import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton } from "@mui/material";
import { openSpotlight } from "@mantine/spotlight";
import SecondaryNav from "./SecondaryNav";
import { make as Tooltip } from "common/src/components/Tooltip.bs";
import SideNav from "./SideNav";
import {
  compsItems,
  statsItems,
  toolsItems,
  playersItemsSub,
  setInfoItems,
  setRevivalInfoItems,
  getActiveGroup,
  getRootGroups,
} from "./NavCore";
import { useT } from "common/src/bindings/Translation.bs";
import { toUrl as apertureToUrl } from "common/src/Aperture.bs";
import { useValue as useApertureValue } from "../ctx/ApertureCtx.bs";
import { PatreonSvg } from "../patreon/Patreon";
import SettingsModal from "../settings/SettingsModal";

import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";

type SubNavLinkProps = {
  href: string;
  label: string;
};

const SubNavLink = ({ href, label }: SubNavLinkProps) => {
  return (
    <Link className="subnav-link" href={href} prefetch={false}>
      <span>{label}</span>
    </Link>
  );
};

type NavLinkProps = {
  href: string;
  label: string;
  active?: boolean;
  subheaderItems: Array<[string, string]>;
};

const NavLink = ({ href, label, active, subheaderItems }: NavLinkProps) => {
  return (
    <div
      className={
        "main-nav-link-wrap" +
        (active && subheaderItems.length > 0 ? " mx-[-3px]" : "")
      }
    >
      <Link
        prefetch={false}
        className={
          "main-nav-link block " +
          (active ? " main-nav-link-active" : "") +
          (active && subheaderItems.length > 0 ? " !pl-[12px]" : "")
        }
        href={href}
      >
        {label}
        {active && subheaderItems.length > 0 ? (
          <ArrowDropDownIcon className="ml-[-1px] mr-[-15px]" />
        ) : null}
      </Link>
      {!active && subheaderItems.length > 0 ? (
        <div className="main-nav-link-subheader elevation-8">
          {subheaderItems.map(([href, label], idx) => (
            <SubNavLink key={idx} href={href} label={label} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

type SecondaryNavWrapProps = {
  activeGroup: string;
  patreonActive: boolean;
};

function SecondaryNavWrap({
  activeGroup,
  patreonActive,
}: SecondaryNavWrapProps) {
  const t = useT(0);
  const aperture = useApertureValue();

  if (activeGroup === "Comps") {
    return <SecondaryNav items={compsItems(t, apertureToUrl(aperture))} />;
  } else if (activeGroup === "Stats") {
    return (
      <SecondaryNav
        items={statsItems(t, apertureToUrl(aperture), patreonActive)}
      />
    );
  } else if (activeGroup === "Tools") {
    return <SecondaryNav items={toolsItems(t)} />;
  } else if (activeGroup === "Set Info") {
    return <SecondaryNav items={setInfoItems(t)} />;
  } else if (activeGroup === "Set 4.5 Info") {
    return <SecondaryNav items={setRevivalInfoItems(t)} />;
  } else if (activeGroup === "Players") {
    return <SecondaryNav items={playersItemsSub(t)} />;
  } else {
    return null;
  }
}

const DiscordSvg = ({ size }: { size: number }) => {
  return (
    <svg
      viewBox="0 0 245 240"
      width={size}
      height={size}
      fill="var(--primary-12)"
    >
      <path d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z" />
      <path d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z" />
    </svg>
  );
};

export default function AppHeader() {
  const [patreonActive, setPatreonActive] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  useEffect(() => {
    const ps = localStorage.getItem("p-status");
    setPatreonActive(() => ps != null);
  }, []);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const t = useT(0);
  const aperture = useApertureValue();

  const router = useRouter();
  const activeGroup = useMemo(
    () => getActiveGroup(router.asPath),
    [router.asPath],
  );
  const rootGroups = useMemo(
    () => getRootGroups(t, aperture, patreonActive),
    [t, aperture, patreonActive],
  );

  return (
    <>
      <div
        id="menu"
        className="hidden md:block fixed top-0 left-0 w-screen z-30 pr-2 bg-bg0 h-12 text-white1"
      >
        <div className="flex items-center justify-between w-full lg:w-[1153px] mx-auto">
          <nav className="hidden md:flex h-full font-montserrat font-medium text-[15px] uppercase">
            {rootGroups.map((group) => (
              <NavLink
                key={group.key}
                href={group.href}
                label={group.label}
                active={group.key === activeGroup}
                subheaderItems={group.items}
              />
            ))}
          </nav>
          <div className="flex items-center gap-3">
            <Tooltip title="Ctrl/Cmd + P">
              <IconButton onClick={() => openSpotlight()}>
                <SearchIcon viewBox="1 1 22 22" />
              </IconButton>
            </Tooltip>
            <div className="cursor-pointer">
              <Link href="/patreon" prefetch={false}>
                <PatreonSvg size={24} active={patreonActive} />
              </Link>
            </div>
            <a
              href="https://discord.gg/K4Z6shucH8"
              target="_blank"
              className="cursor-pointer mt-[1px]"
            >
              <DiscordSvg size={30} />
            </a>
            <div
              className="cursor-pointer"
              onClick={() => setSettingsOpen(true)}
            >
              <SettingsIcon />
            </div>
          </div>
        </div>
      </div>
      <SecondaryNavWrap
        activeGroup={activeGroup}
        patreonActive={patreonActive}
      />
      <div
        className={`md:hidden fixed top-0 left-0 w-screen z-30 pr-2 bg-bg0 h-12 text-white1`}
      >
        <div className="flex items-center h-full px-2">
          <IconButton onClick={() => setDrawerOpen((v) => !v)}>
            <MenuIcon viewBox="1 1 22 22" />
          </IconButton>
          <div className="text-xl font-medium pl-2">
            {rootGroups.find((group) => group.key === activeGroup)?.label ||
              "?"}
          </div>
          <div className="flex items-center ml-auto gap-3">
            <Tooltip title="Ctrl/Cmd + P">
              <IconButton onClick={() => openSpotlight()}>
                <SearchIcon viewBox="1 1 22 22" />
              </IconButton>
            </Tooltip>
            <div className="cursor-pointer">
              <Link href="/patreon" prefetch={false}>
                <PatreonSvg size={22} active={patreonActive} />
              </Link>
            </div>
            <a
              href="https://discord.gg/K4Z6shucH8"
              target="_blank"
              className="cursor-pointer mt-[1px]"
            >
              <DiscordSvg size={30} />
            </a>
            <div
              className="cursor-pointer"
              onClick={() => setSettingsOpen(true)}
            >
              <SettingsIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="h-12 w-full" />
      <SideNav
        open={drawerOpen}
        setOpen={setDrawerOpen}
        activeGroup={activeGroup}
        rootGroups={rootGroups}
      />
      <SettingsModal
        opened={settingsOpen}
        onClose={() => setSettingsOpen(false)}
      />
    </>
  );
}
