import { mem1, mem2, mem3 } from "common/src/bindings/Mem";

type unit = {
  tier: number;
  sets: Array<number>;
  traits: Array<Array<string>>;
  special?: true;
  version?: true;
  baseVersion?: true;
  code?: string;
};

type trait = {
  sets: Array<number>;
  tiers: Array<number>;
  styles: Array<number>;
  singleUnit?: true;
  exclusive?: true;
  type: "origin" | "class" | "special";
};

type data = {
  units: { [unitId: string]: unit };
  traits: { [traitId: string]: Array<trait> };
  itemTraits: { [set: number]: { [id: string]: string } };
};

export const data: data = {
  units: {
    TFT3_Caitlyn: {
      tier: 1,
      sets: [36],
      traits: [["Chrono", "Sniper"]],
    },
    TFT3_Fiora: {
      tier: 1,
      sets: [36],
      traits: [["Cybernetic", "Set3_Blademaster"]],
    },
    TFT3_Graves: {
      tier: 1,
      sets: [36],
      traits: [["SpacePirate", "Blaster"]],
    },
    TFT3_Illaoi: {
      tier: 1,
      sets: [36],
      traits: [["Battlecast", "Set3_Brawler"]],
    },
    TFT3_JarvanIV: {
      tier: 1,
      sets: [36],
      traits: [["DarkStar", "Protector"]],
    },
    TFT3_Leona: {
      tier: 1,
      sets: [36],
      traits: [["Cybernetic", "Vanguard"]],
    },
    TFT3_Malphite: {
      tier: 1,
      sets: [36],
      traits: [["Rebel", "Set3_Brawler"]],
    },
    TFT3_Nocturne: {
      tier: 1,
      sets: [36],
      traits: [["Battlecast", "Infiltrator"]],
    },
    TFT3_Poppy: {
      tier: 1,
      sets: [36],
      traits: [["StarGuardian", "Vanguard"]],
    },
    TFT3_TwistedFate: {
      tier: 1,
      sets: [36],
      traits: [["Chrono", "Set3_Sorcerer"]],
    },
    TFT3_Xayah: {
      tier: 1,
      sets: [36],
      traits: [["Set3_Celestial", "Set3_Blademaster"]],
    },
    TFT3_Ziggs: {
      tier: 1,
      sets: [36],
      traits: [["Rebel", "Demolitionist"]],
    },
    TFT3_Zoe: {
      tier: 1,
      sets: [36],
      traits: [["StarGuardian", "Set3_Sorcerer"]],
    },
    TFT3_Ahri: {
      tier: 2,
      sets: [36],
      traits: [["StarGuardian", "Set3_Sorcerer"]],
    },
    TFT3_Annie: {
      tier: 2,
      sets: [36],
      traits: [["MechPilot", "Set3_Sorcerer"]],
    },
    TFT3_Blitzcrank: {
      tier: 2,
      sets: [36],
      traits: [["Chrono", "Set3_Brawler"]],
    },
    TFT3_Darius: {
      tier: 2,
      sets: [36],
      traits: [["SpacePirate", "ManaReaver"]],
    },
    TFT3_KogMaw: {
      tier: 2,
      sets: [36],
      traits: [["Battlecast", "Blaster"]],
    },
    TFT3_Lucian: {
      tier: 2,
      sets: [36],
      traits: [["Cybernetic", "Blaster"]],
    },
    TFT3_Mordekaiser: {
      tier: 2,
      sets: [36],
      traits: [["DarkStar", "Vanguard"]],
    },
    TFT3_Nautilus: {
      tier: 2,
      sets: [36],
      traits: [["Astro", "Vanguard"]],
    },
    TFT3_Rakan: {
      tier: 2,
      sets: [36],
      traits: [["Set3_Celestial", "Protector"]],
    },
    TFT3_Shen: {
      tier: 2,
      sets: [36],
      traits: [["Chrono", "Set3_Blademaster"]],
    },
    TFT3_XinZhao: {
      tier: 2,
      sets: [36],
      traits: [["Set3_Celestial", "Protector"]],
    },
    TFT3_Yasuo: {
      tier: 2,
      sets: [36],
      traits: [["Rebel", "Set3_Blademaster"]],
    },
    TFT3_Zed: {
      tier: 2,
      sets: [36],
      traits: [["Rebel", "Infiltrator"]],
    },
    TFT3_Ashe: {
      tier: 3,
      sets: [36],
      traits: [["Set3_Celestial", "Sniper"]],
    },
    TFT3_Bard: {
      tier: 3,
      sets: [36],
      traits: [["Astro", "Set3_Mystic"]],
    },
    TFT3_Cassiopeia: {
      tier: 3,
      sets: [36],
      traits: [["Battlecast", "Set3_Mystic"]],
    },
    TFT3_Ezreal: {
      tier: 3,
      sets: [36],
      traits: [["Chrono", "Blaster"]],
    },
    TFT3_Jayce: {
      tier: 3,
      sets: [36],
      traits: [["SpacePirate", "Vanguard"]],
    },
    TFT3_Karma: {
      tier: 3,
      sets: [36],
      traits: [["DarkStar", "Set3_Mystic"]],
    },
    TFT3_MasterYi: {
      tier: 3,
      sets: [36],
      traits: [["Rebel", "Set3_Blademaster"]],
    },
    TFT3_Neeko: {
      tier: 3,
      sets: [36],
      traits: [["StarGuardian", "Protector"]],
    },
    TFT3_Rumble: {
      tier: 3,
      sets: [36],
      traits: [["MechPilot", "Demolitionist"]],
    },
    TFT3_Shaco: {
      tier: 3,
      sets: [36],
      traits: [["DarkStar", "Infiltrator"]],
    },
    TFT3_Syndra: {
      tier: 3,
      sets: [36],
      traits: [["StarGuardian", "Set3_Sorcerer"]],
    },
    TFT3_Vayne: {
      tier: 3,
      sets: [36],
      traits: [["Cybernetic", "Sniper"]],
    },
    TFT3_Vi: {
      tier: 3,
      sets: [36],
      traits: [["Cybernetic", "Set3_Brawler"]],
    },
    TFT3_Fizz: {
      tier: 4,
      sets: [36],
      traits: [["MechPilot", "Infiltrator"]],
    },
    TFT3_Gnar: {
      tier: 4,
      sets: [36],
      traits: [["Astro", "Set3_Brawler"]],
    },
    TFT3_Irelia: {
      tier: 4,
      sets: [36],
      traits: [["Cybernetic", "Set3_Blademaster", "ManaReaver"]],
    },
    TFT3_Jhin: {
      tier: 4,
      sets: [36],
      traits: [["DarkStar", "Sniper"]],
    },
    TFT3_Jinx: {
      tier: 4,
      sets: [36],
      traits: [["Rebel", "Blaster"]],
    },
    TFT3_Riven: {
      tier: 4,
      sets: [36],
      traits: [["Chrono", "Set3_Blademaster"]],
    },
    TFT3_Soraka: {
      tier: 4,
      sets: [36],
      traits: [["StarGuardian", "Set3_Mystic"]],
    },
    TFT3_Teemo: {
      tier: 4,
      sets: [36],
      traits: [["Astro", "Sniper"]],
    },
    TFT3_Viktor: {
      tier: 4,
      sets: [36],
      traits: [["Battlecast", "Set3_Sorcerer"]],
    },
    TFT3_WuKong: {
      tier: 4,
      sets: [36],
      traits: [["Chrono", "Vanguard"]],
    },
    TFT3_AurelionSol: {
      tier: 5,
      sets: [36],
      traits: [["Rebel", "Starship"]],
    },
    TFT3_Ekko: {
      tier: 5,
      sets: [36],
      traits: [["Cybernetic", "Infiltrator"]],
    },
    TFT3_Gangplank: {
      tier: 5,
      sets: [36],
      traits: [["SpacePirate", "Demolitionist", "Mercenary"]],
    },
    TFT3_Janna: {
      tier: 5,
      sets: [36],
      traits: [["StarGuardian", "Paragon"]],
    },
    TFT3_Lulu: {
      tier: 5,
      sets: [36],
      traits: [["Set3_Celestial", "Set3_Mystic"]],
    },
    TFT3_Thresh: {
      tier: 5,
      sets: [36],
      traits: [["Chrono", "ManaReaver"]],
    },
    TFT3_Urgot: {
      tier: 5,
      sets: [36],
      traits: [["Battlecast", "Protector"]],
    },
    TFT3_Xerath: {
      tier: 5,
      sets: [36],
      traits: [["DarkStar", "Set3_Sorcerer"]],
    },

    TFT5_Aatrox: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Redeemed", "TFT5_Legionnaire"]],
    },
    TFT5_Gragas: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Dawnbringer", "TFT5_Brawler"]],
    },
    TFT5_Kalista: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Abomination", "TFT5_Legionnaire"]],
    },
    TFT5_Khazix: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Dawnbringer", "TFT5_Assassin"]],
    },
    TFT5_Kled: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Hellion", "TFT5_Cavalier"]],
    },
    TFT5_Leona: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Redeemed", "TFT5_Knight"]],
    },
    TFT5_Olaf: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Sentinel", "TFT5_Skirmisher"]],
    },
    TFT5_Poppy: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Hellion", "TFT5_Knight"]],
    },
    TFT5_Senna: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Sentinel", "TFT5_Cannoneer"]],
    },
    TFT5_Udyr: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Draconic", "TFT5_Skirmisher"]],
    },
    TFT5_Vayne: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Forgotten", "TFT5_Ranger"]],
    },
    TFT5_Ziggs: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Hellion", "TFT5_Spellweaver"]],
    },
    TFT5_Vladimir: {
      tier: 1,
      sets: [56],
      traits: [["TFT5_Nightbringer", "TFT5_Renewer"]],
    },
    TFT5_Brand: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Abomination", "TFT5_Spellweaver"]],
    },
    TFT5_Hecarim: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Forgotten", "TFT5_Cavalier"]],
    },
    TFT5_Irelia: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Sentinel", "TFT5_Skirmisher", "TFT5_Legionnaire"]],
    },
    TFT5_Kennen: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Hellion", "TFT5_Skirmisher"]],
    },
    TFT5_Nautilus: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Ironclad", "TFT5_Knight"]],
    },
    TFT5_Pyke: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Sentinel", "TFT5_Assassin"]],
    },
    TFT5_Sejuani: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Nightbringer", "TFT5_Cavalier", "TFT5_Brawler"]],
    },
    TFT5_Sett: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Draconic", "TFT5_Brawler"]],
    },
    TFT5_Soraka: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Dawnbringer", "TFT5_Renewer"]],
    },
    TFT5_Syndra: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Redeemed", "TFT5_Invoker"]],
    },
    TFT5_Thresh: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Forgotten", "TFT5_Knight"]],
    },
    TFT5_Tristana: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Hellion", "TFT5_Cannoneer"]],
    },
    TFT5_Varus: {
      tier: 2,
      sets: [56],
      traits: [["TFT5_Redeemed", "TFT5_Ranger"]],
    },
    TFT5_Ashe: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Draconic", "TFT5_Ranger"]],
    },
    TFT5_LeeSin: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Nightbringer", "TFT5_Skirmisher"]],
    },
    TFT5_Lulu: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Hellion", "TFT5_Mystic"]],
    },
    TFT5_Lux: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Redeemed", "TFT5_Mystic"]],
    },
    TFT5_Nidalee: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Dawnbringer", "TFT5_Skirmisher"]],
    },
    TFT5_Nocturne: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Revenant", "TFT5_Assassin"]],
    },
    TFT5_MissFortune: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Forgotten", "TFT5_Cannoneer"]],
    },
    TFT5_Nunu: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Abomination", "TFT5_Brawler"]],
    },
    TFT5_Rakan: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Sentinel", "TFT5_Renewer"]],
    },
    TFT5_Riven: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Dawnbringer", "TFT5_Legionnaire"]],
    },
    TFT5_Yasuo: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Nightbringer", "TFT5_Legionnaire"]],
    },
    TFT5_Zyra: {
      tier: 3,
      sets: [56],
      traits: [["TFT5_Draconic", "TFT5_Spellweaver"]],
    },
    TFT5_Aphelios: {
      tier: 4,
      sets: [56],
      traits: [["TFT5_Nightbringer", "TFT5_Ranger"]],
    },
    TFT5_Diana: {
      tier: 4,
      sets: [56],
      traits: [["TFT5_Nightbringer", "TFT5_Assassin"]],
    },
    TFT5_Draven: {
      tier: 4,
      sets: [56],
      traits: [["TFT5_Forgotten", "TFT5_Legionnaire"]],
    },
    TFT5_Fiddlesticks: {
      tier: 4,
      sets: [56],
      traits: [["TFT5_Abomination", "TFT5_Revenant", "TFT5_Mystic"]],
    },
    TFT5_Galio: {
      tier: 4,
      sets: [56],
      traits: [["TFT5_Draconic", "TFT5_Sentinel", "TFT5_Knight"]],
    },
    TFT5_Ivern: {
      tier: 4,
      sets: [56],
      traits: [["TFT5_Revenant", "TFT5_Invoker", "TFT5_Renewer"]],
    },
    TFT5_Jax: {
      tier: 4,
      sets: [56],
      traits: [["TFT5_Ironclad", "TFT5_Skirmisher"]],
    },
    TFT5_Karma: {
      tier: 4,
      sets: [56],
      traits: [["TFT5_Dawnbringer", "TFT5_Invoker"]],
    },
    TFT5_Lucian: {
      tier: 4,
      sets: [56],
      traits: [["TFT5_Sentinel", "TFT5_Cannoneer"]],
    },
    TFT5_Rell: {
      tier: 4,
      sets: [56],
      traits: [["TFT5_Redeemed", "TFT5_Ironclad", "TFT5_Cavalier"]],
    },
    TFT5_Velkoz: {
      tier: 4,
      sets: [56],
      traits: [["TFT5_Redeemed", "TFT5_Spellweaver"]],
    },
    TFT5_Akshan: {
      tier: 5,
      sets: [56],
      traits: [["TFT5_Sentinel", "TFT5_Ranger"]],
    },
    TFT5_Garen: {
      tier: 5,
      sets: [56],
      traits: [["TFT5_Victorious", "TFT5_Dawnbringer", "TFT5_Knight"]],
    },
    TFT5_Gwen: {
      tier: 5,
      sets: [56],
      traits: [["TFT5_Inanimate", "TFT5_Mystic"]],
    },
    TFT5_Heimerdinger: {
      tier: 5,
      sets: [56],
      traits: [["TFT5_Draconic", "TFT5_Renewer", "TFT5_Caretaker"]],
    },
    TFT5_Kayle: {
      tier: 5,
      sets: [56],
      traits: [["TFT5_Redeemed", "TFT5_Legionnaire"]],
    },
    TFT5_Teemo: {
      tier: 5,
      sets: [56],
      traits: [["TFT5_Cruel", "TFT5_Hellion", "TFT5_Invoker"]],
    },
    TFT5_Viego: {
      tier: 5,
      sets: [56],
      traits: [["TFT5_Forgotten", "TFT5_Skirmisher", "TFT5_Assassin"]],
    },
    TFT5_Volibear: {
      tier: 5,
      sets: [56],
      traits: [["TFT5_Revenant", "TFT5_Brawler"]],
    },
    TFT6_Caitlyn: {
      tier: 1,
      sets: [60, 65],
      traits: [["Set6_Enforcer", "Set6_Sniper"]],
    },
    TFT6_Camille: {
      tier: 1,
      sets: [60, 65],
      traits: [["Set6_Clockwork", "Set6_Challenger"]],
    },
    TFT6_Darius: {
      tier: 1,
      sets: [60, 65],
      traits: [["Set6_Syndicate", "Set6_Bodyguard"]],
    },
    TFT6_Ezreal: {
      tier: 1,
      sets: [60, 65],
      traits: [["Set6_Scrap", "Set6_Innovator"]],
    },
    TFT6_Garen: {
      tier: 1,
      sets: [60],
      traits: [["Set6_Academy", "Set6_Protector"]],
    },
    TFT6_Graves: {
      tier: 1,
      sets: [60],
      traits: [["Set6_Academy", "Set6_Twinshot"]],
    },
    TFT6_Illaoi: {
      tier: 1,
      sets: [60, 65],
      traits: [["Set6_Mercenary", "Set6_Bruiser"]],
    },
    TFT6_Kassadin: {
      tier: 1,
      sets: [60, 65],
      traits: [
        ["Set6_Mutant", "Set6_Protector"],
        ["Set6_Mutant", "Set6_Scholar"],
      ],
    },
    TFT6_Poppy: {
      tier: 1,
      sets: [60, 65],
      traits: [["Set6_Yordle", "Set6_Bodyguard"]],
    },
    TFT6_Singed: {
      tier: 1,
      sets: [60, 65],
      traits: [["Set6_Chemtech", "Set6_Innovator"]],
    },
    TFT6_TwistedFate: {
      tier: 1,
      sets: [60],
      traits: [["Set6_Syndicate", "Set6_Arcanist"]],
    },
    TFT6_Twitch: {
      tier: 1,
      sets: [60, 65],
      traits: [["Set6_Chemtech", "Set6_Assassin"]],
    },
    TFT6_Ziggs: {
      tier: 1,
      sets: [60, 65],
      traits: [["Set6_Yordle", "Set6_Scrap", "Set6_Arcanist"]],
    },
    TFT6_Blitzcrank: {
      tier: 2,
      sets: [60, 65],
      traits: [
        ["Set6_Scrap", "Set6_Bodyguard", "Set6_Protector"],
        ["Set6_Scrap", "Set6_Bodyguard"],
      ],
    },
    TFT6_Katarina: {
      tier: 2,
      sets: [60],
      traits: [["Set6_Academy", "Set6_Assassin"]],
    },
    TFT6_KogMaw: {
      tier: 2,
      sets: [60],
      traits: [["Set6_Mutant", "Set6_Sniper", "Set6_Twinshot"]],
    },
    TFT6_Lulu: {
      tier: 2,
      sets: [60, 65],
      traits: [["Set6_Yordle", "Set6_Enchanter"]],
    },
    TFT6_Quinn: {
      tier: 2,
      sets: [60, 65],
      traits: [["Set6_Mercenary", "Set6_Challenger"]],
    },
    TFT6_Swain: {
      tier: 2,
      sets: [60, 65],
      traits: [
        ["Set6_Imperial", "Set6_Arcanist"],
        ["Set6_Hextech", "Set6_Arcanist"],
      ],
    },
    TFT6_Talon: {
      tier: 2,
      sets: [60, 65],
      traits: [
        ["Set6_Imperial", "Set6_Assassin"],
        ["Set6_Debonair", "Set6_Assassin"],
      ],
    },
    TFT6_Tristana: {
      tier: 2,
      sets: [60],
      traits: [["Set6_Yordle", "Set6_Sniper"]],
    },
    TFT6_Trundle: {
      tier: 2,
      sets: [60],
      traits: [["Set6_Scrap", "Set6_Bruiser"]],
    },
    TFT6_Vi: {
      tier: 2,
      sets: [60],
      traits: [["Set6_Enforcer", "Set6_Sister", "Set6_Bruiser"]],
    },
    TFT6_Warwick: {
      tier: 2,
      sets: [60, 65],
      traits: [["Set6_Chemtech", "Set6_Challenger"]],
    },
    TFT6_Zilean: {
      tier: 2,
      sets: [60, 65],
      traits: [["Set6_Clockwork", "Set6_Innovator"]],
    },
    TFT6_Zyra: {
      tier: 2,
      sets: [60, 65],
      traits: [["Set6_Syndicate", "Set6_Scholar"]],
    },
    TFT6_ChoGath: {
      tier: 3,
      sets: [60, 65],
      traits: [["Set6_Mutant", "Set6_Colossus", "Set6_Bruiser"]],
    },
    TFT6_Ekko: {
      tier: 3,
      sets: [60, 65],
      traits: [
        ["Set6_Scrap", "Set6_Assassin"],
        ["Set6_Scrap", "Set6_Assassin", "Set6_Innovator"],
      ],
    },
    TFT6_Gangplank: {
      tier: 3,
      sets: [60, 65],
      traits: [["Set6_Mercenary", "Set6_Twinshot"]],
    },
    TFT6_Heimerdinger: {
      tier: 3,
      sets: [60],
      traits: [["Set6_Yordle", "Set6_Scholar", "Set6_Innovator"]],
    },
    TFT6_Leona: {
      tier: 3,
      sets: [60, 65],
      traits: [
        ["Set6_Academy", "Set6_Bodyguard"],
        ["Set6_Debonair", "Set6_Bodyguard"],
      ],
    },
    TFT6_Lissandra: {
      tier: 3,
      sets: [60],
      traits: [["Set6_Chemtech", "Set6_Scholar"]],
    },
    TFT6_Malzahar: {
      tier: 3,
      sets: [60, 65],
      traits: [["Set6_Mutant", "Set6_Arcanist"]],
    },
    TFT6_MissFortune: {
      tier: 3,
      sets: [60, 65],
      traits: [["Set6_Mercenary", "Set6_Sniper"]],
    },
    TFT6_Samira: {
      tier: 3,
      sets: [60],
      traits: [["Set6_Imperial", "Set6_Challenger"]],
    },
    TFT6_Shaco: {
      tier: 3,
      sets: [60],
      traits: [["Set6_Syndicate", "Set6_Assassin"]],
    },
    TFT6_Taric: {
      tier: 3,
      sets: [60],
      traits: [["Set6_Socialite", "Set6_Enchanter"]],
    },
    TFT6_Vex: {
      tier: 3,
      sets: [60, 65],
      traits: [["Set6_Yordle", "Set6_Arcanist"]],
    },
    TFT6_Zac: {
      tier: 3,
      sets: [60, 65],
      traits: [["Set6_Chemtech", "Set6_Bruiser"]],
    },
    TFT6_Braum: {
      tier: 4,
      sets: [60, 65],
      traits: [["Set6_Syndicate", "Set6_Bodyguard"]],
    },
    TFT6_DrMundo: {
      tier: 4,
      sets: [60],
      traits: [["Set6_Mutant", "Set6_Chemtech", "Set6_Bruiser"]],
    },
    TFT6_Fiora: {
      tier: 4,
      sets: [60],
      traits: [["Set6_Enforcer", "Set6_Challenger"]],
    },
    TFT6_Janna: {
      tier: 4,
      sets: [60],
      traits: [["Set6_Scrap", "Set6_Enchanter", "Set6_Scholar"]],
    },
    TFT6_Jhin: {
      tier: 4,
      sets: [60, 65],
      traits: [["Set6_Clockwork", "Set6_Sniper"]],
    },
    TFT6_Lux: {
      tier: 4,
      sets: [60],
      traits: [["Set6_Academy", "Set6_Arcanist"]],
    },
    TFT6_Orianna: {
      tier: 4,
      sets: [60, 65],
      traits: [["Set6_Clockwork", "Set6_Enchanter"]],
    },
    TFT6_Seraphine: {
      tier: 4,
      sets: [60, 65],
      traits: [["Set6_Socialite", "Set6_Innovator"]],
    },
    TFT6_Sion: {
      tier: 4,
      sets: [60],
      traits: [["Set6_Imperial", "Set6_Colossus", "Set6_Protector"]],
    },
    TFT6_Urgot: {
      tier: 4,
      sets: [60],
      traits: [["Set6_Chemtech", "Set6_Twinshot"]],
    },
    TFT6_Yone: {
      tier: 4,
      sets: [60],
      traits: [["Set6_Academy", "Set6_Challenger"]],
    },
    TFT6_Akali: {
      tier: 5,
      sets: [60],
      traits: [["Set6_Syndicate", "Set6_Assassin"]],
    },
    TFT6_Galio: {
      tier: 5,
      sets: [60, 65],
      traits: [["Set6_Socialite", "Set6_Colossus", "Set6_Bodyguard"]],
    },
    TFT6_Jayce: {
      tier: 5,
      sets: [60, 65],
      traits: [["Set6_Enforcer", "Set6_Transformer", "Set6_Innovator"]],
    },
    TFT6_Jinx: {
      tier: 5,
      sets: [60, 65],
      traits: [
        ["Set6_Scrap", "Set6_Sister", "Set6_Twinshot"],
        ["Set6_Scrap", "Set6_Rivals", "Set6_Twinshot"],
      ],
    },
    TFT6_Kaisa: {
      tier: 5,
      sets: [60, 65],
      traits: [["Set6_Mutant", "Set6_Challenger"]],
    },
    TFT6_TahmKench: {
      tier: 5,
      sets: [60, 65],
      traits: [["Set6_Mercenary", "Set6_Bruiser", "Set6_Glutton"]],
    },
    TFT6_Veigar: { tier: 5, sets: [60, 65], traits: [["Set6_YordleLord"]] },
    TFT6_Viktor: {
      tier: 5,
      sets: [60, 65],
      traits: [["Set6_Chemtech", "Set6_Arcanist"]],
    },
    TFT6_Yuumi: {
      tier: 5,
      sets: [60],
      traits: [["Set6_Academy", "Set6_Scholar"]],
    },
    TFT6_Brand: {
      tier: 1,
      sets: [65],
      traits: [["Set6_Debonair", "Set6_Arcanist"]],
    },
    TFT6_JarvanIV: {
      tier: 1,
      sets: [65],
      traits: [["Set6_Hextech", "Set6_Striker"]],
    },
    TFT6_Nocturne: {
      tier: 1,
      sets: [65],
      traits: [["Set6_Hextech", "Set6_Assassin"]],
    },
    TFT6_Ashe: {
      tier: 2,
      sets: [65],
      traits: [["Set6_Syndicate", "Set6_Sniper"]],
    },
    TFT6_Corki: {
      tier: 2,
      sets: [65],
      traits: [["Set6_Yordle", "Set6_Twinshot"]],
    },
    TFT6_RekSai: {
      tier: 2,
      sets: [65],
      traits: [["Set6_Mutant", "Set6_Bruiser", "Set6_Striker"]],
    },
    TFT6_Sejuani: {
      tier: 2,
      sets: [65],
      traits: [["Set6_Hextech", "Set6_Enforcer", "Set6_Bruiser"]],
    },
    TFT6_Syndra: {
      tier: 2,
      sets: [65],
      traits: [["Set6_Debonair", "Set6_Scholar"]],
    },
    TFT6_Gnar: {
      tier: 3,
      sets: [65],
      traits: [["Set6_Socialite", "Set6_Yordle", "Set6_Striker"]],
    },
    TFT6_Lucian: {
      tier: 3,
      sets: [65],
      traits: [["Set6_Hextech", "Set6_Twinshot"]],
    },
    TFT6_Morgana: {
      tier: 3,
      sets: [65],
      traits: [["Set6_Syndicate", "Set6_Enchanter"]],
    },
    TFT6_Senna: {
      tier: 3,
      sets: [65],
      traits: [["Set6_Socialite", "Set6_Enchanter"]],
    },
    TFT6_Tryndamere: {
      tier: 3,
      sets: [65],
      traits: [["Set6_Chemtech", "Set6_Challenger"]],
    },
    TFT6_Ahri: {
      tier: 4,
      sets: [65],
      traits: [["Set6_Syndicate", "Set6_Arcanist"]],
    },
    TFT6_Alistar: {
      tier: 4,
      sets: [65],
      traits: [["Set6_Hextech", "Set6_Colossus"]],
    },
    TFT6_Draven: {
      tier: 4,
      sets: [65],
      traits: [["Set6_Debonair", "Set6_Challenger"]],
    },
    TFT6_Irelia: {
      tier: 4,
      sets: [65],
      traits: [["Set6_Scrap", "Set6_Striker"]],
    },
    TFT6_KhaZix: {
      tier: 4,
      sets: [65],
      traits: [["Set6_Mutant", "Set6_Assassin"]],
    },
    TFT6_Renata: {
      tier: 4,
      sets: [65],
      traits: [["Set6_Chemtech", "Set6_Scholar"]],
    },
    TFT6_Sivir: {
      tier: 4,
      sets: [65],
      traits: [["Set6_Hextech", "Set6_Striker"]],
    },
    TFT6b_Vi: {
      tier: 4,
      sets: [65],
      traits: [["Set6_Enforcer", "Set6_Rivals", "Set6_Bruiser"]],
    },
    TFT6_Silco: {
      tier: 5,
      sets: [65],
      traits: [["Set6_Mastermind", "Set6_Scholar"]],
    },
    TFT6_Zeri: {
      tier: 5,
      sets: [65],
      traits: [["Set6_Debonair", "Set6_Sniper"]],
    },

    TFT7_Aatrox: {
      tier: 1,
      sets: [70],
      traits: [["Set7_Shimmerscale", "Set7_Warrior"]],
    },
    TFT7_Ezreal: {
      tier: 1,
      sets: [70, 75],
      traits: [["Set7_Tempest", "Set7_Swiftshot"]],
    },
    TFT7_Heimerdinger: {
      tier: 1,
      sets: [70],
      traits: [["Set7_Trainer", "Set7_Mage"]],
    },
    TFT7_Karma: {
      tier: 1,
      sets: [70, 75],
      traits: [["Set7_Jade", "Set7_Dragonmancer"]],
    },
    TFT7_Leona: {
      tier: 1,
      sets: [70, 75],
      traits: [["Set7_Mirage", "Set7_Guardian"]],
    },
    TFT7_Malphite: {
      tier: 1,
      sets: [75],
      traits: [["Set7_Lagoon", "Set7_Bruiser"]],
    },
    TFT7_Nasus: {
      tier: 1,
      sets: [75],
      traits: [["Set7_Shimmerscale", "Set7_Guardian"]],
    },
    TFT7_Nidalee: {
      tier: 1,
      sets: [70, 75],
      traits: [["Set7_Astral", "Set7_Shapeshifter"]],
    },
    TFT7_Sejuani: {
      tier: 1,
      sets: [70, 75],
      traits: [["Set7_Guild", "Set7_Cavalier"]],
    },
    TFT7_Senna: {
      tier: 1,
      sets: [70, 75],
      traits: [["Set7_Ragewing", "Set7_Cannoneer"]],
    },
    TFT7_Sett: {
      tier: 1,
      sets: [70, 75],
      traits: [["Set7_Ragewing", "Set7_Dragonmancer"]],
    },
    TFT7_Skarner: {
      tier: 1,
      sets: [70, 75],
      traits: [["Set7_Astral", "Set7_Bruiser"]],
    },
    TFT7_TahmKench: {
      tier: 1,
      sets: [70],
      traits: [["Set7_Revel", "Set7_Bruiser"]],
    },
    TFT7_Taliyah: {
      tier: 1,
      sets: [75],
      traits: [["Set7_Lagoon", "Set7_Mage"]],
    },
    TFT7_Taric: {
      tier: 1,
      sets: [70],
      traits: [["Set7_Jade", "Set7_Guardian"]],
    },
    TFT7_Vladimir: {
      tier: 1,
      sets: [70, 75],
      traits: [["Set7_Astral", "Set7_Mage"]],
    },
    TFT7_Wukong: {
      tier: 1,
      sets: [75],
      traits: [["Set7_Jade", "Set7_Warrior"]],
    },
    TFT7_Aphelios: {
      tier: 2,
      sets: [75],
      traits: [["Set7_Darkflight", "Set7_Cannoneer"]],
    },
    TFT7_Ashe: {
      tier: 2,
      sets: [70],
      traits: [["Set7_Jade", "Set7_Dragonmancer", "Set7_Swiftshot"]],
    },
    TFT7_Braum: {
      tier: 2,
      sets: [70, 75],
      traits: [["Set7_Scalescorn", "Set7_Guardian"]],
    },
    TFT7_Gnar: {
      tier: 2,
      sets: [70, 75],
      traits: [["Set7_Jade", "Set7_Shapeshifter"]],
    },
    TFT7_Jax: {
      tier: 2,
      sets: [75],
      traits: [["Set7_Jade", "Set7_Shimmerscale", "Set7_Bruiser"]],
    },
    TFT7_Jinx: {
      tier: 2,
      sets: [70],
      traits: [["Set7_Revel", "Set7_Cannoneer"]],
    },
    TFT7_Kaisa: {
      tier: 2,
      sets: [75],
      traits: [["Set7_Lagoon", "Set7_Dragonmancer"]],
    },
    TFT7_Kayn: {
      tier: 2,
      sets: [70],
      traits: [["Set7_Ragewing", "Set7_Shimmerscale", "Set7_Assassin"]],
    },
    TFT7_Lillia: {
      tier: 2,
      sets: [70, 75],
      traits: [["Set7_Scalescorn", "Set7_Cavalier", "Set7_Mage"]],
    },
    TFT7_Lux: {
      tier: 2,
      sets: [75],
      traits: [["Set7_Astral", "Set7_Mage"]],
    },
    TFT7_Nami: {
      tier: 2,
      sets: [70],
      traits: [["Set7_Astral", "Set7_Mage", "Set7_Mystic"]],
    },
    TFT7_Qiyana: {
      tier: 2,
      sets: [70, 75],
      traits: [["Set7_Tempest", "Set7_Assassin"]],
    },
    TFT7_Rell: {
      tier: 2,
      sets: [75],
      traits: [["Set7_Darkflight", "Set7_Cavalier"]],
    },
    TFT7_Shen: {
      tier: 2,
      sets: [70],
      traits: [["Set7_Ragewing", "Set7_Bruiser", "Set7_Warrior"]],
    },
    TFT7_Thresh: {
      tier: 2,
      sets: [70],
      traits: [["Set7_Whispers", "Set7_Guardian"]],
    },
    TFT7_Tristana: {
      tier: 2,
      sets: [70],
      traits: [["Set7_Trainer", "Set7_Cannoneer"]],
    },
    TFT7_Twitch: {
      tier: 2,
      sets: [70, 75],
      traits: [["Set7_Guild", "Set7_Swiftshot"]],
    },
    TFT7_Yone: {
      tier: 2,
      sets: [70, 75],
      traits: [["Set7_Mirage", "Set7_Warrior"]],
    },
    TFT7_Zac: {
      tier: 2,
      sets: [75],
      traits: [["Set7_Lagoon", "Set7_Guardian"]],
    },
    TFT7_Zyra: {
      tier: 2,
      sets: [75],
      traits: [["Set7_Whispers", "Set7_Evoker"]],
    },
    TFT7_Anivia: {
      tier: 3,
      sets: [70],
      traits: [["Set7_Jade", "Set7_Evoker", "Set7_Legend"]],
    },
    TFT7_Diana: {
      tier: 3,
      sets: [70, 75],
      traits: [["Set7_Scalescorn", "Set7_Assassin"]],
    },
    TFT7_Elise: {
      tier: 3,
      sets: [70],
      traits: [["Set7_Whispers", "Set7_Shapeshifter"]],
    },
    TFT7b_Heimerdinger: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Mage"]],
      special: true,
    },
    TFT7_Illaoi: {
      tier: 3,
      sets: [70],
      traits: [["Set7_Astral", "Set7_Bruiser"]],
    },
    TFT7_LeeSin: {
      tier: 3,
      sets: [70, 75],
      traits: [["Set7_Tempest", "Set7_Dragonmancer"]],
    },
    TFT7_Lulu: {
      tier: 3,
      sets: [70],
      traits: [["Set7_Trainer", "Set7_Mystic", "Set7_Evoker"]],
    },
    TFT7b_Lulu: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Evoker"]],
      special: true,
    },
    TFT7_Nomsy: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Prodigy", "Set7_Dragon"]],
    },
    TFT7_NomsyCannoneer: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Prodigy", "Set7_Dragon", "Set7_Cannoneer"]],
    },
    TFT7_NomsyEvoker: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Prodigy", "Set7_Dragon", "Set7_Evoker"]],
    },
    TFT7_NomsyMage: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Prodigy", "Set7_Dragon", "Set7_Mage"]],
    },
    TFT7_Nunu: {
      tier: 3,
      sets: [70, 75],
      traits: [["Set7_Mirage", "Set7_Cavalier"]],
    },
    TFT7_Olaf: {
      tier: 3,
      sets: [70, 75],
      traits: [["Set7_Scalescorn", "Set7_Bruiser", "Set7_Warrior"]],
    },
    TFT7_Rakan: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Ragewing", "Set7_Guardian", "Set7_Mystic"]],
    },
    TFT7_Rengar: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Darkflight", "Set7_Assassin"]],
    },
    TFT7_Ryze: { tier: 3, sets: [70], traits: [["Set7_Guild", "Set7_Mage"]] },
    TFT7_Seraphine: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Lagoon", "Set7_Evoker", "Set7_Mystic"]],
    },
    TFT7_Swain: {
      tier: 3,
      sets: [70],
      traits: [["Set7_Ragewing", "Set7_Dragonmancer", "Set7_Shapeshifter"]],
    },
    TFT7_Sylas: {
      tier: 3,
      sets: [70, 75],
      traits: [["Set7_Whispers", "Set7_Mage", "Set7_Bruiser"]],
    },
    TFT7b_Tristana: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Cannoneer"]],
      special: true,
    },
    TFT7_Varus: {
      tier: 3,
      sets: [70, 75],
      traits: [["Set7_Astral", "Set7_Swiftshot"]],
    },
    TFT7_Volibear: {
      tier: 3,
      sets: [70],
      traits: [["Set7_Shimmerscale", "Set7_Dragonmancer", "Set7_Legend"]],
    },
    TFT7b_Volibear: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Shimmerscale", "Set7_Dragonmancer"]],
    },
    TFT7_Zeri: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Lagoon", "Set7_Cannoneer"]],
    },
    TFT7_DragonGuild: {
      tier: 3,
      sets: [75],
      traits: [["Set7_Guild", "Set7_Dragon"]],
    },
    TFT7_Corki: {
      tier: 4,
      sets: [70],
      traits: [["Set7_Revel", "Set7_Cannoneer"]],
    },
    TFT7_DragonBlue: {
      tier: 4,
      sets: [70, 75],
      traits: [["Set7_Mirage", "Set7_Dragon"]],
    },
    TFT7_Graves: {
      tier: 4,
      sets: [75],
      traits: [["Set7_Tempest", "Set7_Cannoneer"]],
    },
    TFT7_Hecarim: {
      tier: 4,
      sets: [70, 75],
      traits: [["Set7_Ragewing", "Set7_Cavalier"]],
    },
    TFT7_DragonGold: {
      tier: 4,
      sets: [70, 75],
      traits: [["Set7_Shimmerscale", "Set7_Guardian", "Set7_Dragon"]],
    },
    TFT7_Jayce: {
      tier: 4,
      sets: [75],
      traits: [["Set7_Guild", "Set7_Shapeshifter"]],
    },
    TFT7_Neeko: {
      tier: 4,
      sets: [70],
      traits: [["Set7_Jade", "Set7_Shapeshifter"]],
    },
    TFT7_Nilah: {
      tier: 4,
      sets: [75],
      traits: [["Set7_Lagoon", "Set7_Assassin"]],
    },
    TFT7_Ornn: {
      tier: 4,
      sets: [70],
      traits: [["Set7_Tempest", "Set7_Bruiser", "Set7_Legend"]],
    },
    TFT7_Pantheon: {
      tier: 4,
      sets: [75],
      traits: [["Set7_Whispers", "Set7_Warrior"]],
    },
    TFT7_DragonGreen: {
      tier: 4,
      sets: [70, 75],
      traits: [["Set7_Jade", "Set7_Mystic", "Set7_Dragon"]],
    },
    TFT7_Sona: { tier: 4, sets: [70], traits: [["Set7_Revel", "Set7_Evoker"]] },
    TFT7_Talon: {
      tier: 4,
      sets: [70],
      traits: [["Set7_Guild", "Set7_Assassin"]],
    },
    TFT7_Xayah: {
      tier: 4,
      sets: [70, 75],
      traits: [["Set7_Ragewing", "Set7_Swiftshot"]],
    },
    TFT7_AquaticDragon: {
      tier: 4,
      sets: [75],
      traits: [["Set7_Lagoon", "Set7_Dragon", "Set7_Mage"]],
    },
    TFT7_DragonSwain: {
      tier: 4,
      sets: [75],
      traits: [["Set7_Darkflight", "Set7_Dragon"]],
    },
    TFT7_DragonPurple: {
      tier: 4,
      sets: [70, 75],
      traits: [["Set7_Whispers", "Set7_Bruiser", "Set7_Dragon"]],
    },
    TFT7_AoShin: {
      tier: 5,
      sets: [70, 75],
      traits: [["Set7_Tempest", "Set7_Dragon"]],
    },
    TFT7_AurelionSol: {
      tier: 5,
      sets: [70, 75],
      traits: [["Set7_Astral", "Set7_Evoker", "Set7_Dragon"]],
    },
    TFT7_Bard: {
      tier: 5,
      sets: [70, 75],
      traits: [["Set7_Guild", "Set7_Mystic", "Set7_Bard"]],
    },
    TFT7_Pyke: {
      tier: 5,
      sets: [70],
      traits: [["Set7_Whispers", "Set7_Assassin"]],
    },
    TFT7_Shyvana: {
      tier: 5,
      sets: [70, 75],
      traits: [["Set7_Ragewing", "Set7_Shapeshifter", "Set7_Dragon"]],
    },
    TFT7_Soraka: {
      tier: 5,
      sets: [70, 75],
      traits: [["Set7_Jade", "Set7_Starcaller"]],
    },
    TFT7_DragonEarth: {
      tier: 5,
      sets: [75],
      traits: [["Set7_Monolith", "Set7_Dragon"]],
    },
    TFT7_Yasuo: {
      tier: 5,
      sets: [70, 75],
      traits: [["Set7_Mirage", "Set7_Dragonmancer", "Set7_Warrior"]],
    },
    TFT7_Zoe: {
      tier: 5,
      sets: [70, 75],
      traits: [["Set7_Shimmerscale", "Set7_SpellThief", "Set7_Mage"]],
    },
    TFT8_Ashe: {
      tier: 1,
      sets: [80, 85],
      traits: [
        ["Set8_SpaceCorps", "Set8_Recon"],
        ["Set8_SpaceCorps", "Set8_Deadeye"],
      ],
    },
    TFT8_Blitzcrank: {
      tier: 1,
      sets: [80, 85],
      traits: [["Set8_Admin", "Set8_Brawler"]],
    },
    TFT8_Galio: {
      tier: 1,
      sets: [80],
      traits: [["Set8_Civilian", "Set8_Mascot"]],
    },
    TFT8_Gangplank: {
      tier: 1,
      sets: [80, 85],
      traits: [["Set8_Supers", "Set8_Duelist"]],
    },
    TFT8_Kayle: {
      tier: 1,
      sets: [80, 85],
      traits: [["Set8_UndergroundThe", "Set8_Duelist"]],
    },
    TFT8_Lucian: {
      tier: 1,
      sets: [85],
      traits: [["Set8_Pulsefire", "Set8_GunMage", "Set8_Renegade"]],
    },
    TFT8_Lulu: {
      tier: 1,
      sets: [80, 85],
      traits: [["Set8_GenAE", "Set8_Heart"]],
    },
    TFT8_Lux: {
      tier: 1,
      sets: [80, 85],
      traits: [["Set8_StarGuardian", "Set8_Channeler"]],
    },
    TFT8_Nasus: {
      tier: 1,
      sets: [80, 85],
      traits: [["Set8_AnimaSquad", "Set8_Mascot"]],
    },
    TFT8_Pantheon: {
      tier: 1,
      sets: [85],
      traits: [["Set8_Pulsefire", "Set8_Heart"]],
    },
    TFT8_Poppy: {
      tier: 1,
      sets: [80, 85],
      traits: [["Set8_GenAE", "Set8_Defender"]],
    },
    TFT8_Renekton: {
      tier: 1,
      sets: [80, 85],
      traits: [["Set8_SpaceCorps", "Set8_Brawler"]],
    },
    TFT8_Sylas: {
      tier: 1,
      sets: [80, 85],
      traits: [["Set8_AnimaSquad", "Set8_Renegade"]],
    },
    TFT8_Talon: {
      tier: 1,
      sets: [80],
      traits: [["Set8_OxForce", "Set8_Renegade"]],
    },
    TFT8_WuKong: {
      tier: 1,
      sets: [80, 85],
      traits: [["Set8_ExoPrime", "Set8_Defender"]],
    },
    TFT8_Annie: {
      tier: 2,
      sets: [80, 85],
      traits: [["Set8_OxForce", "Set8_Channeler", "Set8_GenAE"]],
    },
    TFT8_Camille: {
      tier: 2,
      sets: [80, 85],
      traits: [["Set8_Admin", "Set8_Renegade"]],
    },
    TFT8_Draven: {
      tier: 2,
      sets: [80, 85],
      traits: [["Set8_ExoPrime", "Set8_Ace"]],
    },
    TFT8_Ezreal: {
      tier: 2,
      sets: [80, 85],
      traits: [
        ["Set8_UndergroundThe", "Set8_Recon"],
        ["Set8_UndergroundThe", "Set8_Parallel", "Set8_GunMage"],
      ],
    },
    TFT8_Fiora: {
      tier: 2,
      sets: [80, 85],
      traits: [["Set8_OxForce", "Set8_Duelist"]],
    },
    TFT8_Jinx: {
      tier: 2,
      sets: [80, 85],
      traits: [["Set8_AnimaSquad", "Set8_Prankster"]],
    },
    TFT8_LeeSin: {
      tier: 2,
      sets: [80, 85],
      traits: [["Set8_Supers", "Set8_Brawler", "Set8_Heart"]],
    },
    TFT8_Malphite: {
      tier: 2,
      sets: [80, 85],
      traits: [["Set8_Supers", "Set8_Mascot"]],
    },
    TFT8_Pyke: {
      tier: 2,
      sets: [85],
      traits: [["Set8_Riftwalker", "Set8_Hacker"]],
    },
    TFT8_Rell: {
      tier: 2,
      sets: [80, 85],
      traits: [["Set8_StarGuardian", "Set8_Defender"]],
    },
    TFT8_Sivir: {
      tier: 2,
      sets: [80],
      traits: [["Set8_Civilian", "Set8_Deadeye"]],
    },
    TFT8b_Sivir: {
      tier: 2,
      sets: [85],
      traits: [["Set8_Pulsefire", "Set8_Deadeye"]],
    },
    TFT8_Vi: {
      tier: 2,
      sets: [80, 85],
      traits: [["Set8_UndergroundThe", "Set8_Brawler", "Set8_Aegis"]],
    },
    TFT8_Yasuo: {
      tier: 2,
      sets: [80, 85],
      traits: [["Set8_SpaceCorps", "Set8_Duelist"]],
    },
    TFT8_Yuumi: {
      tier: 2,
      sets: [80],
      traits: [["Set8_StarGuardian", "Set8_Mascot", "Set8_Heart"]],
    },
    TFT8_Alistar: {
      tier: 3,
      sets: [80, 85],
      traits: [["Set8_OxForce", "Set8_Mascot", "Set8_Aegis"]],
    },
    TFT8_Chogath: {
      tier: 3,
      sets: [80],
      traits: [["Set8_Threat"]],
    },
    TFT8_GnarBig: {
      tier: 3,
      sets: [85],
      traits: [["Set8_GenAE", "Set8_Prankster"]],
    },
    TFT8_Jax: {
      tier: 3,
      sets: [80, 85],
      traits: [["Set8_ExoPrime", "Set8_Brawler"]],
    },
    TFT8_Kaisa: {
      tier: 3,
      sets: [80, 85],
      traits: [
        ["Set8_StarGuardian", "Set8_Recon"],
        ["Set8_StarGuardian", "Set8_GunMage"],
      ],
    },
    TFT8_Leblanc: {
      tier: 3,
      sets: [80, 85],
      traits: [["Set8_Admin", "Set8_Channeler", "Set8_Hacker"]],
    },
    TFT8_Morgana: {
      tier: 3,
      sets: [85],
      traits: [["Set8_Threat"]],
    },
    TFT8_Nilah: {
      tier: 3,
      sets: [80, 85],
      traits: [["Set8_StarGuardian", "Set8_Duelist"]],
    },
    TFT8_Rammus: {
      tier: 3,
      sets: [80, 85],
      traits: [["Set8_Threat"]],
    },
    TFT8_Riven: {
      tier: 3,
      sets: [80, 85],
      traits: [["Set8_AnimaSquad", "Set8_Defender", "Set8_Brawler"]],
    },
    TFT8_Senna: {
      tier: 3,
      sets: [80],
      traits: [["Set8_SpaceCorps", "Set8_Deadeye"]],
    },
    TFT8_Shen: {
      tier: 3,
      sets: [85],
      traits: [["Set8_Pulsefire", "Set8_Defender", "Set8_Hacker"]],
    },
    TFT8_Sona: {
      tier: 3,
      sets: [80, 85],
      traits: [["Set8_UndergroundThe", "Set8_Channeler", "Set8_Heart"]],
    },
    TFT8_Vayne: {
      tier: 3,
      sets: [80, 85],
      traits: [
        ["Set8_AnimaSquad", "Set8_Duelist", "Set8_Recon"],
        ["Set8_AnimaSquad", "Set8_Duelist"],
      ],
    },
    TFT8_Velkoz: {
      tier: 3,
      sets: [80],
      traits: [["Set8_Threat"]],
    },
    TFT8_Vex: {
      tier: 3,
      sets: [85],
      traits: [["Set8_Riftwalker", "Set8_Mascot"]],
    },
    TFT8_Zoe: {
      tier: 3,
      sets: [80],
      traits: [["Set8_GenAE", "Set8_Prankster", "Set8_Hacker"]],
    },

    TFT8_Aatrox: {
      tier: 4,
      sets: [85],
      traits: [["Set8_Threat"]],
    },
    TFT8_AurelionSol: {
      tier: 4,
      sets: [80, 85],
      traits: [["Set8_Threat"]],
    },
    TFT8_BelVeth: {
      tier: 4,
      sets: [80, 85],
      traits: [["Set8_Threat"]],
    },
    TFT8_Ekko: {
      tier: 4,
      sets: [80, 85],
      traits: [["Set8_StarGuardian", "Set8_Prankster", "Set8_Aegis"]],
    },
    TFT8_Garen: {
      tier: 4,
      sets: [85],
      traits: [["Set8_ExoPrime", "Set8_Defender"]],
    },
    TFT8_Jhin: {
      tier: 4,
      sets: [85],
      traits: [["Set8_Riftwalker", "Set8_Renegade"]],
    },
    TFT8_MissFortune: {
      tier: 4,
      sets: [80, 85],
      traits: [
        ["Set8_AnimaSquad", "Set8_Ace"],
        ["Set8_AnimaSquad", "Set8_Ace", "Set8_GunMage"],
      ],
    },
    TFT8_Neeko: {
      tier: 4,
      sets: [85],
      traits: [["Set8_StarGuardian", "Set8_Channeler"]],
    },
    TFT8_Samira: {
      tier: 4,
      sets: [80, 85],
      traits: [["Set8_UndergroundThe", "Set8_Deadeye", "Set8_Ace"]],
    },
    TFT8_Sejuani: {
      tier: 4,
      sets: [80],
      traits: [["Set8_SpaceCorps", "Set8_Brawler"]],
    },
    TFT8_Sett: {
      tier: 4,
      sets: [80],
      traits: [["Set8_ExoPrime", "Set8_Defender"]],
    },
    TFT8_Soraka: {
      tier: 4,
      sets: [80],
      traits: [["Set8_Admin", "Set8_Heart"]],
    },
    TFT8_Taliyah: {
      tier: 4,
      sets: [80],
      traits: [["Set8_StarGuardian", "Set8_Channeler"]],
    },
    TFT8_TwistedFate: {
      tier: 4,
      sets: [85],
      traits: [["Set8_Pulsefire", "Set8_Duelist", "Set8_Channeler"]],
    },
    TFT8_Viego: {
      tier: 4,
      sets: [80, 85],
      traits: [
        ["Set8_OxForce", "Set8_Renegade"],
        ["Set8_OxForce", "Set8_Heart", "Set8_Renegade"],
      ],
    },
    TFT8_Warwick: {
      tier: 4,
      sets: [85],
      traits: [["Set8_SpaceCorps", "Set8_Admin", "Set8_Brawler"]],
    },
    TFT8_Zac: {
      tier: 4,
      sets: [80],
      traits: [["Set8_Threat"]],
    },
    TFT8_Zed: {
      tier: 4,
      sets: [80],
      traits: [["Set8_SpaceCorps", "Set8_Duelist", "Set8_Hacker"]],
    },

    TFT8_Aphelios: {
      tier: 5,
      sets: [80],
      traits: [["Set8_OxForce", "Set8_Arsenal", "Set8_Deadeye"]],
    },
    TFT8_Fiddlesticks: {
      tier: 5,
      sets: [80, 85],
      traits: [["Set8_Corrupted", "Set8_Threat"]],
    },
    TFT8_Janna: {
      tier: 5,
      sets: [80, 85],
      traits: [
        ["Set8_Civilian", "Set8_Forecaster", "Set8_Channeler"],
        ["Set8_Forecaster", "Set8_Channeler"],
      ],
    },
    TFT8_Leona: {
      tier: 5,
      sets: [80, 85],
      traits: [["Set8_ExoPrime", "Set8_Renegade", "Set8_Aegis"]],
    },
    TFT8_Mordekaiser: {
      tier: 5,
      sets: [80, 85],
      traits: [["Set8_SpaceCorps", "Set8_Ace"]],
    },
    TFT8_Nunu: {
      tier: 5,
      sets: [80, 85],
      traits: [["Set8_GenAE", "Set8_Mascot"]],
    },
    TFT8_Syndra: {
      tier: 5,
      sets: [80, 85],
      traits: [["Set8_StarGuardian", "Set8_Heart"]],
    },
    TFT8_EzrealFuture: {
      tier: 5,
      sets: [85],
      traits: [["Set8_Pulsefire", "Set8_Parallel", "Set8_Deadeye"]],
    },
    TFT8_Urgot: {
      tier: 5,
      sets: [80, 85],
      traits: [["Set8_Threat"]],
    },

    TFT9_Cassiopeia: {
      tier: 1,
      sets: [90, 95],
      traits: [["Set9_Noxus", "Set9_Shurima", "Set9_Preserver"]],
    },
    TFT9_ChoGath: {
      tier: 1,
      sets: [90, 95],
      traits: [["Set9_Void", "Set9_Bruiser"]],
    },
    TFT9_Graves: {
      tier: 1,
      sets: [95],
      traits: [["Set9b_Bilgewater", "Set9_Marksman", "Set9_Rogue"]],
    },
    TFT9_Illaoi: {
      tier: 1,
      sets: [95],
      traits: [["Set9b_Bilgewater", "Set9_Bastion"]],
    },
    TFT9_Irelia: {
      tier: 1,
      sets: [90, 95],
      traits: [["Set9_Ionia", "Set9_Challenger"]],
    },
    TFT9_Jhin: {
      tier: 1,
      sets: [90, 95],
      traits: [
        ["Set9_Ionia", "Set9_Deadeye"],
        ["Set9_Ionia", "Set9b_Vanquisher"],
      ],
    },
    TFT9_Kayle: {
      tier: 1,
      sets: [90, 95],
      traits: [["Set9_Demacia", "Set9_Slayer"]],
    },
    TFT9_Malzahar: {
      tier: 1,
      sets: [90, 95],
      traits: [["Set9_Void", "Set9_Sorcerer"]],
    },
    TFT9_Maokai: {
      tier: 1,
      sets: [90],
      traits: [["Set9_ShadowIsles", "Set9_Bastion"]],
    },
    TFT9_Milio: {
      tier: 1,
      sets: [95],
      traits: [["Set9_Ixtal", "Set9_Preserver"]],
    },
    TFT9_Orianna: {
      tier: 1,
      sets: [90, 95],
      traits: [["Set9_Piltover", "Set9_Sorcerer"]],
    },
    TFT9_Poppy: {
      tier: 1,
      sets: [90, 95],
      traits: [
        ["Set9_BandleCity", "Set9_Demacia", "Set9_Bastion"],
        ["Set9_Demacia", "Set9_Bastion"],
      ],
    },
    TFT9_Renekton: {
      tier: 1,
      sets: [90, 95],
      traits: [["Set9_Shurima", "Set9_Bruiser"]],
    },
    TFT9_Samira: {
      tier: 1,
      sets: [90, 95],
      traits: [["Set9_Noxus", "Set9_Challenger"]],
    },
    TFT9_Tristana: {
      tier: 1,
      sets: [90],
      traits: [["Set9_BandleCity", "Set9_Marksman"]],
    },
    TFT9_Viego: {
      tier: 1,
      sets: [90],
      traits: [["Set9_ShadowIsles", "Set9_Rogue"]],
    },
    TFT9_Ashe: {
      tier: 2,
      sets: [90, 95],
      traits: [
        ["Set9_Freljord", "Set9_Deadeye"],
        ["Set9_Freljord", "Set9b_Vanquisher"],
      ],
    },
    TFT9_Galio: {
      tier: 2,
      sets: [90, 95],
      traits: [["Set9_Demacia", "Set9_Preserver"]],
    },
    TFT9_Jinx: {
      tier: 2,
      sets: [90, 95],
      traits: [["Set9_Zaun", "Set9_Marksman"]],
    },
    TFT9_Kassadin: {
      tier: 2,
      sets: [90, 95],
      traits: [["Set9_Void", "Set9_Bastion"]],
    },
    TFT9_Kled: {
      tier: 2,
      sets: [90],
      traits: [["Set9_Noxus", "Set9_BandleCity", "Set9_Slayer"]],
    },
    TFT9_Naafiri: {
      tier: 2,
      sets: [95],
      traits: [["Set9b_Darkin", "Set9_Shurima", "Set9_Challenger"]],
    },
    TFT9_Qiyana: {
      tier: 2,
      sets: [95],
      traits: [["Set9_Ixtal", "Set9_Rogue", "Set9_Slayer"]],
    },
    TFT9_Sett: {
      tier: 2,
      sets: [90, 95],
      traits: [["Set9_Ionia", "Set9_Armorclad"]],
    },
    TFT9_Soraka: {
      tier: 2,
      sets: [90, 95],
      traits: [["Set9_Targon", "Set9_Preserver"]],
    },
    TFT9_Swain: {
      tier: 2,
      sets: [90, 95],
      traits: [["Set9_Noxus", "Set9_Strategist", "Set9_Sorcerer"]],
    },
    TFT9_Taliyah: {
      tier: 2,
      sets: [90, 95],
      traits: [["Set9_Shurima", "Set9_Multicaster"]],
    },
    TFT9_Teemo: {
      tier: 2,
      sets: [90],
      traits: [["Set9_BandleCity", "Set9_Strategist", "Set9_Multicaster"]],
    },
    TFT9_TwistedFate: {
      tier: 2,
      sets: [95],
      traits: [["Set9b_Bilgewater", "Set9_Multicaster"]],
    },
    TFT9_Vi: {
      tier: 2,
      sets: [90, 95],
      traits: [["Set9_Piltover", "Set9_Bruiser"]],
    },
    TFT9_Warwick: {
      tier: 2,
      sets: [90, 95],
      traits: [["Set9_Zaun", "Set9_Armorclad", "Set9_Challenger"]],
    },
    TFT9_Zed: {
      tier: 2,
      sets: [90],
      traits: [["Set9_Ionia", "Set9_Rogue", "Set9_Slayer"]],
    },
    TFT9_Akshan: {
      tier: 3,
      sets: [90],
      traits: [["Set9_Shurima", "Set9_Deadeye"]],
    },
    TFT9_Darius: {
      tier: 3,
      sets: [90, 95],
      traits: [
        ["Set9_Noxus", "Set9_Armorclad"],
        ["Set9_Noxus", "Set9_Armorclad", "Set9b_Vanquisher"],
      ],
    },
    TFT9_Ekko: {
      tier: 3,
      sets: [90, 95],
      traits: [["Set9_Zaun", "Set9_Piltover", "Set9_Rogue"]],
    },
    TFT9_Garen: {
      tier: 3,
      sets: [90],
      traits: [["Set9_Demacia", "Set9_Armorclad"]],
    },
    TFT9_Jayce: {
      tier: 3,
      sets: [90, 95],
      traits: [["Set9_Piltover", "Set9_Marksman"]],
    },
    TFT9_Kalista: {
      tier: 3,
      sets: [90],
      traits: [["Set9_ShadowIsles", "Set9_Challenger"]],
    },
    TFT9_Karma: {
      tier: 3,
      sets: [90, 95],
      traits: [["Set9_Ionia", "Set9_Preserver"]],
    },
    TFT9_Katarina: {
      tier: 3,
      sets: [90, 95],
      traits: [["Set9_Noxus", "Set9_Rogue"]],
    },
    TFT9_Lissandra: {
      tier: 3,
      sets: [90],
      traits: [["Set9_Freljord", "Set9_Preserver"]],
    },
    TFT9_MissFortune: {
      tier: 3,
      sets: [95],
      traits: [["Set9b_Bilgewater", "Set9_Strategist"]],
    },
    TFT9_Nautilus: {
      tier: 3,
      sets: [95],
      traits: [["Set9b_Bilgewater", "Set9_Armorclad"]],
    },
    TFT9_Neeko: {
      tier: 3,
      sets: [95],
      traits: [["Set9_Ixtal", "Set9_Bastion"]],
    },
    TFT9_RekSai: {
      tier: 3,
      sets: [90, 95],
      traits: [
        ["Set9_Void", "Set9_Bruiser"],
        ["Set9_Void", "Set9_Bruiser", "Set9_Slayer"],
      ],
    },
    TFT9_Sona: {
      tier: 3,
      sets: [90, 95],
      traits: [["Set9_Demacia", "Set9_Multicaster"]],
    },
    TFT9_Taric: {
      tier: 3,
      sets: [90, 95],
      traits: [["Set9_Targon", "Set9_Bastion", "Set9_Sorcerer"]],
    },
    TFT9_VelKoz: {
      tier: 3,
      sets: [90, 95],
      traits: [["Set9_Void", "Set9_Multicaster", "Set9_Sorcerer"]],
    },
    TFT9_Aphelios: {
      tier: 4,
      sets: [90, 95],
      traits: [
        ["Set9_Targon", "Set9_Deadeye"],
        ["Set9_Targon", "Set9_Marksman"],
      ],
    },
    TFT9_Azir: {
      tier: 4,
      sets: [90, 95],
      traits: [["Set9_Shurima", "Set9_Strategist"]],
    },
    TFT9_Fiora: {
      tier: 4,
      sets: [95],
      traits: [["Set9_Demacia", "Set9_Challenger"]],
    },
    TFT9_Gwen: {
      tier: 4,
      sets: [90],
      traits: [["Set9_ShadowIsles", "Set9_Slayer"]],
    },
    TFT9_JarvanIV: {
      tier: 4,
      sets: [90, 95],
      traits: [["Set9_Demacia", "Set9_Strategist"]],
    },
    TFT9_KaiSa: {
      tier: 4,
      sets: [90, 95],
      traits: [["Set9_Void", "Set9_Challenger"]],
    },
    TFT9_Lux: {
      tier: 4,
      sets: [90],
      traits: [["Set9_Demacia", "Set9_Sorcerer"]],
    },
    TFT9_Mordekaiser: {
      tier: 4,
      sets: [95],
      traits: [["Set9_Noxus", "Set9_Slayer"]],
    },
    TFT9_Nasus: {
      tier: 4,
      sets: [90, 95],
      traits: [["Set9_Shurima", "Set9_Armorclad"]],
    },
    TFT9_Nilah: {
      tier: 4,
      sets: [95],
      traits: [["Set9b_Bilgewater", "Set9b_Vanquisher"]],
    },
    TFT9_Quinn: {
      tier: 3,
      sets: [95],
      traits: [["Set9_Demacia", "Set9_Slayer"]],
    },
    TFT9_Sejuani: {
      tier: 4,
      sets: [90, 95],
      traits: [["Set9_Freljord", "Set9_Bruiser"]],
    },
    TFT9_Shen: {
      tier: 4,
      sets: [90, 95],
      traits: [["Set9_Ionia", "Set9_Bastion", "Set9_Preserver"]],
    },
    TFT9_Silco: {
      tier: 4,
      sets: [95],
      traits: [["Set9_Zaun", "Set9_Sorcerer"]],
    },
    TFT9_Urgot: {
      tier: 4,
      sets: [90],
      traits: [["Set9_Zaun", "Set9_Deadeye"]],
    },
    TFT9_Xayah: {
      tier: 4,
      sets: [95],
      traits: [["Set9_Ionia", "Set9b_Vanquisher"]],
    },
    TFT9_Yasuo: {
      tier: 4,
      sets: [90],
      traits: [["Set9_Ionia", "Set9_Challenger"]],
    },
    TFT9_Zeri: {
      tier: 4,
      sets: [90],
      traits: [["Set9_Zaun", "Set9_Marksman"]],
    },
    TFT9_Aatrox: {
      tier: 5,
      sets: [90],
      traits: [["Set9_Darkin", "Set9_Slayer", "Set9_Armorclad"]],
    },
    TFT9b_Aatrox: {
      tier: 5,
      sets: [95],
      traits: [["Set9b_Darkin", "Set9_Slayer", "Set9_Armorclad"]],
    },
    TFT9_Ahri: {
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Ionia", "Set9_Sorcerer"]],
    },
    TFT9_BelVeth: {
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Empress", "Set9_Void"]],
    },
    TFT9_Gangplank: {
      tier: 5,
      sets: [95],
      traits: [["Set9b_Bilgewater", "Set9_Marksman", "Set9_ReaverKing"]],
    },
    TFT9_Heimerdinger: {
      tier: 5,
      sets: [90, 95],
      traits: [
        ["Set9_Piltover", "Set9_BandleCity", "Set9_Technogenius"],
        ["Set9_Piltover", "Set9_Technogenius"],
      ],
    },
    TFT9_KSante: {
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Shurima", "Set9_Bastion"]],
    },
    TFT9_Ryze: {
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
      baseVersion: true,
    },
    TFT9_RyzeBandleCity: {
      version: true,
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_RyzeBilgewater: {
      version: true,
      tier: 5,
      sets: [95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_RyzeDemacia: {
      version: true,
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_RyzeFreljord: {
      version: true,
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_RyzeIonia: {
      version: true,
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_RyzeIxtal: {
      version: true,
      tier: 5,
      sets: [95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_RyzeNoxus: {
      version: true,
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_RyzePiltover: {
      version: true,
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_RyzeShadowIsles: {
      version: true,
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_RyzeShurima: {
      version: true,
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_RyzeTargon: {
      version: true,
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_RyzeZaun: {
      version: true,
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Wanderer", "Set9_Preserver"]],
    },
    TFT9_Senna: {
      tier: 5,
      sets: [90],
      traits: [["Set9_ShadowIsles", "Set9_Marksman", "Set9_Redeemer"]],
    },
    TFT9_Sion: {
      tier: 5,
      sets: [90, 95],
      traits: [["Set9_Noxus", "Set9_Bruiser"]],
    },
    TFT10_Annie: {
      tier: 1,
      traits: [["Set10_Emo", "Set10_Spellweaver"]],
      sets: [10],
    },
    TFT10_Corki: {
      tier: 1,
      traits: [["Set10_8Bit", "Set10_Deadeye"]],
      sets: [10],
    },
    TFT10_Evelynn: {
      tier: 1,
      traits: [["Set10_KDA", "Set10_CrowdDive"]],
      sets: [10],
    },
    TFT10_Jinx: {
      tier: 1,
      traits: [["Set10_PunkRock", "Set10_Quickshot"]],
      sets: [10],
    },
    TFT10_Kennen: {
      tier: 1,
      traits: [["Set10_TrueDamage", "Set10_Superfan", "Set10_Guardian"]],
      sets: [10],
    },
    TFT10_KSante: {
      tier: 1,
      traits: [["Set10_PopBand", "Set10_Sentinel"]],
      sets: [10],
    },
    TFT10_Lillia: {
      tier: 1,
      traits: [["Set10_KDA", "Set10_Superfan", "Set10_Sentinel"]],
      sets: [10],
    },
    TFT10_Nami: {
      tier: 1,
      traits: [["Set10_Funk", "Set10_Dazzler"]],
      sets: [10],
    },
    TFT10_Olaf: {
      tier: 1,
      traits: [["Set10_Pentakill", "Set10_Brawler"]],
      sets: [10],
    },
    TFT10_Taric: {
      tier: 1,
      traits: [["Set10_Funk", "Set10_Guardian"]],
      sets: [10],
    },
    TFT10_TahmKench: {
      tier: 1,
      traits: [["Set10_Country", "Set10_Brawler"]],
      sets: [10],
    },
    TFT10_Vi: {
      tier: 1,
      traits: [["Set10_PunkRock", "Set10_Fighter"]],
      sets: [10],
    },
    TFT10_Yasuo: {
      tier: 1,
      traits: [["Set10_TrueDamage", "Set10_Edgelord"]],
      sets: [10],
    },
    TFT10_Aphelios: {
      tier: 2,
      traits: [["Set10_PopBand", "Set10_Quickshot"]],
      sets: [10],
    },
    TFT10_Bard: {
      tier: 2,
      traits: [["Set10_Jazz", "Set10_Dazzler"]],
      sets: [10],
    },
    TFT10_Garen: {
      tier: 2,
      traits: [["Set10_8Bit", "Set10_Sentinel"]],
      sets: [10],
    },
    TFT10_Gnar: {
      tier: 2,
      traits: [["Set10_Pentakill", "Set10_Superfan", "Set10_Fighter"]],
      sets: [10],
    },
    TFT10_Gragas: {
      tier: 2,
      traits: [["Set10_Funk", "Set10_Spellweaver", "Set10_Brawler"]],
      sets: [10],
    },
    TFT10_Jax: {
      tier: 2,
      traits: [["Set10_EDM", "Set10_Fighter"]],
      sets: [10],
    },
    TFT10_Kaisa: {
      tier: 2,
      traits: [["Set10_KDA", "Set10_Deadeye"]],
      sets: [10],
    },
    TFT10_Katarina: {
      tier: 2,
      traits: [["Set10_Country", "Set10_CrowdDive"]],
      sets: [10],
    },
    TFT10_Kayle: {
      tier: 2,
      traits: [["Set10_Pentakill", "Set10_Edgelord"]],
      sets: [10],
    },
    TFT10_Pantheon: {
      tier: 2,
      traits: [["Set10_PunkRock", "Set10_Guardian"]],
      sets: [10],
    },
    TFT10_Senna: {
      tier: 2,
      traits: [["Set10_TrueDamage", "Set10_Quickshot"]],
      sets: [10],
    },
    TFT10_Seraphine: {
      tier: 2,
      traits: [["Set10_KDA", "Set10_Spellweaver"]],
      sets: [10],
    },
    TFT10_Twitch: {
      tier: 2,
      traits: [["Set10_PunkRock", "Set10_Executioner"]],
      sets: [10],
    },
    TFT10_Amumu: {
      tier: 3,
      traits: [["Set10_Emo", "Set10_Guardian"]],
      sets: [10],
    },
    TFT10_Ekko: {
      tier: 3,
      traits: [["Set10_TrueDamage", "Set10_Spellweaver", "Set10_Sentinel"]],
      sets: [10],
    },
    TFT10_Lulu: {
      tier: 3,
      traits: [["Set10_Hyperpop", "Set10_Spellweaver"]],
      sets: [10],
    },
    TFT10_Lux: {
      tier: 3,
      traits: [["Set10_EDM", "Set10_Dazzler"]],
      sets: [10],
    },
    TFT10_MissFortune: {
      tier: 3,
      traits: [["Set10_Jazz", "Set10_Deadeye"]],
      sets: [10],
    },
    TFT10_Mordekaiser: {
      tier: 3,
      traits: [["Set10_Pentakill", "Set10_Sentinel"]],
      sets: [10],
    },
    TFT10_Neeko: {
      tier: 3,
      traits: [["Set10_KDA", "Set10_Superfan", "Set10_Guardian"]],
      sets: [10],
    },
    TFT10_Riven: {
      tier: 3,
      traits: [["Set10_8Bit", "Set10_Edgelord"]],
      sets: [10],
    },
    TFT10_Samira: {
      tier: 3,
      traits: [["Set10_Country", "Set10_Executioner"]],
      sets: [10],
    },
    TFT10_Sett: {
      tier: 3,
      traits: [["Set10_PopBand", "Set10_Brawler", "Set10_Fighter"]],
      sets: [10],
    },
    TFT10_Urgot: {
      tier: 3,
      traits: [["Set10_Country", "Set10_Fighter"]],
      sets: [10],
    },
    TFT10_Vex: {
      tier: 3,
      traits: [["Set10_Emo", "Set10_Executioner"]],
      sets: [10],
    },
    TFT10_Yone: {
      tier: 3,
      traits: [["Set10_PopBand", "Set10_Edgelord", "Set10_CrowdDive"]],
      sets: [10],
    },
    TFT10_Ahri: {
      tier: 4,
      sets: [10],
      traits: [["Set10_KDA", "Set10_Spellweaver"]],
    },
    TFT10_Akali: {
      tier: 4,
      traits: [["Set10_KDA", "Set10_Executioner", "Set10_Breakout"]],
      sets: [10],
      // version: true,
    },
    TFT10_Akali_TrueDamage: {
      tier: 4,
      traits: [["Set10_TrueDamage", "Set10_Executioner", "Set10_Breakout"]],
      sets: [10],
      // version: true,
    },
    TFT10_Blitzcrank: {
      tier: 4,
      traits: [["Set10_Funk", "Set10_Sentinel"]],
      sets: [10],
    },
    TFT10_Caitlyn: {
      tier: 4,
      traits: [["Set10_8Bit", "Set10_Quickshot"]],
      sets: [10],
    },
    TFT10_Ezreal: {
      tier: 4,
      traits: [["Set10_PopBand", "Set10_Deadeye"]],
      sets: [10],
    },
    TFT10_Karthus: {
      tier: 4,
      traits: [["Set10_Pentakill", "Set10_Executioner"]],
      sets: [10],
    },
    TFT10_Poppy: {
      tier: 4,
      traits: [["Set10_Emo", "Set10_Fighter"]],
      sets: [10],
    },
    TFT10_Thresh: {
      tier: 4,
      traits: [["Set10_Country", "Set10_Guardian"]],
      sets: [10],
    },
    TFT10_TwistedFate: {
      tier: 4,
      traits: [["Set10_Funk", "Set10_Dazzler"]],
      sets: [10],
    },
    TFT10_Viego: {
      tier: 4,
      traits: [["Set10_Pentakill", "Set10_Edgelord"]],
      sets: [10],
    },
    TFT10_Zac: {
      tier: 4,
      traits: [["Set10_EDM", "Set10_Brawler"]],
      sets: [10],
    },
    TFT10_Zed: {
      tier: 4,
      traits: [["Set10_EDM", "Set10_CrowdDive"]],
      sets: [10],
    },
    TFT10_Illaoi: {
      tier: 5,
      traits: [["Set10_IllBeats", "Set10_Brawler"]],
      sets: [10],
    },
    TFT10_Jhin: {
      tier: 5,
      traits: [["Set10_Classical", "Set10_Deadeye"]],
      sets: [10],
    },
    TFT10_Kayn: {
      tier: 5,
      traits: [["Set10_PopBand", "Set10_Edgelord", "Set10_TwoSides"]],
      sets: [10],
    },
    TFT10_Lucian: {
      tier: 5,
      traits: [["Set10_Jazz", "Set10_Quickshot"]],
      sets: [10],
    },
    TFT10_Qiyana: {
      tier: 5,
      traits: [["Set10_TrueDamage", "Set10_CrowdDive"]],
      sets: [10],
    },
    TFT10_Sona: {
      tier: 5,
      traits: [["Set10_DJ", "Set10_Spellweaver"]],
      sets: [10],
    },
    TFT10_Yorick: {
      tier: 5,
      traits: [["Set10_Pentakill", "Set10_Fighter", "Set10_Guardian"]],
      sets: [10],
    },
    TFT10_Ziggs: {
      tier: 5,
      traits: [["Set10_Hyperpop", "Set10_Dazzler"]],
      sets: [10],
    },
    TFT11_Ahri: {
      tier: 1,
      traits: [["TFT11_Fated", "TFT11_Arcanist"]],
      sets: [110],
    },
    TFT11_Caitlyn: {
      tier: 1,
      traits: [["TFT11_Ghostly", "TFT11_Sniper"]],
      sets: [110],
    },
    TFT11_ChoGath: {
      tier: 1,
      traits: [["TFT11_Mythic", "TFT11_Behemoth"]],
      sets: [110],
    },
    TFT11_Darius: {
      tier: 1,
      traits: [["TFT11_Umbral", "TFT11_Duelist"]],
      sets: [110],
    },
    TFT11_Garen: {
      tier: 1,
      traits: [["TFT11_Storyweaver", "TFT11_Warden"]],
      sets: [110],
    },
    TFT11_Jax: {
      tier: 1,
      traits: [["TFT11_InkShadow", "TFT11_Warden"]],
      sets: [110],
    },
    TFT11_KhaZix: {
      tier: 1,
      traits: [["TFT11_Heavenly", "TFT11_Reaper"]],
      sets: [110],
    },
    TFT11_FortuneYord: {
      tier: 1,
      traits: [["TFT11_Fortune", "TFT11_Bruiser"]],
      sets: [110],
    },
    TFT11_KogMaw: {
      tier: 1,
      traits: [["TFT11_Mythic", "TFT11_Sniper", "TFT11_Invoker"]],
      sets: [110],
    },
    TFT11_Malphite: {
      tier: 1,
      traits: [["TFT11_Heavenly", "TFT11_Behemoth"]],
      sets: [110],
    },
    TFT11_RekSai: {
      tier: 1,
      traits: [["TFT11_Dryad", "TFT11_Bruiser"]],
      sets: [110],
    },
    TFT11_Sivir: {
      tier: 1,
      traits: [["TFT11_Storyweaver", "TFT11_Trickshot"]],
      sets: [110],
    },
    TFT11_Yasuo: {
      tier: 1,
      traits: [["TFT11_Fated", "TFT11_Duelist"]],
      sets: [110],
    },
    TFT11_Aatrox: {
      tier: 2,
      traits: [["TFT11_InkShadow", "TFT11_Ghostly", "TFT11_Bruiser"]],
      sets: [110],
    },
    TFT11_Gnar: {
      tier: 2,
      traits: [["TFT11_Dryad", "TFT11_Warden"]],
      sets: [110],
    },
    TFT11_Janna: {
      tier: 2,
      traits: [["TFT11_Dragonlord", "TFT11_Invoker"]],
      sets: [110],
    },
    TFT11_Kindred: {
      tier: 2,
      traits: [["TFT11_Dryad", "TFT11_Fated", "TFT11_Reaper"]],
      sets: [110],
    },
    TFT11_Lux: {
      tier: 2,
      traits: [["TFT11_Porcelain", "TFT11_Arcanist"]],
      sets: [110],
    },
    TFT11_Neeko: {
      tier: 2,
      traits: [["TFT11_Mythic", "TFT11_Heavenly", "TFT11_Arcanist"]],
      sets: [110],
    },
    TFT11_Qiyana: {
      tier: 2,
      traits: [["TFT11_Heavenly", "TFT11_Duelist"]],
      sets: [110],
    },
    TFT11_Riven: {
      tier: 2,
      traits: [["TFT11_Storyweaver", "TFT11_Altruist", "TFT11_Bruiser"]],
      sets: [110],
    },
    TFT11_Senna: {
      tier: 2,
      traits: [["TFT11_InkShadow", "TFT11_Sniper"]],
      sets: [110],
    },
    TFT11_Shen: {
      tier: 2,
      traits: [["TFT11_Ghostly", "TFT11_Behemoth"]],
      sets: [110],
    },
    TFT11_Teemo: {
      tier: 2,
      traits: [["TFT11_Fortune", "TFT11_Trickshot"]],
      sets: [110],
    },
    TFT11_Yorick: {
      tier: 2,
      traits: [["TFT11_Umbral", "TFT11_Behemoth"]],
      sets: [110],
    },
    TFT11_Zyra: {
      tier: 2,
      traits: [["TFT11_Storyweaver", "TFT11_Sage"]],
      sets: [110],
    },
    TFT11_Alune: {
      tier: 3,
      traits: [["TFT11_Umbral", "TFT11_Invoker"]],
      sets: [110],
    },
    TFT11_Amumu: {
      tier: 3,
      traits: [["TFT11_Porcelain", "TFT11_Warden"]],
      sets: [110],
    },
    TFT11_Aphelios: {
      tier: 3,
      traits: [["TFT11_Fated", "TFT11_Sniper"]],
      sets: [110],
    },
    TFT11_Bard: {
      tier: 3,
      traits: [["TFT11_Mythic", "TFT11_Trickshot"]],
      sets: [110],
    },
    TFT11_Diana: {
      tier: 3,
      traits: [["TFT11_Dragonlord", "TFT11_Sage"]],
      sets: [110],
    },
    TFT11_Illaoi: {
      tier: 3,
      traits: [["TFT11_Ghostly", "TFT11_Arcanist", "TFT11_Warden"]],
      sets: [110],
    },
    TFT11_Soraka: {
      tier: 3,
      traits: [["TFT11_Heavenly", "TFT11_Altruist"]],
      sets: [110],
    },
    TFT11_TahmKench: {
      tier: 3,
      traits: [["TFT11_Mythic", "TFT11_Bruiser"]],
      sets: [110],
    },
    TFT11_Thresh: {
      tier: 3,
      traits: [["TFT11_Fated", "TFT11_Behemoth"]],
      sets: [110],
    },
    TFT11_Tristana: {
      tier: 3,
      traits: [["TFT11_Fortune", "TFT11_Duelist"]],
      sets: [110],
    },
    TFT11_Volibear: {
      tier: 3,
      traits: [["TFT11_InkShadow", "TFT11_Duelist"]],
      sets: [110],
    },
    TFT11_Yone: {
      tier: 3,
      traits: [["TFT11_Umbral", "TFT11_Reaper"]],
      sets: [110],
    },
    TFT11_Zoe: {
      tier: 3,
      traits: [["TFT11_Storyweaver", "TFT11_Fortune", "TFT11_Arcanist"]],
      sets: [110],
    },
    TFT11_Annie: {
      tier: 4,
      traits: [["TFT11_Fortune", "TFT11_Invoker"]],
      sets: [110],
    },
    TFT11_Ashe: {
      tier: 4,
      traits: [["TFT11_Porcelain", "TFT11_Sniper"]],
      sets: [110],
    },
    TFT11_Galio: {
      tier: 4,
      traits: [["TFT11_Storyweaver", "TFT11_Bruiser"]],
      sets: [110],
    },
    TFT11_Kaisa: {
      tier: 4,
      traits: [["TFT11_InkShadow", "TFT11_Trickshot"]],
      sets: [110],
    },
    TFT11_Kayn: {
      tier: 4,
      traits: [["TFT11_Ghostly", "TFT11_Reaper"]],
      sets: [110],
    },
    TFT11_LeeSin: {
      tier: 4,
      traits: [["TFT11_Dragonlord", "TFT11_Duelist"]],
      sets: [110],
    },
    TFT11_Lillia: {
      tier: 4,
      traits: [["TFT11_Mythic", "TFT11_Invoker"]],
      sets: [110],
    },
    TFT11_Morgana: {
      tier: 4,
      traits: [["TFT11_Ghostly", "TFT11_Sage"]],
      sets: [110],
    },
    TFT11_Nautilus: {
      tier: 4,
      traits: [["TFT11_Mythic", "TFT11_Warden"]],
      sets: [110],
    },
    TFT11_Ornn: {
      tier: 4,
      traits: [["TFT11_Dryad", "TFT11_Behemoth"]],
      sets: [110],
    },
    TFT11_Sylas: {
      tier: 4,
      traits: [["TFT11_Umbral", "TFT11_Bruiser"]],
      sets: [110],
    },
    TFT11_Syndra: {
      tier: 4,
      traits: [["TFT11_Fated", "TFT11_Arcanist"]],
      sets: [110],
    },
    TFT11_Azir: {
      tier: 5,
      traits: [["TFT11_Dryad", "TFT11_Invoker"]],
      sets: [110],
    },
    TFT11_Hwei: {
      tier: 5,
      traits: [["TFT11_Mythic", "TFT11_Artist"]],
      sets: [110],
    },
    TFT11_Irelia: {
      tier: 5,
      traits: [["TFT11_Storyweaver", "TFT11_Duelist"]],
      sets: [110],
    },
    TFT11_Lissandra: {
      tier: 5,
      traits: [["TFT11_Porcelain", "TFT11_Arcanist"]],
      sets: [110],
    },
    TFT11_Rakan: {
      tier: 5,
      traits: [["TFT11_Dragonlord", "TFT11_Lovers", "TFT11_Altruist"]],
      sets: [110],
    },
    TFT11_Sett: {
      tier: 5,
      traits: [["TFT11_Fated", "TFT11_Umbral", "TFT11_Warden"]],
      sets: [110],
    },
    TFT11_Udyr: {
      tier: 5,
      traits: [["TFT11_InkShadow", "TFT11_SpiritWalker", "TFT11_Behemoth"]],
      sets: [110],
    },
    TFT11_WuKong: {
      tier: 5,
      traits: [["TFT11_Great", "TFT11_Heavenly", "TFT11_Sage"]],
      sets: [110],
    },
    TFT11_Xayah: {
      tier: 5,
      traits: [["TFT11_Dragonlord", "TFT11_Lovers", "TFT11_Trickshot"]],
      sets: [110],
    },
    TFT12_Ashe: {
      tier: 1,
      traits: [["TFT12_Eldritch", "TFT12_Multistriker"]],
      sets: [120],
      code: "03",
    },
    TFT12_Blitzcrank: {
      tier: 1,
      traits: [["TFT12_Honeymancy", "TFT12_Vanguard"]],
      sets: [120],
      code: "05",
    },
    TFT12_Elise: {
      tier: 1,
      traits: [["TFT12_Eldritch", "TFT12_Shapeshifter"]],
      sets: [120],
      code: "0a",
    },
    TFT12_Jax: {
      tier: 1,
      traits: [["TFT12_Chrono", "TFT12_Multistriker"]],
      sets: [120],
      code: "11",
    },
    TFT12_Jayce: {
      tier: 1,
      traits: [["TFT12_Portal", "TFT12_Shapeshifter"]],
      sets: [120],
      code: "12",
    },
    TFT12_Lillia: {
      tier: 1,
      traits: [["TFT12_Faerie", "TFT12_Bastion"]],
      sets: [120],
      code: "19",
    },
    TFT12_Nomsy: {
      tier: 1,
      traits: [["TFT12_Dragon", "TFT12_Hunter"]],
      sets: [120],
      code: "21",
    },
    TFT12_Poppy: {
      tier: 1,
      traits: [["TFT12_Witchcraft", "TFT12_Bastion"]],
      sets: [120],
      code: "25",
    },
    TFT12_Seraphine: {
      tier: 1,
      traits: [["TFT12_Faerie", "TFT12_Mage"]],
      sets: [120],
      code: "29",
    },
    TFT12_Soraka: {
      tier: 1,
      traits: [["TFT12_Sugarcraft", "TFT12_Mage"]],
      sets: [120],
      code: "2d",
    },
    TFT12_Twitch: {
      tier: 1,
      traits: [["TFT12_Frost", "TFT12_Hunter"]],
      sets: [120],
      code: "33",
    },
    TFT12_Warwick: {
      tier: 1,
      traits: [["TFT12_Frost", "TFT12_Vanguard"]],
      sets: [120],
      code: "37",
    },
    TFT12_Ziggs: {
      tier: 1,
      traits: [["TFT12_Honeymancy", "TFT12_Incantor"]],
      sets: [120],
      code: "3a",
    },
    TFT12_Zoe: {
      tier: 1,
      traits: [["TFT12_Portal", "TFT12_Witchcraft", "TFT12_Scholar"]],
      sets: [120],
      code: "3c",
    },
    TFT12_Ahri: {
      tier: 2,
      traits: [["TFT12_Arcana", "TFT12_Scholar"]],
      sets: [120],
      code: "01",
    },
    TFT12_Akali: {
      tier: 2,
      traits: [["TFT12_Pyro", "TFT12_Warrior", "TFT12_Multistriker"]],
      sets: [120],
      code: "02",
    },
    TFT12_Cassiopeia: {
      tier: 2,
      traits: [["TFT12_Witchcraft", "TFT12_Incantor"]],
      sets: [120],
      code: "08",
    },
    TFT12_Galio: {
      tier: 2,
      traits: [["TFT12_Portal", "TFT12_Vanguard", "TFT12_Mage"]],
      sets: [120],
      code: "0d",
    },
    TFT12_Kassadin: {
      tier: 2,
      traits: [["TFT12_Portal", "TFT12_Multistriker"]],
      sets: [120],
      code: "16",
    },
    TFT12_KogMaw: {
      tier: 2,
      traits: [["TFT12_Honeymancy", "TFT12_Hunter"]],
      sets: [120],
      code: "18",
    },
    TFT12_Nilah: {
      tier: 2,
      traits: [["TFT12_Eldritch", "TFT12_Warrior"]],
      sets: [120],
      code: "20",
    },
    TFT12_Nunu: {
      tier: 2,
      traits: [["TFT12_Honeymancy", "TFT12_Bastion"]],
      sets: [120],
      code: "23",
    },
    TFT12_Rumble: {
      tier: 2,
      traits: [["TFT12_Sugarcraft", "TFT12_Vanguard", "TFT12_Blaster"]],
      sets: [120],
      code: "27",
    },
    TFT12_Shyvana: {
      tier: 2,
      traits: [["TFT12_Dragon", "TFT12_Shapeshifter"]],
      sets: [120],
      code: "2b",
    },
    TFT12_Syndra: {
      tier: 2,
      traits: [["TFT12_Eldritch", "TFT12_Incantor"]],
      sets: [120],
      code: "2f",
    },
    TFT12_Tristana: {
      tier: 2,
      traits: [["TFT12_Faerie", "TFT12_Blaster"]],
      sets: [120],
      code: "32",
    },
    TFT12_Zilean: {
      tier: 2,
      traits: [["TFT12_Frost", "TFT12_Chrono", "TFT12_Preserver"]],
      sets: [120],
      code: "3b",
    },
    TFT12_Bard: {
      tier: 3,
      traits: [["TFT12_Sugarcraft", "TFT12_Preserver", "TFT12_Scholar"]],
      sets: [120],
      code: "04",
    },
    TFT12_Ezreal: {
      tier: 3,
      traits: [["TFT12_Portal", "TFT12_Blaster"]],
      sets: [120],
      code: "0b",
    },
    TFT12_Hecarim: {
      tier: 3,
      traits: [["TFT12_Arcana", "TFT12_Bastion", "TFT12_Multistriker"]],
      sets: [120],
      code: "0f",
    },
    TFT12_Hwei: {
      tier: 3,
      traits: [["TFT12_Frost", "TFT12_Blaster"]],
      sets: [120],
      code: "10",
    },
    TFT12_Jinx: {
      tier: 3,
      traits: [["TFT12_Sugarcraft", "TFT12_Hunter"]],
      sets: [120],
      code: "13",
    },
    TFT12_Katarina: {
      tier: 3,
      traits: [["TFT12_Faerie", "TFT12_Warrior"]],
      sets: [120],
      code: "17",
    },
    TFT12_Mordekaiser: {
      tier: 3,
      traits: [["TFT12_Eldritch", "TFT12_Vanguard"]],
      sets: [120],
      code: "1b",
    },
    TFT12_Neeko: {
      tier: 3,
      traits: [["TFT12_Witchcraft", "TFT12_Shapeshifter"]],
      sets: [120],
      code: "1f",
    },
    TFT12_Shen: {
      tier: 3,
      traits: [["TFT12_Pyro", "TFT12_Bastion"]],
      sets: [120],
      code: "2a",
    },
    TFT12_Swain: {
      tier: 3,
      traits: [["TFT12_Frost", "TFT12_Shapeshifter"]],
      sets: [120],
      code: "2e",
    },
    TFT12_Veigar: {
      tier: 3,
      traits: [["TFT12_Honeymancy", "TFT12_Mage"]],
      sets: [120],
      code: "35",
    },
    TFT12_Vex: {
      tier: 3,
      traits: [["TFT12_Chrono", "TFT12_Mage"]],
      sets: [120],
      code: "36",
    },
    TFT12_Wukong: {
      tier: 3,
      traits: [["TFT12_Druid"]],
      sets: [120],
      code: "38",
    },
    TFT12_Fiora: {
      tier: 4,
      traits: [["TFT12_Witchcraft", "TFT12_Warrior"]],
      sets: [120],
      code: "0c",
    },
    TFT12_Gwen: {
      tier: 4,
      traits: [["TFT12_Sugarcraft", "TFT12_Warrior"]],
      sets: [120],
      code: "0e",
    },
    TFT12_Kalista: {
      tier: 4,
      traits: [["TFT12_Faerie", "TFT12_Multistriker"]],
      sets: [120],
      code: "14",
    },
    TFT12_Karma: {
      tier: 4,
      traits: [["TFT12_Chrono", "TFT12_Incantor"]],
      sets: [120],
      code: "15",
    },
    TFT12_Nami: {
      tier: 4,
      traits: [["TFT12_Eldritch", "TFT12_Mage"]],
      sets: [120],
      code: "1d",
    },
    TFT12_Nasus: {
      tier: 4,
      traits: [["TFT12_Pyro", "TFT12_Shapeshifter"]],
      sets: [120],
      code: "1e",
    },
    TFT12_Olaf: {
      tier: 4,
      traits: [["TFT12_Frost", "TFT12_Hunter"]],
      sets: [120],
      code: "24",
    },
    TFT12_Rakan: {
      tier: 4,
      traits: [["TFT12_Faerie", "TFT12_Preserver"]],
      sets: [120],
      code: "26",
    },
    TFT12_Ryze: {
      tier: 4,
      traits: [["TFT12_Portal", "TFT12_Scholar"]],
      sets: [120],
      code: "28",
    },
    TFT12_TahmKench: {
      tier: 4,
      traits: [["TFT12_Arcana", "TFT12_Vanguard"]],
      sets: [120],
      code: "30",
    },
    TFT12_Taric: {
      tier: 4,
      traits: [["TFT12_Portal", "TFT12_Bastion"]],
      sets: [120],
      code: "31",
    },
    TFT12_Varus: {
      tier: 4,
      traits: [["TFT12_Pyro", "TFT12_Blaster"]],
      sets: [120],
      code: "34",
    },
    TFT12_Briar: {
      tier: 5,
      traits: [["TFT12_Ravenous", "TFT12_Eldritch", "TFT12_Shapeshifter"]],
      sets: [120],
      code: "06",
    },
    TFT12_Camille: {
      tier: 5,
      traits: [["TFT12_Chrono", "TFT12_Multistriker"]],
      sets: [120],
      code: "07",
    },
    TFT12_Diana: {
      tier: 5,
      traits: [["TFT12_Frost", "TFT12_Bastion"]],
      sets: [120],
      code: "09",
    },
    TFT12_Milio: {
      tier: 5,
      traits: [["TFT12_Faerie", "TFT12_Scholar"]],
      sets: [120],
      code: "1a",
    },
    TFT12_Morgana: {
      tier: 5,
      traits: [["TFT12_Witchcraft", "TFT12_BatQueen", "TFT12_Preserver"]],
      sets: [120],
      code: "1c",
    },
    TFT12_Norra: {
      tier: 5,
      traits: [["TFT12_Portal", "TFT12_Explorer", "TFT12_Mage"]],
      sets: [120],
      code: "22",
    },
    TFT12_Smolder: {
      tier: 5,
      traits: [["TFT12_Dragon", "TFT12_Blaster"]],
      sets: [120],
      code: "2c",
    },
    TFT12_Xerath: {
      tier: 5,
      traits: [["TFT12_Arcana", "TFT12_Ascendant"]],
      sets: [120],
      code: "39",
    },
    TFT13_Amumu: {
      tier: 1,
      traits: [["TFT13_Hextech", "TFT13_Watcher"]],
      sets: [130],
      code: "",
    },
    TFT13_Darius: {
      tier: 1,
      traits: [["TFT13_Warband", "TFT13_Watcher"]],
      sets: [130],
      code: "",
    },
    TFT13_Draven: {
      tier: 1,
      traits: [["TFT13_Warband", "TFT13_Pugilist"]],
      sets: [130],
      code: "",
    },
    TFT13_Irelia: {
      tier: 1,
      traits: [["TFT13_Rebel", "TFT13_Titan"]],
      sets: [130],
      code: "",
    },
    TFT13_Lux: {
      tier: 1,
      traits: [["TFT13_Academy", "TFT13_Sorcerer"]],
      sets: [130],
      code: "",
    },
    TFT13_Shooter: {
      tier: 1,
      traits: [["TFT13_Squad", "TFT13_Sniper"]],
      sets: [130],
      code: "",
    },
    TFT13_Morgana: {
      tier: 1,
      traits: [["TFT13_Cabal", "TFT13_Invoker"]],
      sets: [130],
      code: "",
    },
    TFT13_Blue: {
      tier: 1,
      traits: [["TFT13_Family", "TFT13_Scrap", "TFT13_Ambusher"]],
      sets: [130],
      code: "",
    },
    TFT13_Singed: {
      tier: 1,
      traits: [["TFT13_Crime", "TFT13_Titan"]],
      sets: [130],
      code: "",
    },
    TFT13_Fish: {
      tier: 1,
      traits: [["TFT13_Squad", "TFT13_Bruiser"]],
      sets: [130],
      code: "",
    },
    TFT13_Trundle: {
      tier: 1,
      traits: [["TFT13_Scrap", "TFT13_Bruiser"]],
      sets: [130],
      code: "",
    },
    TFT13_Vex: {
      tier: 1,
      traits: [["TFT13_Rebel", "TFT13_Invoker"]],
      sets: [130],
      code: "",
    },
    TFT13_Red: {
      tier: 1,
      traits: [["TFT13_Family", "TFT13_Pugilist"]],
      sets: [130],
      code: "",
    },
    TFT13_Zyra: {
      tier: 1,
      traits: [["TFT13_Experiment", "TFT13_Sorcerer"]],
      sets: [130],
      code: "",
    },
    TFT13_Akali: {
      tier: 2,
      traits: [["TFT13_Rebel", "TFT13_Challenger"]],
      sets: [130],
      code: "",
    },
    TFT13_Camille: {
      tier: 2,
      traits: [["TFT13_Squad", "TFT13_Ambusher"]],
      sets: [130],
      code: "",
    },
    TFT13_Leona: {
      tier: 2,
      traits: [["TFT13_Academy", "TFT13_Titan"]],
      sets: [130],
      code: "",
    },
    TFT13_Nocturne: {
      tier: 2,
      traits: [["TFT13_Hextech", "TFT13_Challenger"]],
      sets: [130],
      code: "",
    },
    TFT13_Rell: {
      tier: 2,
      traits: [["TFT13_Warband", "TFT13_Titan", "TFT13_Invoker"]],
      sets: [130],
      code: "",
    },
    TFT13_RenataGlasc: {
      tier: 2,
      traits: [["TFT13_Crime", "TFT13_Invoker"]],
      sets: [130],
      code: "",
    },
    TFT13_Sett: {
      tier: 2,
      traits: [["TFT13_Rebel", "TFT13_Bruiser"]],
      sets: [130],
      code: "",
    },
    TFT13_Tristana: {
      tier: 2,
      traits: [["TFT13_Ambassador", "TFT13_Martialist"]],
      sets: [130],
      code: "",
    },
    TFT13_Urgot: {
      tier: 2,
      traits: [["TFT13_Experiment", "TFT13_Pugilist", "TFT13_Martialist"]],
      sets: [130],
      code: "",
    },
    TFT13_Prime: {
      tier: 2,
      traits: [["TFT13_Family", "TFT13_Watcher"]],
      sets: [130],
      code: "",
    },
    TFT13_Vladimir: {
      tier: 2,
      traits: [["TFT13_Cabal", "TFT13_Watcher", "TFT13_Sorcerer"]],
      sets: [130],
      code: "",
    },
    TFT13_Zeri: {
      tier: 2,
      traits: [["TFT13_Hoverboard", "TFT13_Sniper"]],
      sets: [130],
      code: "",
    },
    TFT13_Ziggs: {
      tier: 2,
      traits: [["TFT13_Scrap", "TFT13_Infused"]],
      sets: [130],
      code: "",
    },
    TFT13_Blitzcrank: {
      tier: 3,
      traits: [["TFT13_Hextech", "TFT13_Infused"]],
      sets: [130],
      code: "",
    },
    TFT13_Cassiopeia: {
      tier: 3,
      traits: [["TFT13_Cabal", "TFT13_Infused"]],
      sets: [130],
      code: "",
    },
    TFT13_Ezreal: {
      tier: 3,
      traits: [["TFT13_Academy", "TFT13_Rebel", "TFT13_Martialist"]],
      sets: [130],
      code: "",
    },
    TFT13_Gangplank: {
      tier: 3,
      traits: [["TFT13_Scrap", "TFT13_FormSwapper", "TFT13_Pugilist"]],
      sets: [130],
      code: "",
    },
    TFT13_Gangplank_Ranged: {
      tier: 3,
      traits: [["TFT13_Scrap", "TFT13_FormSwapper", "TFT13_Pugilist"]],
      sets: [130],
      code: "",
      special: true,
    },
    TFT13_KogMaw: {
      tier: 3,
      traits: [["TFT13_Hextech", "TFT13_Sniper"]],
      sets: [130],
      code: "",
    },
    TFT13_Beardy: {
      tier: 3,
      traits: [["TFT13_Squad", "TFT13_Titan"]],
      sets: [130],
      code: "",
    },
    TFT13_Nami: {
      tier: 3,
      traits: [["TFT13_Ambassador", "TFT13_Sorcerer"]],
      sets: [130],
      code: "",
    },
    TFT13_NunuWillump: {
      tier: 3,
      traits: [["TFT13_Experiment", "TFT13_Bruiser", "TFT13_Invoker"]],
      sets: [130],
      code: "",
    },
    TFT13_Chainsaw: {
      tier: 3,
      traits: [["TFT13_Crime", "TFT13_Bruiser"]],
      sets: [130],
      code: "",
    },
    TFT13_FlyGuy: {
      tier: 3,
      traits: [["TFT13_Hoverboard", "TFT13_Watcher"]],
      sets: [130],
      code: "",
    },
    TFT13_Gremlin: {
      tier: 3,
      traits: [["TFT13_Crime", "TFT13_Ambusher"]],
      sets: [130],
      code: "",
    },
    TFT13_Swain: {
      tier: 3,
      traits: [["TFT13_Warband", "TFT13_FormSwapper", "TFT13_Sorcerer"]],
      sets: [130],
      code: "",
    },
    TFT13_Swain_Ranged: {
      tier: 3,
      traits: [["TFT13_Warband", "TFT13_FormSwapper", "TFT13_Sorcerer"]],
      sets: [130],
      code: "",
      special: true,
    },
    TFT13_TwistedFate: {
      tier: 3,
      traits: [["TFT13_Squad", "TFT13_Challenger"]],
      sets: [130],
      code: "",
    },
    TFT13_Ambessa: {
      tier: 4,
      traits: [["TFT13_Ambassador", "TFT13_Warband", "TFT13_Challenger"]],
      sets: [130],
      code: "",
    },
    TFT13_Corki: {
      tier: 4,
      traits: [["TFT13_Scrap", "TFT13_Martialist"]],
      sets: [130],
      code: "",
    },
    TFT13_DrMundo: {
      tier: 4,
      traits: [["TFT13_Experiment", "TFT13_Infused"]],
      sets: [130],
      code: "",
    },
    TFT13_Ekko: {
      tier: 4,
      traits: [["TFT13_Hoverboard", "TFT13_Scrap", "TFT13_Ambusher"]],
      sets: [130],
      code: "",
    },
    TFT13_Elise: {
      tier: 4,
      traits: [["TFT13_Cabal", "TFT13_FormSwapper", "TFT13_Bruiser"]],
      sets: [130],
      code: "",
    },
    TFT13_Elise_Ranged: {
      tier: 4,
      traits: [["TFT13_Cabal", "TFT13_FormSwapper", "TFT13_Bruiser"]],
      sets: [130],
      code: "",
      special: true,
    },
    TFT13_Garen: {
      tier: 4,
      traits: [["TFT13_Ambassador", "TFT13_Watcher"]],
      sets: [130],
      code: "",
    },
    TFT13_Heimerdinger: {
      tier: 4,
      traits: [["TFT13_Academy", "TFT13_Invoker"]],
      sets: [130],
      code: "",
    },
    TFT13_Illaoi: {
      tier: 4,
      traits: [["TFT13_Rebel", "TFT13_Titan"]],
      sets: [130],
      code: "",
    },
    TFT13_Silco: {
      tier: 4,
      traits: [["TFT13_Crime", "TFT13_Infused"]],
      sets: [130],
      code: "",
    },
    TFT13_Twitch: {
      tier: 4,
      traits: [["TFT13_Experiment", "TFT13_Sniper"]],
      sets: [130],
      code: "",
    },
    TFT13_Vi: {
      tier: 4,
      traits: [["TFT13_Squad", "TFT13_Pugilist"]],
      sets: [130],
      code: "",
    },
    TFT13_Zoe: {
      tier: 4,
      traits: [["TFT13_Rebel", "TFT13_Sorcerer"]],
      sets: [130],
      code: "",
    },
    TFT13_Caitlyn: {
      tier: 5,
      traits: [["TFT13_Squad", "TFT13_Sniper"]],
      sets: [130],
      code: "",
    },
    TFT13_Jayce: {
      tier: 5,
      traits: [["TFT13_Academy", "TFT13_FormSwapper"]],
      sets: [130],
      code: "",
    },
    TFT13_Jayce_Ranged: {
      tier: 5,
      traits: [["TFT13_Academy", "TFT13_FormSwapper"]],
      sets: [130],
      code: "",
      special: true,
    },
    TFT13_Jinx: {
      tier: 5,
      traits: [["TFT13_Rebel", "TFT13_Ambusher"]],
      sets: [130],
      code: "",
    },
    TFT13_LeBlanc: {
      tier: 5,
      traits: [["TFT13_Cabal", "TFT13_Sorcerer"]],
      sets: [130],
      code: "",
    },
    TFT13_Malzahar: {
      tier: 5,
      traits: [["TFT13_Hextech", "TFT13_Invoker"]],
      sets: [130],
      code: "",
    },
    TFT13_Mordekaiser: {
      tier: 5,
      traits: [["TFT13_Warband", "TFT13_Infused"]],
      sets: [130],
      code: "",
    },
    TFT13_Rumble: {
      tier: 5,
      traits: [["TFT13_JunkerKing", "TFT13_Scrap", "TFT13_Titan"]],
      sets: [130],
      code: "",
    },
    TFT13_Lieutenant: {
      tier: 5,
      traits: [["TFT13_HighRoller", "TFT13_Crime", "TFT13_Pugilist"]],
      sets: [130],
      code: "",
    },
    TFT13_MissMage: {
      tier: 6,
      traits: [["TFT13_MissMageTrait"]],
      sets: [130],
      code: "",
    },
    TFT13_Viktor: {
      tier: 6,
      traits: [["TFT13_MachineHerald"]],
      sets: [130],
      code: "",
    },
    TFT13_Warwick: {
      tier: 6,
      traits: [["TFT13_Experiment", "TFT13_BloodHunter"]],
      sets: [130],
      code: "",
    },
    TFT4_Brand: {
      tier: 1,
      traits: [["TFT4_Dragonsoul", "TFT4_Mage"]],
      sets: [46],
      code: "",
    },
    TFT4b_Diana: {
      tier: 1,
      traits: [["TFT4_Spirit", "TFT4_Assassin"]],
      sets: [46],
      code: "",
    },
    TFT4_Elise: {
      tier: 1,
      traits: [["TFT4_Cultist", "TFT4_Keeper"]],
      sets: [46],
      code: "",
    },
    TFT4_Fiora: {
      tier: 1,
      traits: [["TFT4_Enlightened", "TFT4_Duelist"]],
      sets: [46],
      code: "",
    },
    TFT4_Garen: {
      tier: 1,
      traits: [["TFT4_Warlord", "TFT4_Vanguard"]],
      sets: [46],
      code: "",
    },
    TFT4_Maokai: {
      tier: 1,
      traits: [["TFT4_Elderwood", "TFT4_Brawler"]],
      sets: [46],
      code: "",
    },
    TFT4_Nasus: {
      tier: 1,
      traits: [["TFT4_Divine", "TFT4_Syphoner"]],
      sets: [46],
      code: "",
    },
    TFT4_Nidalee: {
      tier: 1,
      traits: [["TFT4_Warlord", "TFT4_Sharpshooter"]],
      sets: [46],
      code: "",
    },
    TFT4_TahmKench: {
      tier: 1,
      traits: [["TFT4_Fortune", "TFT4_Brawler"]],
      sets: [46],
      code: "",
    },
    TFT4_Tristana: {
      tier: 1,
      traits: [["TFT4_Dragonsoul", "TFT4_Sharpshooter"]],
      sets: [46],
      code: "",
    },
    TFT4_TwistedFate: {
      tier: 1,
      traits: [["TFT4_Cultist", "TFT4_Mage"]],
      sets: [46],
      code: "",
    },
    TFT4_Wukong: {
      tier: 1,
      traits: [["TFT4_Divine", "TFT4_Vanguard"]],
      sets: [46],
      code: "",
    },
    TFT4_Yasuo: {
      tier: 1,
      traits: [["TFT4_Exile", "TFT4_Duelist"]],
      sets: [46],
      code: "",
    },
    TFT4_Annie: {
      tier: 2,
      traits: [["TFT4_Fortune", "TFT4_Mage"]],
      sets: [46],
      code: "",
    },
    TFT4_Braum: {
      tier: 2,
      traits: [["TFT4_Dragonsoul", "TFT4_Vanguard"]],
      sets: [46],
      code: "",
    },
    TFT4_Janna: {
      tier: 2,
      traits: [["TFT4_Enlightened", "TFT4_Mystic"]],
      sets: [46],
      code: "",
    },
    TFT4_JarvanIV: {
      tier: 2,
      traits: [["TFT4_Warlord", "TFT4_Keeper"]],
      sets: [46],
      code: "",
    },
    TFT4_Jax: {
      tier: 2,
      traits: [["TFT4_Divine", "TFT4_Duelist"]],
      sets: [46],
      code: "",
    },
    TFT4_Lulu: {
      tier: 2,
      traits: [["TFT4_Elderwood", "TFT4_Mage"]],
      sets: [46],
      code: "",
    },
    TFT4_Nautilus: {
      tier: 2,
      traits: [["TFT4_Fabled", "TFT4_Vanguard"]],
      sets: [46],
      code: "",
    },
    TFT4_Pyke: {
      tier: 2,
      traits: [["TFT4_Cultist", "TFT4_Assassin", "TFT4_Slayer"]],
      sets: [46],
      code: "",
    },
    TFT4_Rakan: {
      tier: 2,
      traits: [["TFT4_Elderwood", "TFT4_Keeper"]],
      sets: [46],
      code: "",
    },
    TFT4_Teemo: {
      tier: 2,
      traits: [["TFT4_Spirit", "TFT4_Sharpshooter"]],
      sets: [46],
      code: "",
    },
    TFT4_Vi: {
      tier: 2,
      traits: [["TFT4_Warlord", "TFT4_Brawler"]],
      sets: [46],
      code: "",
    },
    TFT4_Vladimir: {
      tier: 2,
      traits: [["TFT4_Cultist", "TFT4_Syphoner"]],
      sets: [46],
      code: "",
    },
    TFT4b_Zed: {
      tier: 2,
      traits: [["TFT4_Ninja", "TFT4_Slayer"]],
      sets: [46],
      code: "",
    },
    TFT4_Akali: {
      tier: 3,
      traits: [["TFT4_Ninja", "TFT4_Assassin"]],
      sets: [46],
      code: "",
    },
    TFT4_Darius: {
      tier: 3,
      traits: [["TFT4_Fortune", "TFT4_Slayer"]],
      sets: [46],
      code: "",
    },
    TFT4_Irelia: {
      tier: 3,
      traits: [["TFT4_Divine", "TFT4_Enlightened", "TFT4_Adept"]],
      sets: [46],
      code: "",
    },
    TFT4_Kalista: {
      tier: 3,
      traits: [["TFT4_Cultist", "TFT4_Duelist"]],
      sets: [46],
      code: "",
    },
    TFT4_Katarina: {
      tier: 3,
      traits: [["TFT4_Fortune", "TFT4_Warlord", "TFT4_Assassin"]],
      sets: [46],
      code: "",
    },
    TFT4_Kennen: {
      tier: 3,
      traits: [["TFT4_Ninja", "TFT4_Keeper"]],
      sets: [46],
      code: "",
    },
    TFT4b_Kindred: {
      tier: 3,
      traits: [["TFT4_Spirit", "TFT4_Executioner"]],
      sets: [46],
      code: "",
    },
    TFT4_Neeko: {
      tier: 3,
      traits: [["TFT4_Fabled", "TFT4_Mystic"]],
      sets: [46],
      code: "",
    },
    TFT4_Nunu: {
      tier: 3,
      traits: [["TFT4_Elderwood", "TFT4_Brawler"]],
      sets: [46],
      code: "",
    },
    TFT4_Shyvana: {
      tier: 3,
      traits: [["TFT4_Dragonsoul", "TFT4_Brawler"]],
      sets: [46],
      code: "",
    },
    TFT4_Sivir: {
      tier: 3,
      traits: [["TFT4_Cultist", "TFT4_Sharpshooter"]],
      sets: [46],
      code: "",
    },
    TFT4_Veigar: {
      tier: 3,
      traits: [["TFT4_Elderwood", "TFT4_Mage"]],
      sets: [46],
      code: "",
    },
    TFT4_Yuumi: {
      tier: 3,
      traits: [["TFT4_Spirit", "TFT4_Mystic"]],
      sets: [46],
      code: "",
    },
    TFT4_Aatrox: {
      tier: 4,
      traits: [["TFT4_Cultist", "TFT4_Vanguard"]],
      sets: [46],
      code: "",
    },
    TFT4_AurelionSol: {
      tier: 4,
      traits: [["TFT4_Dragonsoul", "TFT4_Mage"]],
      sets: [46],
      code: "",
    },
    TFT4_ChoGath: {
      tier: 4,
      traits: [["TFT4_Fabled", "TFT4_Brawler"]],
      sets: [46],
      code: "",
    },
    TFT4_Kayle: {
      tier: 4,
      traits: [["TFT4_Divine", "TFT4_Executioner"]],
      sets: [46],
      code: "",
    },
    TFT4b_Morgana: {
      tier: 4,
      traits: [["TFT4_Enlightened", "TFT4_Syphoner"]],
      sets: [46],
      code: "",
    },
    TFT4_Olaf: {
      tier: 4,
      traits: [["TFT4_Dragonsoul", "TFT4_Slayer"]],
      sets: [46],
      code: "",
    },
    TFT4_Sejuani: {
      tier: 4,
      traits: [["TFT4_Fortune", "TFT4_Vanguard"]],
      sets: [46],
      code: "",
    },
    TFT4_Shen: {
      tier: 4,
      traits: [["TFT4_Ninja", "TFT4_Adept", "TFT4_Mystic"]],
      sets: [46],
      code: "",
    },
    TFT4_Talon: {
      tier: 4,
      traits: [["TFT4_Enlightened", "TFT4_Assassin"]],
      sets: [46],
      code: "",
    },
    TFT4_Tryndamere: {
      tier: 4,
      traits: [["TFT4_Warlord", "TFT4_Duelist", "TFT4_Slayer"]],
      sets: [46],
      code: "",
    },
    TFT4_Xayah: {
      tier: 4,
      traits: [["TFT4_Elderwood", "TFT4_Keeper", "TFT4_Executioner"]],
      sets: [46],
      code: "",
    },
    TFT4_Azir: {
      tier: 5,
      traits: [["TFT4_Warlord", "TFT4_Keeper", "TFT4_Emperor"]],
      sets: [46],
      code: "",
    },
    TFT4_LeeSin: {
      tier: 5,
      traits: [["TFT4_Divine", "TFT4_Duelist"]],
      sets: [46],
      code: "",
    },
    TFT4_Ornn: {
      tier: 5,
      traits: [["TFT4_Elderwood", "TFT4_Blacksmith", "TFT4_Vanguard"]],
      sets: [46],
      code: "",
    },
    TFT4_Samira: {
      tier: 5,
      traits: [["TFT4_Daredevil", "TFT4_Sharpshooter", "TFT4_Slayer"]],
      sets: [46],
      code: "",
    },
    TFT4_Sett: {
      tier: 5,
      traits: [["TFT4_Boss", "TFT4_Brawler"]],
      sets: [46],
      code: "",
    },
    TFT4_Swain: {
      tier: 5,
      traits: [["TFT4_Dragonsoul", "TFT4_Syphoner"]],
      sets: [46],
      code: "",
    },
    TFT4_Yone: {
      tier: 5,
      traits: [["TFT4_Exile", "TFT4_Adept"]],
      sets: [46],
      code: "",
    },
    TFT4_Zilean: {
      tier: 5,
      traits: [["TFT4_Cultist", "TFT4_Mystic"]],
      sets: [46],
      code: "",
    },
  },
  traits: {
    Sniper: [
      {
        sets: [36],
        tiers: [2, 4],
        styles: [1, 3],
        type: "class",
      },
    ],
    Set3_Celestial: [
      {
        sets: [36],
        tiers: [2, 4, 6],
        styles: [1, 3, 4],
        type: "origin",
      },
    ],
    Set3_Mystic: [
      {
        sets: [36],
        tiers: [2, 4],
        styles: [1, 3],
        type: "class",
      },
    ],
    Rebel: [
      {
        sets: [36],
        tiers: [3, 6, 9],
        styles: [1, 3, 4],
        type: "origin",
      },
    ],
    SpacePirate: [
      {
        sets: [36],
        tiers: [2, 4],
        styles: [1, 3],
        type: "origin",
      },
    ],
    DarkStar: [
      {
        sets: [36],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Battlecast: [
      {
        sets: [36],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Starship: [
      {
        sets: [36],
        tiers: [1],
        styles: [3],
        type: "class",
      },
    ],
    Astro: [
      {
        sets: [36],
        tiers: [3],
        styles: [3],
        type: "origin",
      },
    ],
    Set3_Blademaster: [
      {
        sets: [36],
        tiers: [3, 6, 9],
        styles: [1, 3, 4],
        type: "class",
      },
    ],
    Chrono: [
      {
        sets: [36],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    Vanguard: [
      {
        sets: [36],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    Protector: [
      {
        sets: [36],
        tiers: [2, 4, 6],
        styles: [1, 3, 3],
        type: "class",
      },
    ],
    StarGuardian: [
      {
        sets: [36],
        tiers: [3, 6, 9],
        styles: [1, 3, 4],
        type: "origin",
      },
    ],
    Set3_Brawler: [
      {
        sets: [36],
        tiers: [2, 4],
        styles: [1, 3],
        type: "class",
      },
    ],
    Cybernetic: [
      {
        sets: [36],
        tiers: [3, 6],
        styles: [1, 3],
        type: "origin",
      },
    ],
    Set3_Sorcerer: [
      {
        sets: [36],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    Mercenary: [
      {
        sets: [36],
        tiers: [1],
        styles: [3],
        type: "class",
      },
    ],
    MechPilot: [
      {
        sets: [36],
        tiers: [3],
        styles: [3],
        type: "origin",
      },
    ],
    ManaReaver: [
      {
        sets: [36],
        tiers: [2],
        styles: [3],
        type: "class",
      },
    ],
    Blaster: [
      {
        sets: [36],
        tiers: [2, 4],
        styles: [1, 3],
        type: "class",
      },
    ],
    Demolitionist: [
      {
        sets: [36],
        tiers: [2],
        styles: [3],
        type: "class",
      },
    ],
    Paragon: [
      {
        sets: [36],
        tiers: [1],
        styles: [3],
        type: "class",
      },
    ],
    Infiltrator: [
      {
        sets: [36],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT5_Abomination: [
      {
        sets: [56],
        tiers: [3, 4, 5],
        styles: [1, 3, 3],
        type: "origin",
      },
    ],
    TFT5_Assassin: [
      {
        sets: [56],
        tiers: [2, 4, 6],
        styles: [1, 3, 3],
        type: "class",
      },
    ],
    TFT5_Brawler: [
      {
        sets: [56],
        tiers: [2, 4, 6],
        styles: [1, 3, 3],
        type: "class",
      },
    ],
    TFT5_Cannoneer: [
      {
        sets: [56],
        tiers: [2, 4, 6],
        styles: [1, 3, 3],
        type: "class",
      },
    ],
    TFT5_Caretaker: [
      {
        sets: [56],
        tiers: [1],
        styles: [3],
        type: "class",
        singleUnit: true,
      },
    ],
    TFT5_Cavalier: [
      {
        sets: [56],
        tiers: [2, 3, 4],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT5_Cruel: [
      {
        sets: [56],
        tiers: [1],
        styles: [3],
        type: "class",
        singleUnit: true,
      },
    ],
    TFT5_Dawnbringer: [
      {
        sets: [56],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT5_Draconic: [
      {
        sets: [56],
        tiers: [3, 5],
        styles: [1, 3],
        type: "origin",
      },
    ],
    TFT5_Forgotten: [
      {
        sets: [56],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT5_Hellion: [
      {
        sets: [56],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT5_Inanimate: [
      {
        sets: [56],
        tiers: [1],
        styles: [3],
        type: "origin",
        singleUnit: true,
      },
    ],
    TFT5_Invoker: [
      {
        sets: [56],
        tiers: [2, 4],
        styles: [1, 3],
        type: "class",
      },
    ],
    TFT5_Ironclad: [
      {
        sets: [56],
        tiers: [2, 3, 4],
        styles: [1, 3, 3],
        type: "origin",
      },
    ],
    TFT5_Knight: [
      {
        sets: [56],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT5_Legionnaire: [
      {
        sets: [56],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT5_Mystic: [
      {
        sets: [56],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT5_Nightbringer: [
      {
        sets: [56],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT5_Ranger: [
      {
        sets: [56],
        tiers: [2, 4, 6],
        styles: [1, 3, 3],
        type: "class",
      },
    ],
    TFT5_Redeemed: [
      {
        sets: [56],
        tiers: [3, 6, 8, 10],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    TFT5_Renewer: [
      {
        sets: [56],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT5_Revenant: [
      {
        sets: [56],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT5_Sentinel: [
      {
        sets: [56],
        tiers: [3, 6, 8, 10],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    TFT5_Skirmisher: [
      {
        sets: [56],
        tiers: [3, 6, 8, 10],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    TFT5_Spellweaver: [
      {
        sets: [56],
        tiers: [2, 4, 6],
        styles: [1, 3, 3],
        type: "origin",
      },
    ],
    TFT5_Victorious: [
      {
        sets: [56],
        tiers: [1],
        styles: [3],
        type: "origin",
        singleUnit: true,
      },
    ],
    Set6_Academy: [
      {
        sets: [60],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set6_Arcanist: [
      {
        sets: [60, 65],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set6_Assassin: [
      { sets: [60, 65], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set6_Bodyguard: [
      {
        sets: [60, 65],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set6_Bruiser: [
      {
        sets: [60, 65],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set6_Challenger: [
      {
        sets: [60, 65],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set6_Chemtech: [
      {
        sets: [60, 65],
        tiers: [3, 5, 7, 9],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set6_Clockwork: [
      { sets: [60, 65], tiers: [2, 4, 6], styles: [1, 3, 4], type: "origin" },
    ],
    Set6_Colossus: [
      { sets: [60], tiers: [2], styles: [3], type: "class" },
      { sets: [65], tiers: [2, 3], styles: [2, 3], type: "class" },
    ],
    Set6_Cuddly: [{ sets: [60], tiers: [1], styles: [3], type: "class" }],
    Set6_Enchanter: [
      {
        sets: [60, 65],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set6_Enforcer: [
      { sets: [60], tiers: [2, 4], styles: [1, 3], type: "origin" },
      { sets: [65], tiers: [3, 5], styles: [3, 4], type: "origin" },
    ],
    Set6_Glutton: [{ sets: [60, 65], tiers: [1], styles: [3], type: "class" }],
    Set6_Imperial: [
      { sets: [60], tiers: [3, 5], styles: [3, 4], type: "origin" },
    ],
    Set6_Innovator: [
      { sets: [60, 65], tiers: [3, 5, 7], styles: [1, 3, 4], type: "origin" },
    ],
    Set6_Mercenary: [
      { sets: [60, 65], tiers: [3, 5, 7], styles: [1, 3, 4], type: "origin" },
    ],
    Set6_Mutant: [
      { sets: [60], tiers: [3, 5], styles: [1, 3], type: "origin" },
      { sets: [65], tiers: [3, 5, 7], styles: [1, 3, 4], type: "origin" },
    ],
    Set6_Protector: [
      { sets: [60], tiers: [2, 3, 4, 5], styles: [1, 2, 3, 4], type: "class" },
    ],
    Set6_Scholar: [
      { sets: [60, 65], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
    ],
    Set6_Scrap: [
      { sets: [60, 65], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set6_Sister: [{ sets: [60], tiers: [2], styles: [3], type: "class" }],
    Set6_Sniper: [
      { sets: [60, 65], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
    ],
    Set6_Socialite: [
      { sets: [60], tiers: [1, 2, 3], styles: [1, 2, 3], type: "class" },
      { sets: [65], tiers: [1, 2, 3, 5], styles: [1, 2, 3, 4], type: "class" },
    ],
    Set6_Syndicate: [
      { sets: [60, 65], tiers: [3, 5, 7], styles: [1, 3, 4], type: "origin" },
    ],
    Set6_Transformer: [
      { sets: [60, 65], tiers: [1], styles: [3], type: "class" },
    ],
    Set6_Twinshot: [
      { sets: [60], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
      { sets: [65], tiers: [2, 3, 4, 5], styles: [1, 2, 3, 4], type: "class" },
    ],
    Set6_Yordle: [
      { sets: [60, 65], tiers: [3, 6], styles: [1, 3], type: "origin" },
    ],
    Set6_YordleLord: [
      { sets: [60, 65], tiers: [1], styles: [4], type: "origin" },
    ],
    Set6_Debonair: [
      { sets: [65], tiers: [3, 5, 7], styles: [1, 3, 4], type: "origin" },
    ],
    Set6_Hextech: [
      { sets: [65], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 4], type: "origin" },
    ],
    Set6_Mastermind: [{ sets: [65], tiers: [1], styles: [3], type: "origin" }],
    Set6_Rivals: [
      { sets: [65], tiers: [1], styles: [3], exclusive: true, type: "origin" },
    ],
    Set6_Striker: [
      { sets: [65], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
    ],

    Set7_Astral: [
      { sets: [70], tiers: [3, 6, 9], styles: [1, 2, 3], type: "origin" },
      { sets: [75], tiers: [3, 5, 8], styles: [1, 2, 3], type: "origin" },
    ],
    Set7_Bard: [{ sets: [70, 75], tiers: [1], styles: [3], type: "origin" }],
    Set7_Dragon: [
      { sets: [70], tiers: [1, 2], styles: [3, 4], type: "class" },
      {
        sets: [75],
        tiers: [1, 2, 3, 4, 5, 6],
        styles: [1, 2, 2, 3, 4, 4],
        type: "class",
      },
    ],
    Set7_Guild: [
      {
        sets: [70],
        tiers: [1, 2, 3, 4, 5, 6, 7],
        styles: [1, 1, 2, 2, 3, 4, 4],
        type: "origin",
      },
      {
        sets: [75],
        tiers: [1, 2, 3, 4, 5, 6, 7, 8],
        styles: [1, 1, 2, 2, 2, 2, 3, 4],
        type: "origin",
      },
    ],
    Set7_Jade: [
      {
        sets: [70],
        tiers: [3, 6, 9, 12],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
      { sets: [75], tiers: [3, 5, 7, 9], styles: [1, 2, 3, 4], type: "origin" },
    ],
    Set7_Legend: [{ sets: [70], tiers: [3], styles: [3], type: "class" }],
    Set7_Mirage: [
      {
        sets: [70, 75],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set7_Ragewing: [
      { sets: [70], tiers: [3, 6, 9], styles: [1, 2, 3], type: "origin" },
      { sets: [75], tiers: [2, 4, 6, 8], styles: [1, 2, 2, 3], type: "origin" },
    ],
    Set7_Revel: [
      { sets: [70], tiers: [2, 3, 4, 5], styles: [1, 2, 3, 4], type: "origin" },
    ],
    Set7_Scalescorn: [
      { sets: [70, 75], tiers: [2, 4, 6], styles: [1, 3, 4], type: "origin" },
    ],
    Set7_Shimmerscale: [
      {
        sets: [70, 75],
        tiers: [3, 5, 7, 9],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set7_Tempest: [
      {
        sets: [70, 75],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set7_Trainer: [
      { sets: [70], tiers: [2, 3], styles: [1, 3], type: "origin" },
    ],
    Set7_Whispers: [
      {
        sets: [70, 75],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set7_Assassin: [
      { sets: [70, 75], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
    ],
    Set7_Bruiser: [
      { sets: [70], tiers: [2, 4, 6, 8], styles: [1, 2, 2, 3], type: "class" },
      { sets: [75], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 4], type: "class" },
    ],
    Set7_Cannoneer: [
      { sets: [70], tiers: [2, 3, 4, 5], styles: [1, 2, 3, 4], type: "class" },
      { sets: [75], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
    ],
    Set7_Cavalier: [
      { sets: [70], tiers: [2, 3, 4, 5], styles: [1, 2, 3, 4], type: "class" },
      {
        sets: [75],
        tiers: [2, 3, 4, 5, 6],
        styles: [1, 2, 2, 3, 4],
        type: "class",
      },
    ],
    Set7_Dragonmancer: [
      { sets: [70], tiers: [3, 6, 9], styles: [1, 3, 4], type: "class" },
      { sets: [75], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 4], type: "class" },
    ],
    Set7_Evoker: [
      { sets: [70], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
      { sets: [75], tiers: [2, 3, 4], styles: [1, 2, 3], type: "class" },
    ],
    Set7_Guardian: [
      { sets: [70], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
      { sets: [75], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 4], type: "class" },
    ],
    Set7_Mage: [
      {
        sets: [70, 75],
        tiers: [3, 5, 7, 9],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set7_Mystic: [
      {
        sets: [70, 75],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set7_Shapeshifter: [
      { sets: [70], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
      { sets: [75], tiers: [2, 4], styles: [1, 3], type: "class" },
    ],
    Set7_SpellThief: [
      { sets: [70, 75], tiers: [1], styles: [3], type: "class" },
    ],
    Set7_Starcaller: [
      { sets: [70, 75], tiers: [1], styles: [3], type: "class" },
    ],
    Set7_Swiftshot: [
      { sets: [70], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
      { sets: [75], tiers: [2, 3, 4, 5], styles: [1, 2, 3, 4], type: "class" },
    ],
    Set7_Warrior: [
      { sets: [70, 75], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
    ],
    Set7_Darkflight: [
      { sets: [75], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 4], type: "origin" },
    ],
    Set7_Lagoon: [
      {
        sets: [75],
        tiers: [3, 6, 9, 12],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set7_Monolith: [
      { sets: [75], tiers: [3], styles: [3], singleUnit: true, type: "origin" },
    ],
    Set7_Prodigy: [
      { sets: [75], tiers: [3], styles: [3], singleUnit: true, type: "origin" },
    ],
    Set8_Ace: [
      {
        sets: [80, 85],
        tiers: [1, 4],
        styles: [1, 3],
        type: "class",
        exclusive: true,
      },
    ],
    Set8_Admin: [
      { sets: [80, 85], tiers: [2, 4, 6], styles: [1, 3, 4], type: "origin" },
    ],
    Set8_Aegis: [
      {
        sets: [80, 85],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set8_AnimaSquad: [
      { sets: [80, 85], tiers: [3, 5, 7], styles: [1, 3, 4], type: "origin" },
    ],
    Set8_Arsenal: [
      { sets: [80], tiers: [1], styles: [3], type: "class", singleUnit: true },
    ],
    Set8_Brawler: [
      {
        sets: [80, 85],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set8_Civilian: [
      { sets: [80], tiers: [1, 2, 3], styles: [1, 2, 3], type: "origin" },
    ],
    Set8_Corrupted: [
      {
        sets: [80, 85],
        tiers: [1],
        styles: [3],
        type: "origin",
        singleUnit: true,
      },
    ],
    Set8_Defender: [
      { sets: [80, 85], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
    ],
    Set8_Duelist: [
      {
        sets: [80, 85],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set8_Forecaster: [
      {
        sets: [80, 85],
        tiers: [1],
        styles: [3],
        type: "class",
        singleUnit: true,
      },
    ],
    Set8_GenAE: [
      { sets: [80, 85], tiers: [3, 5], styles: [1, 3], type: "origin" },
    ],
    Set8_Hacker: [
      { sets: [80], tiers: [2, 3, 4], styles: [1, 3, 4], type: "class" },
      { sets: [85], tiers: [3, 4, 5, 6], styles: [1, 2, 3, 4], type: "class" },
    ],
    Set8_Heart: [
      { sets: [80, 85], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set8_Pulsefire: [
      { sets: [85], tiers: [3, 5, 7], styles: [1, 3, 4], type: "origin" },
    ],
    Set8_SpaceCorps: [
      { sets: [80], tiers: [3, 5, 7, 9], styles: [1, 2, 3, 4], type: "origin" },
      { sets: [85], tiers: [3, 4, 5, 6], styles: [1, 2, 3, 4], type: "origin" },
    ],
    Set8_Mascot: [
      {
        sets: [80, 85],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set8_ExoPrime: [
      { sets: [80, 85], tiers: [3, 5], styles: [1, 3], type: "origin" },
    ],
    Set8_OxForce: [
      { sets: [80], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 4], type: "origin" },
      { sets: [85], tiers: [2, 4, 6], styles: [1, 3, 4], type: "origin" },
    ],
    Set8_Parallel: [{ sets: [85], tiers: [2], styles: [3], type: "origin" }],
    Set8_Prankster: [
      { sets: [80], tiers: [2, 3], styles: [1, 3], type: "class" },
      { sets: [85], tiers: [2, 3, 4], styles: [1, 3, 4], type: "class" },
    ],
    Set8_GunMage: [
      { sets: [85], tiers: [2, 3, 4], styles: [1, 2, 3], type: "class" },
    ],
    Set8_Recon: [
      { sets: [80], tiers: [2, 3, 4], styles: [1, 2, 3], type: "class" },
    ],
    Set8_Renegade: [
      { sets: [80], tiers: [3, 6], styles: [3, 4], type: "class" },
      { sets: [85], tiers: [3, 5, 7], styles: [1, 2, 3], type: "class" },
    ],
    Set8_Riftwalker: [{ sets: [85], tiers: [3], styles: [3], type: "origin" }],
    Set8_Channeler: [
      {
        sets: [80, 85],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    Set8_StarGuardian: [
      {
        sets: [80],
        tiers: [3, 5, 7, 9],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
      {
        sets: [85],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set8_Supers: [{ sets: [80, 85], tiers: [3], styles: [3], type: "origin" }],
    Set8_Deadeye: [
      { sets: [80], tiers: [2, 4], styles: [1, 3], type: "class" },
      { sets: [85], tiers: [2, 3, 4, 5], styles: [1, 2, 3, 4], type: "class" },
    ],
    Set8_Threat: [{ sets: [80, 85], tiers: [1], styles: [3], type: "origin" }],
    Set8_UndergroundThe: [
      {
        sets: [80, 85],
        tiers: [3, 4, 5, 6],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set9_Bastion: [
      {
        sets: [90],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "class",
      },
      {
        sets: [95],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    Set9_Bruiser: [
      { sets: [90], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
      { sets: [95], tiers: [2, 3, 4, 6], styles: [1, 1, 2, 3], type: "class" },
    ],
    Set9b_Bilgewater: [
      { sets: [95], tiers: [3, 5, 7, 9], styles: [1, 2, 3, 4], type: "origin" },
    ],
    Set9_Challenger: [
      {
        sets: [90],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "class",
      },
      {
        sets: [95],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    Set9_Darkin: [
      {
        sets: [90],
        tiers: [1],
        styles: [3],
        type: "origin",
        singleUnit: true,
      },
    ],
    Set9b_Darkin: [
      {
        sets: [95],
        tiers: [1, 2],
        styles: [1, 3],
        type: "origin",
      },
    ],
    Set9_Deadeye: [
      { sets: [90], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
    ],
    Set9_Demacia: [
      {
        sets: [90, 95],
        tiers: [3, 5, 7, 9],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set9_Empress: [
      {
        sets: [90, 95],
        tiers: [1],
        styles: [3],
        type: "class",
        singleUnit: true,
      },
    ],
    Set9_Freljord: [
      { sets: [90], tiers: [2, 3, 4], styles: [1, 3, 4], type: "origin" },
      { sets: [95], tiers: [2, 3], styles: [1, 3], type: "origin" },
    ],
    Set9_Marksman: [
      { sets: [90], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
      { sets: [95], tiers: [2, 4, 6], styles: [1, 3, 3], type: "class" },
    ],
    Set9_Ionia: [
      { sets: [90, 95], tiers: [3, 6, 9], styles: [1, 3, 4], type: "origin" },
    ],
    Set9_Ixtal: [
      { sets: [95], tiers: [2, 3, 4], styles: [1, 3, 3], type: "origin" },
    ],
    Set9_Multicaster: [
      { sets: [90], tiers: [2, 4], styles: [1, 3], type: "class" },
      { sets: [95], tiers: [2, 3, 4], styles: [1, 2, 3], type: "class" },
    ],
    Set9_Noxus: [
      { sets: [90], tiers: [3, 6, 9], styles: [1, 3, 4], type: "origin" },
      {
        sets: [90, 95],
        tiers: [3, 5, 7, 9],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set9_Preserver: [
      { sets: [90], tiers: [2, 4, 6], styles: [1, 3, 4], type: "class" },
      { sets: [95], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 3], type: "class" },
    ],
    Set9_Armorclad: [
      { sets: [90, 95], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set9_Piltover: [
      { sets: [90, 95], tiers: [3, 6], styles: [3, 4], type: "origin" },
    ],
    Set9_ReaverKing: [
      { sets: [95], tiers: [1], styles: [3], type: "class", singleUnit: true },
    ],
    Set9_Rogue: [
      { sets: [90, 95], tiers: [2, 4], styles: [1, 3], type: "class" },
    ],
    Set9_Shurima: [
      {
        sets: [90],
        tiers: [3, 5, 7, 9],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
      {
        sets: [95],
        tiers: [2, 4, 6, 9],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set9_Slayer: [
      {
        sets: [90],
        tiers: [2, 3, 4, 5, 6],
        styles: [1, 2, 2, 3, 4],
        type: "class",
      },

      {
        sets: [95],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    Set9_Sorcerer: [
      {
        sets: [90],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "class",
      },
      {
        sets: [95],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    Set9_Strategist: [
      {
        sets: [90],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 4],
        type: "class",
      },
      {
        sets: [95],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    Set9_Redeemer: [
      { sets: [90], tiers: [1], styles: [3], type: "class", singleUnit: true },
    ],
    Set9_ShadowIsles: [
      { sets: [90], tiers: [2, 4, 6], styles: [1, 3, 4], type: "origin" },
    ],
    Set9_Targon: [
      { sets: [90], tiers: [2, 3, 4], styles: [1, 3, 4], type: "origin" },
      { sets: [95], tiers: [2, 3, 4], styles: [1, 3, 3], type: "origin" },
    ],
    Set9_Technogenius: [
      {
        sets: [90, 95],
        tiers: [1],
        styles: [3],
        type: "class",
        singleUnit: true,
      },
    ],
    Set9b_Vanquisher: [
      { sets: [95], tiers: [2, 4, 6], styles: [1, 3, 3], type: "class" },
    ],
    Set9_Void: [
      { sets: [90], tiers: [3, 6, 8], styles: [1, 3, 4], type: "origin" },
      { sets: [95], tiers: [3, 6, 8], styles: [1, 3, 3], type: "origin" },
    ],
    Set9_Wanderer: [
      {
        sets: [90, 95],
        tiers: [1],
        styles: [3],
        type: "origin",
        singleUnit: true,
      },
    ],
    Set9_BandleCity: [
      { sets: [90], tiers: [3, 5], styles: [1, 3], type: "origin" },
    ],
    Set9_Zaun: [
      { sets: [90], tiers: [2, 4, 6], styles: [1, 3, 4], type: "origin" },
      { sets: [95], tiers: [2, 4, 6], styles: [1, 3, 3], type: "origin" },
    ],
    Set10_8Bit: [
      { sets: [10], tiers: [2, 4, 6], styles: [1, 2, 3], type: "origin" },
    ],
    // Big Shot
    Set10_Deadeye: [
      { sets: [10], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set10_Brawler: [
      { sets: [10], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set10_Breakout: [
      {
        sets: [10],
        tiers: [1],
        styles: [3],
        type: "origin",
        singleUnit: true,
      },
    ],
    Set10_Country: [
      { sets: [10], tiers: [3, 5, 7], styles: [1, 2, 3], type: "origin" },
    ],
    Set10_CrowdDive: [
      { sets: [10], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set10_Dazzler: [
      { sets: [10], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set10_Funk: [
      {
        sets: [10],
        tiers: [3, 4, 5, 6],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    Set10_Edgelord: [
      { sets: [10], tiers: [3, 5, 7], styles: [1, 2, 3], type: "class" },
    ],
    Set10_EDM: [
      {
        sets: [10],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    Set10_Emo: [
      { sets: [10], tiers: [2, 4, 6], styles: [1, 2, 3], type: "origin" },
    ],
    Set10_Executioner: [
      { sets: [10], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set10_Fighter: [
      { sets: [10], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set10_Guardian: [
      { sets: [10], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set10_Jazz: [
      { sets: [10], tiers: [2, 3, 4], styles: [1, 2, 3], type: "origin" },
    ],
    Set10_PopBand: [
      {
        sets: [10],
        tiers: [3, 5, 7, 10],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set10_Hyperpop: [
      { sets: [10], tiers: [1, 2, 3, 4], styles: [1, 2, 3, 3], type: "origin" },
    ],
    Set10_IllBeats: [
      {
        sets: [10],
        tiers: [1],
        styles: [3],
        type: "origin",
        singleUnit: true,
      },
    ],
    Set10_TwoSides: [
      {
        sets: [10],
        tiers: [1],
        styles: [3],
        type: "class",
        singleUnit: true,
      },
    ],
    Set10_KDA: [
      {
        sets: [10],
        tiers: [3, 5, 7, 10],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set10_Classical: [
      {
        sets: [10],
        tiers: [1],
        styles: [3],
        type: "origin",
        singleUnit: true,
      },
    ],
    Set10_DJ: [
      {
        sets: [10],
        tiers: [1],
        styles: [3],
        type: "origin",
        singleUnit: true,
      },
    ],
    Set10_Pentakill: [
      {
        sets: [10],
        tiers: [3, 5, 7, 10],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    Set10_PunkRock: [
      { sets: [10], tiers: [2, 4, 6], styles: [1, 2, 3], type: "origin" },
    ],
    // Rapidfire
    Set10_Quickshot: [
      { sets: [10], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class" },
    ],
    Set10_Sentinel: [
      { sets: [10], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 3], type: "class" },
    ],
    Set10_Superfan: [
      { sets: [10], tiers: [3, 4, 5], styles: [1, 2, 3], type: "class" },
    ],
    Set10_Spellweaver: [
      {
        sets: [10],
        tiers: [3, 5, 7, 9],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    Set10_TrueDamage: [
      {
        sets: [10],
        tiers: [2, 4, 6, 9],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    TFT11_Dragonlord: [
      {
        sets: [110],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT11_Dryad: [
      {
        sets: [110],
        tiers: [2, 4, 6],
        styles: [1, 3, 3],
        type: "origin",
      },
    ],
    TFT11_Fated: [
      {
        sets: [110],
        tiers: [3, 5, 7, 10],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    TFT11_Fortune: [
      {
        sets: [110],
        tiers: [3, 5, 7],
        styles: [1, 2, 3],
        type: "origin",
      },
    ],
    TFT11_Ghostly: [
      {
        sets: [110],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT11_Heavenly: [
      {
        sets: [110],
        tiers: [2, 3, 4, 5, 6, 7],
        styles: [1, 1, 2, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT11_InkShadow: [
      {
        sets: [110],
        tiers: [3, 5, 7],
        styles: [1, 2, 3],
        type: "origin",
      },
    ],
    TFT11_Mythic: [
      {
        sets: [110],
        tiers: [3, 5, 7, 10],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    TFT11_Porcelain: [
      {
        sets: [110],
        tiers: [2, 4, 6],
        styles: [1, 3, 3],
        type: "origin",
      },
    ],
    TFT11_Sniper: [
      {
        sets: [110],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT11_Storyweaver: [
      {
        sets: [110],
        tiers: [3, 5, 7, 10],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    TFT11_Umbral: [
      {
        sets: [110],
        tiers: [2, 4, 6, 9],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    TFT11_Altruist: [
      {
        sets: [110],
        tiers: [2, 3, 4],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT11_Arcanist: [
      {
        sets: [110],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT11_Artist: [
      {
        sets: [110],
        tiers: [1],
        styles: [3],
        type: "class",
      },
    ],
    TFT11_Behemoth: [
      {
        sets: [110],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT11_Bruiser: [
      {
        sets: [110],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT11_Duelist: [
      {
        sets: [110],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT11_Exalted: [
      {
        sets: [110],
        tiers: [3, 5],
        styles: [2, 3],
        type: "class",
      },
    ],
    TFT11_Great: [
      {
        sets: [110],
        tiers: [1],
        styles: [3],
        type: "class",
      },
    ],
    TFT11_Invoker: [
      {
        sets: [110],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT11_Lovers: [
      {
        sets: [110],
        tiers: [1],
        styles: [3],
        type: "class",
      },
    ],
    TFT11_Reaper: [
      {
        sets: [110],
        tiers: [2, 4],
        styles: [1, 3],
        type: "class",
      },
    ],
    TFT11_Sage: [
      {
        sets: [110],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT11_SpiritWalker: [
      {
        sets: [110],
        tiers: [1],
        styles: [3],
        type: "class",
      },
    ],
    TFT11_Trickshot: [
      {
        sets: [110],
        tiers: [2, 4],
        styles: [1, 3],
        type: "class",
      },
    ],
    TFT11_Warden: [
      {
        sets: [110],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT12_Arcana: [
      {
        sets: [120],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT12_Chrono: [
      {
        sets: [120],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "origin",
      },
    ],
    TFT12_Dragon: [
      {
        sets: [120],
        tiers: [2, 3],
        styles: [1, 3],
        type: "origin",
      },
    ],
    TFT12_Druid: [
      {
        sets: [120],
        tiers: [1],
        styles: [3],
        type: "origin",
      },
    ],
    TFT12_Eldritch: [
      {
        sets: [120],
        tiers: [3, 5, 7, 10],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    TFT12_Faerie: [
      {
        sets: [120],
        tiers: [3, 5, 7, 9],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT12_Frost: [
      {
        sets: [120],
        tiers: [3, 5, 7, 9],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT12_Honeymancy: [
      {
        sets: [120],
        tiers: [3, 5, 7],
        styles: [1, 3, 3],
        type: "origin",
      },
    ],
    TFT12_Portal: [
      {
        sets: [120],
        tiers: [3, 6, 8, 10],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    TFT12_Pyro: [
      {
        sets: [120],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT12_Ravenous: [
      {
        sets: [120],
        tiers: [1],
        styles: [3],
        type: "origin",
      },
    ],
    TFT12_Sugarcraft: [
      {
        sets: [120],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    TFT12_Witchcraft: [
      {
        sets: [120],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT12_Ascendant: [
      {
        sets: [120],
        tiers: [1],
        styles: [3],
        type: "class",
      },
    ],
    TFT12_Bastion: [
      {
        sets: [120],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT12_BatQueen: [
      {
        sets: [120],
        tiers: [1],
        styles: [3],
        type: "class",
      },
    ],
    // Best Friends
    TFT12_Explorer: [
      {
        sets: [120],
        tiers: [1],
        styles: [3],
        type: "class",
      },
    ],
    TFT12_Blaster: [
      {
        sets: [120],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT12_Hunter: [
      {
        sets: [120],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT12_Incantor: [
      {
        sets: [120],
        tiers: [2, 4],
        styles: [1, 3],
        type: "class",
      },
    ],
    TFT12_Mage: [
      {
        sets: [120],
        tiers: [3, 5, 7, 10],
        styles: [1, 2, 3, 4],
        type: "class",
      },
    ],
    TFT12_Multistriker: [
      {
        sets: [120],
        tiers: [3, 5, 7, 9],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT12_Preserver: [
      {
        sets: [120],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT12_Scholar: [
      {
        sets: [120],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT12_Shapeshifter: [
      {
        sets: [120],
        tiers: [2, 4, 6, 8, 10],
        styles: [1, 2, 3, 3, 4],
        type: "class",
      },
    ],
    TFT12_Vanguard: [
      {
        sets: [120],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT12_Warrior: [
      {
        sets: [120],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT13_Academy: [
      {
        sets: [130],
        tiers: [3, 4, 5, 6],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT13_Hextech: [
      {
        sets: [130],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "origin",
      },
    ],
    TFT13_MissMageTrait: [
      { sets: [130], tiers: [1], styles: [3], type: "origin" },
    ],
    TFT13_BloodHunter: [
      { sets: [130], tiers: [1], styles: [3], type: "origin" },
    ],
    TFT13_Cabal: [
      {
        sets: [130],
        tiers: [3, 4, 5, 7],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT13_Crime: [
      {
        sets: [130],
        tiers: [3, 4, 5, 6, 7],
        styles: [1, 2, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT13_Warband: [
      {
        sets: [130],
        tiers: [2, 4, 6, 9],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT13_Ambassador: [
      {
        sets: [130],
        tiers: [1, 4],
        styles: [1, 3],
        type: "origin",
      },
    ],
    TFT13_Squad: [
      {
        sets: [130],
        tiers: [2, 4, 6, 8, 10],
        styles: [1, 2, 3, 3, 4],
        type: "origin",
      },
    ],
    TFT13_Experiment: [
      {
        sets: [130],
        tiers: [3, 5, 7],
        styles: [1, 2, 3],
        type: "origin",
      },
    ],
    TFT13_Family: [
      {
        sets: [130],
        tiers: [3, 4, 5],
        styles: [1, 2, 3],
        type: "origin",
      },
    ],
    TFT13_Hoverboard: [
      {
        sets: [130],
        tiers: [2, 3, 4],
        styles: [1, 2, 3],
        type: "origin",
      },
    ],
    TFT13_HighRoller: [
      {
        sets: [130],
        tiers: [1],
        styles: [3],
        type: "origin",
      },
    ],
    TFT13_JunkerKing: [
      {
        sets: [130],
        tiers: [1],
        styles: [3],
        type: "origin",
      },
    ],
    TFT13_MachineHerald: [
      { sets: [130], tiers: [1], styles: [3], type: "origin" },
    ],
    TFT13_Rebel: [
      {
        sets: [130],
        tiers: [3, 5, 7, 10],
        styles: [1, 2, 3, 4],
        type: "origin",
      },
    ],
    TFT13_Scrap: [
      {
        sets: [130],
        tiers: [2, 4, 6, 9],
        styles: [1, 2, 3, 3],
        type: "origin",
      },
    ],
    TFT13_Ambusher: [
      {
        sets: [130],
        tiers: [2, 3, 4, 5],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT13_Martialist: [
      {
        sets: [130],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT13_Bruiser: [
      {
        sets: [130],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT13_Infused: [
      {
        sets: [130],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT13_FormSwapper: [
      {
        sets: [130],
        tiers: [2, 4],
        styles: [1, 3],
        type: "class",
      },
    ],
    TFT13_Pugilist: [
      {
        sets: [130],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT13_Challenger: [
      {
        sets: [130],
        tiers: [2, 3, 4],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT13_Titan: [
      {
        sets: [130],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT13_Sniper: [
      {
        sets: [130],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT13_Sorcerer: [
      {
        sets: [130],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT13_Invoker: [
      {
        sets: [130],
        tiers: [2, 4, 6, 8],
        styles: [1, 2, 3, 3],
        type: "class",
      },
    ],
    TFT13_Watcher: [
      {
        sets: [130],
        tiers: [2, 4, 6],
        styles: [1, 2, 3],
        type: "class",
      },
    ],
    TFT13_Teamup_Reunion: [
      { sets: [130], tiers: [2], styles: [3], type: "special" },
    ],
    TFT13_Teamup_UnlikelyDuo: [
      { sets: [130], tiers: [2], styles: [3], type: "special" },
    ],
    TFT13_Teamup_Mentorship: [
      { sets: [130], tiers: [2], styles: [3], type: "special" },
    ],
    TFT13_Teamup_Geniuses: [
      { sets: [130], tiers: [2], styles: [3], type: "special" },
    ],
    TFT13_Teamup_Sisters: [
      { sets: [130], tiers: [2], styles: [3], type: "special" },
    ],
    TFT13_Teamup_BloodBrothers: [
      { sets: [130], tiers: [2], styles: [3], type: "special" },
    ],
    TFT13_Teamup_Menaces: [
      { sets: [130], tiers: [2], styles: [3], type: "special" },
    ],
    TFT13_Teamup_Betrayal: [
      { sets: [130], tiers: [2], styles: [3], type: "special" },
    ],
    TFT4_Cultist:  [{sets: [46], tiers: [3, 6, 9, 11], styles: [1, 2, 3, 4], type: "origin"}],
    TFT4_Daredevil:  [{sets: [46], tiers: [1], styles: [3], type: "origin"}],
    TFT4_Divine:  [{sets: [46], tiers: [2, 4, 8, 6], styles: [1, 2, 3, 3], type: "origin"}],
    TFT4_Dragonsoul:  [{sets: [46], tiers: [3, 6, 9], styles: [1, 2, 3], type: "origin"}],
    TFT4_Elderwood:  [{sets: [46], tiers: [3, 6, 9], styles: [1, 2, 3], type: "origin"}],
    TFT4_Enlightened:  [{sets: [46], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 3], type: "origin"}],
    TFT4_Exile:  [{sets: [46], tiers: [1, 2], styles: [1, 3], type: "origin"}],
    TFT4_Fabled:  [{sets: [46], tiers: [3], styles: [3], type: "origin"}],
    TFT4_Fortune:  [{sets: [46], tiers: [3, 6, 10], styles: [1, 3, 4], type: "origin"}],
    TFT4_Ninja:  [{sets: [46], tiers: [1, 4], styles: [1, 3], type: "origin"}],
    TFT4_Spirit:  [{sets: [46], tiers: [2, 4, 6], styles: [1, 2, 3], type: "origin"}],
    TFT4_Boss:  [{sets: [46], tiers: [1], styles: [3], type: "origin"}],
    TFT4_Warlord:  [{sets: [46], tiers: [3, 6, 9, 11], styles: [1, 2, 3, 4], type: "origin"}],
    TFT4_Adept:  [{sets: [46], tiers: [2, 3, 4], styles: [1, 2, 3], type: "class"}],
    TFT4_Assassin:  [{sets: [46], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class"}],
    TFT4_Blacksmith:  [{sets: [46], tiers: [1], styles: [3], type: "class"}],
    TFT4_Brawler:  [{sets: [46], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 3], type: "class"}],
    TFT4_Duelist:  [{sets: [46], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 3], type: "class"}],
    TFT4_Emperor:  [{sets: [46], tiers: [1], styles: [1], type: "class"}],
    TFT4_Executioner:  [{sets: [46], tiers: [2, 3, 4], styles: [1, 2, 3], type: "class"}],
    TFT4_Keeper:  [{sets: [46], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 3], type: "class"}],
    TFT4_Mage:  [{sets: [46], tiers: [3, 5, 7, 9], styles: [1, 2, 3, 3], type: "class"}],
    TFT4_Mystic:  [{sets: [46], tiers: [2, 3, 4, 5], styles: [1, 2, 3, 3], type: "class"}],
    TFT4_Sharpshooter:  [{sets: [46], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class"}],
    TFT4_Slayer:  [{sets: [46], tiers: [3, 6, 9], styles: [1, 2, 3], type: "class"}],
    TFT4_Syphoner:  [{sets: [46], tiers: [2, 4, 6], styles: [1, 2, 3], type: "class"}],
    TFT4_Vanguard:  [{sets: [46], tiers: [2, 4, 6, 8], styles: [1, 2, 3, 3], type: "class"}],
  },
  itemTraits: {
    60: {
      18: "Set6_Imperial",
      28: "Set6_Challenger",
      38: "Set6_Arcanist",
      48: "Set6_Academy",
      58: "Set6_Bodyguard",
      68: "Set6_Syndicate",
      78: "Set6_Chemtech",
      89: "Set6_Assassin",
      2190: "Set6_Mutant",
      2191: "Set6_Clockwork",
      2192: "Set6_Mercenary",
      2193: "Set6_Socialite",
      2194: "Set6_Enforcer",
      2195: "Set6_Scrap",
      2196: "Set6_Protector",
      2197: "Set6_Bruiser",
      2198: "Set6_Innovator",
      2199: "Set6_Sniper",
      2200: "Set6_Scholar",
    },
    65: {
      28: "Set6_Challenger",
      38: "Set6_Arcanist",
      58: "Set6_Bodyguard",
      68: "Set6_Syndicate",
      70: "Set6_Debonair",
      71: "Set6_Striker",
      72: "Set6_Hextech",
      78: "Set6_Chemtech",
      89: "Set6_Assassin",
      2190: "Set6_Mutant",
      2191: "Set6_Clockwork",
      2192: "Set6_Mercenary",
      2193: "Set6_Socialite",
      2194: "Set6_Enforcer",
      2195: "Set6_Scrap",
      2197: "Set6_Bruiser",
      2198: "Set6_Innovator",
      2199: "Set6_Sniper",
      2200: "Set6_Scholar",
    },
    70: {
      2300: "Set7_Scalescorn",
      2301: "Set7_Cannoneer",
      2302: "Set7_Shimmerscale",
      2303: "Set7_Whispers",
      2305: "Set7_Bruiser",
      2306: "Set7_Astral",
      2307: "Set7_Cavalier",
      2308: "Set7_Warrior",
      2310: "Set7_Tempest",
      2311: "Set7_Mage",
      2312: "Set7_Mystic",
      2313: "Set7_Swiftshot",
      2314: "Set7_Mirage",
      2315: "Set7_Ragewing",
      2316: "Set7_Revel",
      2317: "Set7_Evoker",
      2318: "Set7_Guild",
      2323: "Set7_Guardian",
      2324: "Set7_Jade",
      2325: "Set7_Assassin",
      2425: "Set7_Legend",
      2426: "Set7_Dragonmancer",
    },
    75: {
      2100: "Set7_Lagoon",
      2101: "Set7_Darkflight",
      2300: "Set7_Scalescorn",
      2301: "Set7_Cannoneer",
      2302: "Set7_Shimmerscale",
      2303: "Set7_Whispers",
      2305: "Set7_Bruiser",
      2306: "Set7_Astral",
      2307: "Set7_Cavalier",
      2308: "Set7_Warrior",
      2310: "Set7_Tempest",
      2311: "Set7_Mage",
      2312: "Set7_Mystic",
      2313: "Set7_Swiftshot",
      2314: "Set7_Mirage",
      2315: "Set7_Ragewing",
      2316: "Set7_Revel",
      2317: "Set7_Evoker",
      2318: "Set7_Guild",
      2323: "Set7_Guardian",
      2324: "Set7_Jade",
      2325: "Set7_Assassin",
      2425: "Set7_Legend",
      2426: "Set7_Dragonmancer",
    },
    80: {
      8000: "Set8_AnimaSquad",
      8001: "Set8_Civilian",
      8002: "Set8_StarGuardian",
      8003: "Set8_SpaceCorps",
      8004: "Set8_OxForce",
      8005: "Set8_UndergroundThe",
      8006: "Set8_GenAE",
      8007: "Set8_Admin",
      8008: "Set8_Mascot",
      8009: "Set8_Defender",
      8010: "Set8_Recon",
      8011: "Set8_Channeler",
      8012: "Set8_Renegade",
      8013: "Set8_Brawler",
      8014: "Set8_Duelist",
      8015: "Set8_Hacker",
      8016: "Set8_Heart",
      8017: "Set8_Prankster",
      8018: "Set8_Deadeye",
      8020: "Set8_ExoPrime",
      8021: "Set8_Ace",
      8047: "Set8_Aegis",
    },
    85: {
      "8AnimaSquadEmblemItem": "Set8_AnimaSquad",
      "8StarGuardianEmblemItem": "Set8_StarGuardian",
      "8InterPolarisEmblemItem": "Set8_SpaceCorps",
      "8OxForceEmblemItem": "Set8_OxForce",
      "8UndergroundTheEmblemItem": "Set8_UndergroundThe",
      "8GenAEEmblemItem": "Set8_GenAE",
      "8ADMINEmblemItem": "Set8_Admin",
      "8MascotEmblemItem": "Set8_Mascot",
      "8DefenderEmblemItem": "Set8_Defender",
      "8ChannelerEmblemItem": "Set8_Channeler",
      "8RenegadeEmblemItem": "Set8_Renegade",
      "8BrawlerEmblemItem": "Set8_Brawler",
      "8DuelistEmblemItem": "Set8_Duelist",
      "8HackerEmblemItem": "Set8_Hacker",
      "8HeartEmblemItem": "Set8_Heart",
      "8PranksterEmblemItem": "Set8_Prankster",
      "8DeadeyeEmblemItem": "Set8_Deadeye",
      "8ExoPrimeEmblemItem": "Set8_ExoPrime",
      "8AceEmblemItem": "Set8_Ace",
      "8AegisEmblemItem": "Set8_Aegis",
      "8InfiniteamEmblemItem": "Set8_Pulsefire",
      "8RiftwalkerEmblemItem": "Set8_Riftwalker",
    },
    90: {
      "9IoniaEmblem": "Set9_Ionia",
      "9ChallengerEmblem": "Set9_Challenger",
      "9ShurimaEmblem": "Set9_Shurima",
      "9SorcererEmblem": "Set9_Sorcerer",
      "9ArmorcladEmblem": "Set9_Armorclad",
      "9DemaciaEmblem": "Set9_Demacia",
      "9NoxusEmblem": "Set9_Noxus",
      "9SlayerEmblem": "Set9_Slayer",
      "9BastionEmblem": "Set9_Bastion",
      "9BrawlerEmblem": "Set9_Bruiser",
      "9DeadeyeEmblem": "Set9_Deadeye",
      "9FreljordEmblem": "Set9_Freljord",
      "9TrickshotEmblem": "Set9_Marksman",
      "9PreserverEmblem": "Set9_Preserver",
      "9PiltoverEmblem": "Set9_Piltover",
      "9RogueEmblem": "Set9_Rogue",
      "9ShadowIslesEmblem": "Set9_ShadowIsles",
      "9StrategistEmblem": "Set9_Strategist",
      "9TargonEmblem": "Set9_Targon",
      "9VoidEmblem": "Set9_Void",
      "9ZaunEmblem": "Set9_Zaun",
    },
    95: {
      "9IoniaEmblem": "Set9_Ionia",
      "9ChallengerEmblem": "Set9_Challenger",
      "9ShurimaEmblem": "Set9_Shurima",
      "9SorcererEmblem": "Set9_Sorcerer",
      "9ArmorcladEmblem": "Set9_Armorclad",
      "9BilgewaterEmblem": "Set9b_Bilgewater",
      "9NoxusEmblem": "Set9_Noxus",
      "9VanquisherEmblem": "Set9b_Vanquisher",
      "9BastionEmblem": "Set9_Bastion",
      "9BrawlerEmblem": "Set9_Bruiser",
      "9DemaciaEmblem": "Set9_Demacia",
      "9FreljordEmblem": "Set9_Freljord",
      "9PreserverEmblem": "Set9_Preserver",
      "9IxtalEmblem": "Set9_Ixtal",
      "9TrickshotEmblem": "Set9_Marksman",
      "9PiltoverEmblem": "Set9_Piltover",
      "9RogueEmblem": "Set9_Rogue",
      "9SlayerEmblem": "Set9_Slayer",
      "9StrategistEmblem": "Set9_Strategist",
      "9VoidEmblem": "Set9_Void",
      "9ZaunEmblem": "Set9_Zaun",
      "9TargonEmblem": "Set9_Targon",
    },
    10: {
      "10WardenEmblem": "Set10_Sentinel",
      "10TrueDamageEmblem": "Set10_TrueDamage",
      "10SuperfanEmblem": "Set10_Superfan",
      "10SpellweaverEmblem": "Set10_Spellweaver",
      "10RapidfireEmblem": "Set10_Quickshot",
      "10PunkEmblem": "Set10_PunkRock",
      "10PentakillEmblem": "Set10_Pentakill",
      "10PBJEmblem": "Set10_PopBand",
      "10FighterEmblem": "Set10_Fighter",
      "10KDAEmblem": "Set10_KDA",
      "10JazzEmblem": "Set10_Jazz",
      "10GuardianEmblem": "Set10_Guardian",
      "10HyperPopEmblem": "Set10_Hyperpop",
      "10ExecutionerEmblem": "Set10_Executioner",
      "10EdgelordEmblem": "Set10_Edgelord",
      "10DiscoEmblem": "Set10_Funk",
      "10CountryEmblem": "Set10_Country",
      "10DazzlerEmblem": "Set10_Dazzler",
      "10CrowdDiverEmblem": "Set10_CrowdDive",
      "10BrawlerEmblem": "Set10_Brawler",
      "10BigShotEmblem": "Set10_Deadeye",
      "10EmoEmblem": "Set10_Emo",
      "108bitEmblem": "Set10_8Bit",
    },
    36: {
      "3BlademasterEmblem": "Set3_Blademaster",
      "3InfiltratorEmblem": "Infiltrator",
      "3RebelEmblem": "Rebel",
      "3CelestialEmblem": "Set3_Celestial",
      "3BattlecastEmblem": "Battlecast",
      "3StarGuardianEmblem": "StarGuardian",
      "3ProtectorEmblem": "Protector",
      "3DarkStarEmblem": "DarkStar",
    },
    110: {
      "11GhostlyEmblemItem": "TFT11_Ghostly",
      "11PorcelainEmblemItem": "TFT11_Porcelain",
      "11FatedEmblemItem": "TFT11_Fated",
      "11MythicEmblemItem": "TFT11_Mythic",
      "11StoryweaverEmblemItem": "TFT11_Storyweaver",
      "11HeavenlyEmblemItem": "TFT11_Heavenly",
      "11DryadEmblemItem": "TFT11_Dryad",
      "11UmbralEmblemItem": "TFT11_Umbral",
      "11AltruistEmblemItem": "TFT11_Altruist",
      "11BehemothEmblemItem": "TFT11_Behemoth",
      "11BruiserEmblemItem": "TFT11_Bruiser",
      "11DragonlordEmblemItem": "TFT11_Dragonlord",
      "11ChallengerEmblemItem": "TFT11_Duelist",
      "11FortuneEmblemItem": "TFT11_Fortune",
      "11InkShadowEmblemItem": "TFT11_InkShadow",
      "11InvokerEmblemItem": "TFT11_Invoker",
      "11SpellslingerEmblemItem": "TFT11_Arcanist",
      "11ExaltedEmblemItem": "TFT11_Exalted",
      "11ReaperEmblemItem": "TFT11_Reaper",
      "11SageEmblemItem": "TFT11_Sage",
      "11SniperEmblemItem": "TFT11_Sniper",
      "11WardenEmblemItem": "TFT11_Warden",
    },
    120: {
      "12PortalEmblemItem": "TFT12_Portal",
      "12PyroEmblemItem": "TFT12_Pyro",
      "12SugarcraftEmblemItem": "TFT12_Sugarcraft",
      "12HoneymancyEmblemItem": "TFT12_Honeymancy",
      "12FaerieEmblemItem": "TFT12_Faerie",
      "12EldritchEmblemItem": "TFT12_Eldritch",
      "12FrostEmblemItem": "TFT12_Frost",
      "12WitchcraftEmblemItem": "TFT12_Witchcraft",
      "12ArcanaEmblemItem": "TFT12_Arcana",
      "12BastionEmblemItem": "TFT12_Bastion",
      "12BlasterEmblemItem": "TFT12_Blaster",
      "12ChronoEmblemItem": "TFT12_Chrono",
      "12HunterEmblemItem": "TFT12_Hunter",
      "12IncantorEmblemItem": "TFT12_Incantor",
      "12MageEmblemItem": "TFT12_Mage",
      "12MultistrikerEmblemItem": "TFT12_Multistriker",
      "12PreserverEmblemItem": "TFT12_Preserver",
      "12ScholarEmblemItem": "TFT12_Scholar",
      "12ShapeshifterEmblemItem": "TFT12_Shapeshifter",
      "12VanguardEmblemItem": "TFT12_Vanguard",
      "12WarriorEmblemItem": "TFT12_Warrior",
    },
    56: {
      "5AssassinEmblemItem": "TFT5_Assassin",
      "5SpellweaverEmblemItem": "TFT5_Spellweaver",
      "5RevenantEmblemItem": "TFT5_Revenant",
      "5LegionnaireEmblemItem": "TFT5_Legionnaire",
      "5RedeemedEmblemItem": "TFT5_Redeemed",
      "5RenewerEmblemItem": "TFT5_Renewer",
      "5ForgottenEmblemItem": "TFT5_Forgotten",
      "5AbominationEmblemItem": "TFT5_Abomination",
      "5CavalierEmblemItem": "TFT5_Cavalier",
      "5DawnbringerEmblemItem": "TFT5_Dawnbringer",
      "5HellionEmblemItem": "TFT5_Hellion",
      "5IroncladEmblemItem": "TFT5_Ironclad",
      "5NightbringerEmblemItem": "TFT5_Nightbringer",
      "5SkirmisherEmblemItem": "TFT5_Skirmisher",
      "5DraconicEmblemItem": "TFT5_Draconic",
      "5RangerEmblemItem": "TFT5_Ranger",
      "5BrawlerEmblemItem": "TFT5_Brawler",
      "5InvokerEmblemItem": "TFT5_Invoker",
      "5CannoneerEmblemItem": "TFT5_Cannoneer",
      "5SentinelEmblemItem": "TFT5_Sentinel",
      "5KnightEmblemItem": "TFT5_Knight",
      "5MysticEmblemItem": "TFT5_Mystic",
    },
    130: {
      "13AcademyEmblemItem": "TFT13_Academy",
      "13AmbusherEmblemItem": "TFT13_Ambusher",
      "13DemolitionistEmblemItem": "TFT13_Martialist",
      "13AutomataEmblemItem": "TFT13_Hextech",
      "13CabalEmblemItem": "TFT13_Cabal",
      "13BruiserEmblemItem": "TFT13_Bruiser",
      "13CrimeEmblemItem": "TFT13_Crime",
      "13WarbandEmblemItem": "TFT13_Warband",
      "13InfusedEmblemItem": "TFT13_Infused",
      "13SquadEmblemItem": "TFT13_Squad",
      "13ExperimentEmblemItem": "TFT13_Experiment",
      "13FamilyEmblemItem": "TFT13_Family",
      "13HoverboardEmblemItem": "TFT13_Hoverboard",
      "13PitFighterEmblemItem": "TFT13_Pugilist",
      "13ChallengerEmblemItem": "TFT13_Challenger",
      "13RebelEmblemItem": "TFT13_Rebel",
      "13ScrapEmblemItem": "TFT13_Scrap",
      "13TitanEmblemItem": "TFT13_Titan",
      "13SniperEmblemItem": "TFT13_Sniper",
      "13SorcererEmblemItem": "TFT13_Sorcerer",
      "13InvokerEmblemItem": "TFT13_Invoker",
      "13WatcherEmblemItem": "TFT13_Watcher",
    },
    46: {
      "4DragonsoulEmblemItem": "TFT4_Dragonsoul",
      "4MageEmblemItem": "TFT4_Mage",
      "4DuelistEmblemItem": "TFT4_Duelist",
      "4WarlordEmblemItem": "TFT4_Warlord",
      "4AssassinEmblemItem": "TFT4_Assassin",
      "4DivineEmblemItem": "TFT4_Divine",
      "4VanguardEmblemItem": "TFT4_Vanguard",
      "4ElderwoodEmblemItem": "TFT4_Elderwood",
      "4CultistEmblemItem": "TFT4_Cultist",
      "4FortuneEmblemItem": "TFT4_Fortune",
      "4SpiritEmblemItem": "TFT4_Spirit",
      "4EnlightenedEmblemItem": "TFT4_Enlightened",
      "4BrawlerEmblemItem": "TFT4_Brawler",
      "4KeeperEmblemItem": "TFT4_Keeper",
      "4SlayerEmblemItem": "TFT4_Slayer",
      "4SyphonerEmblemItem": "TFT4_Syphoner",
    },

  },
};

// Test
// Object.values(data.units).forEach((ui) => {
//   ui.traits.forEach((ts) => {
//     ts.forEach((t) => {
//       if (data.traits[t] == null) {
//         console.log(ui, t);
//       }
//     });
//   });
// });

// Test2
// Object.entries(data.itemTraits).forEach(([set, spatItems]) => {
//   Object.entries(spatItems).forEach(([itemId, trait]) => {
//     if (data.traits[trait] == null) {
//       console.log(itemId, trait);
//     }
//   });
// });

export const getTraitUnits = mem2((traitId: string, set: number) => {
  const units: Array<string> = [];
  Object.entries(data.units).forEach(([unitId, ui]) => {
    const idx = ui.sets.indexOf(set);
    if (idx === -1) {
      return;
    }
    if (ui.special || ui.version) {
      return;
    }

    let traits;
    if (ui.traits.length === 1) {
      traits = ui.traits[0];
    } else {
      traits = ui.traits[idx];
    }
    if (traits.includes(traitId)) {
      if (!(unitId.startsWith("TFT7_Nomsy") && unitId != "TFT7_Nomsy")) {
        units.push(unitId);
      }
    }
  });
  return units;
});

export const isTraitUnique = mem2((traitId: string, set: number) => {
  if (traitId === "Set7_Dragon" && set === 70) {
    return true;
  }
  const trait = data.traits[traitId].find((t) => t.sets.includes(set));
  if (trait == null) {
    return false;
  }
  return trait.singleUnit || (trait.tiers.length === 1 && trait.tiers[0] === 1);
});

export const traitStyle = mem3(
  (traitId: string, traitRank: number, set: number) => {
    if (traitRank <= 0) {
      return 0;
    }
    const trait = data.traits[traitId].find((t) => t.sets.includes(set));
    if (trait == null) {
      return undefined;
    }
    return trait.styles[traitRank - 1];
  },
);

export const traitRankUnitCount = mem3(
  (traitId: string, traitRank: number, set: number) => {
    const trait = data.traits[traitId].find((t) => t.sets.includes(set));
    if (trait == null) {
      return undefined;
    }
    return trait.tiers[Math.max(traitRank - 1, 0)];
  },
);

export const traitRank = mem3(
  (traitId: string, unitCount: number, set: number) => {
    const trait = data.traits[traitId].find((t) => t.sets.includes(set));
    if (trait == null) {
      return undefined;
    }
    let tierIdx = -1;
    for (let idx = trait.tiers.length; idx >= 0; idx--) {
      if (
        trait.exclusive
          ? unitCount === trait.tiers[idx]
          : unitCount >= trait.tiers[idx]
      ) {
        tierIdx = idx;
        break;
      }
    }
    return tierIdx + 1;
  },
);

export const getUnitTraits = mem2((unitId: string, set: number) => {
  const ui = data.units[unitId];
  if (ui == null) {
    return [];
  }
  if (ui.traits.length === 1) {
    return ui.traits[0];
  } else {
    const idx = ui.sets.indexOf(set);
    return ui.traits[idx];
  }
});

export const getUnitTraitsFull = mem2((unitId: string, set: number) => {
  const ui = data.units[unitId];
  let traits: Array<string> = [];
  if (ui.traits.length === 1) {
    traits = ui.traits[0];
  } else {
    const idx = ui.sets.indexOf(set);
    traits = ui.traits[idx];
  }
  if (traits.includes("Set7_Dragon")) {
    traits = [traits[0], traits[0], ...traits];
  }
  return traits;
});

export const allUnits = mem1((set: number): Array<string> => {
  const unitIds: Array<string> = [];
  Object.entries(data.units).forEach(([unitId, ui]) => {
    if (ui.sets.includes(set) && !ui.special && !ui.version) {
      if (!(unitId.startsWith("TFT7_Nomsy") && unitId != "TFT7_Nomsy")) {
        unitIds.push(unitId);
      }
    }
  });
  return unitIds;
});

export const allUnits2 = mem1((set: number): Array<string> => {
  const unitIds: Array<string> = [];
  Object.entries(data.units).forEach(([unitId, ui]) => {
    if (ui.sets.includes(set) && !ui.special && !ui.baseVersion) {
      if (unitId != "TFT7_Nomsy") {
        unitIds.push(unitId);
      }
    }
  });
  return unitIds;
});

export const allUnits3 = mem1((set: number): Array<string> => {
  const unitIds: Array<string> = [];
  Object.entries(data.units).forEach(([unitId, ui]) => {
    if (ui.sets.includes(set) && !ui.special) {
      if (unitId != "TFT7_Nomsy") {
        unitIds.push(unitId);
      }
    }
  });
  return unitIds;
});

export const allTraits = mem1((set: number) => {
  const traitIds: Array<string> = [];
  Object.entries(data.traits).forEach(([traitId, tis]) => {
    if (tis.some((ti) => ti.sets.includes(set) && ti.type !== "special")) {
      traitIds.push(traitId);
    }
  });
  return traitIds;
});

export const allTraits2 = mem1((set: number) => {
  const traitIds: Array<string> = [];
  Object.entries(data.traits).forEach(([traitId, tis]) => {
    if (tis.some((ti) => ti.sets.includes(set))) {
      traitIds.push(traitId);
    }
  });
  return traitIds;
});

export const allOrigins = mem1((set: number) => {
  const traitIds: Array<string> = [];
  Object.entries(data.traits).forEach(([traitId, tis]) => {
    if (tis.some((ti) => ti.sets.includes(set) && ti.type === "origin")) {
      traitIds.push(traitId);
    }
  });
  return traitIds;
});

export const allClasses = mem1((set: number) => {
  const traitIds: Array<string> = [];
  Object.entries(data.traits).forEach(([traitId, tis]) => {
    if (tis.some((ti) => ti.sets.includes(set) && ti.type === "class")) {
      traitIds.push(traitId);
    }
  });
  return traitIds;
});

export const unitTier = (unitId: string) => {
  return data.units[unitId]?.tier || 0;
};

if (typeof window !== "undefined") {
  // @ts-ignore
  window.unitTier = unitTier;
}

export const unitCost = (unitId: string, set: number) => {
  const tier = unitTier(unitId);
  if (tier == null) {
    return 0;
  }
  const traits = getUnitTraits(unitId, set);
  const isDragon = traits.includes("Set7_Dragon");
  if (set == 70) {
    if (isDragon) {
      return tier * 2;
    } else {
      return tier;
    }
  } else if (set == 75) {
    if (isDragon) {
      return tier + 3;
    } else {
      return tier;
    }
  } else {
    return tier;
  }
};

export const unitCode = (unitId: string) => {
  return data.units[unitId]?.code;
};

export const emblemTrait = (itemId: number, set: number) =>
  data.itemTraits[set][itemId];

export const traitRankCounts = mem2((traitId: string, set: number) => {
  const trait = data.traits[traitId].find((t) => t.sets.includes(set));
  if (trait == null) {
    return undefined;
  }
  return trait.tiers;
});

export const isOrigin = mem1(
  (traitId: string): boolean => data.traits[traitId][0].type === "origin",
);

export const isClass = mem1(
  (traitId: string): boolean => data.traits[traitId][0].type === "class",
);

export const toUrlName = mem1((id: string): string => {
  const split = id.split("_");
  if (split.length > 1) {
    return split[1].toLowerCase();
  } else {
    return split[0].toLowerCase();
  }
});

type urlNameMap = { [urlName: string]: string };
let _unitUrlNames: { [set: number]: urlNameMap } = {};
let _traitUrlNames: { [set: number]: urlNameMap } = {};
const unitUrlNames = (set: number): { [urlName: string]: string } => {
  if (_unitUrlNames[set] == null) {
    _unitUrlNames[set] = Object.fromEntries(
      allUnits(set).map((unitId) => [toUrlName(unitId), unitId]),
    );
  }

  return _unitUrlNames[set];
};
const traitUrlNames = (set: number): { [urlName: string]: string } => {
  if (_traitUrlNames[set] == null) {
    _traitUrlNames[set] = Object.fromEntries(
      allTraits(set).map((traitId) => [toUrlName(traitId), traitId]),
    );
  }

  return _traitUrlNames[set];
};

let _delowerIds: { [id: string]: string } | null = null;
export const delowerId = (id: string): string => {
  if (_delowerIds == null) {
    _delowerIds = {};
    for (let id of Object.keys(data.units)) {
      _delowerIds[id.toLowerCase()] = id;
    }
    for (let id of Object.keys(data.traits)) {
      _delowerIds[id.toLowerCase()] = id;
    }
  }
  return _delowerIds![id.toLowerCase()] || id;
};

export const fromUrlName = mem2(
  (name: string, set: number): string =>
    unitUrlNames(set)[name] || traitUrlNames(set)[name],
);
