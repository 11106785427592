// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Colors from "../Colors.bs.js";
import * as SdTs from "./Sd.ts";

var data = SdTs.data;

function getTraitUnits(prim0, prim1) {
  return SdTs.getTraitUnits(prim0, prim1);
}

function getUnitTraits(prim0, prim1) {
  return SdTs.getUnitTraits(prim0, prim1);
}

function getUnitTraitsFull(prim0, prim1) {
  return SdTs.getUnitTraitsFull(prim0, prim1);
}

function allUnits(prim) {
  return SdTs.allUnits(prim);
}

function allUnits2(prim) {
  return SdTs.allUnits2(prim);
}

function allUnits3(prim) {
  return SdTs.allUnits3(prim);
}

function allTraits(prim) {
  return SdTs.allTraits(prim);
}

function allTraits2(prim) {
  return SdTs.allTraits2(prim);
}

function allOrigins(prim) {
  return SdTs.allOrigins(prim);
}

function allClasses(prim) {
  return SdTs.allClasses(prim);
}

function unitTier(prim) {
  return SdTs.unitTier(prim);
}

function unitCost(prim0, prim1) {
  return SdTs.unitCost(prim0, prim1);
}

function unitCode(prim) {
  return SdTs.unitCode(prim);
}

function isTraitUnique(prim0, prim1) {
  return SdTs.isTraitUnique(prim0, prim1);
}

function traitStyle(prim0, prim1, prim2) {
  return SdTs.traitStyle(prim0, prim1, prim2);
}

function traitRankUnitCount(prim0, prim1, prim2) {
  return SdTs.traitRankUnitCount(prim0, prim1, prim2);
}

function traitRank(prim0, prim1, prim2) {
  return SdTs.traitRank(prim0, prim1, prim2);
}

function emblemTrait(prim0, prim1) {
  return SdTs.emblemTrait(prim0, prim1);
}

function traitRankCounts(prim0, prim1) {
  return SdTs.traitRankCounts(prim0, prim1);
}

var isOrigin = SdTs.isOrigin;

var isClass = SdTs.isClass;

var toUrlName = SdTs.toUrlName;

var fromUrlName = SdTs.fromUrlName;

var delowerId = SdTs.delowerId;

function tierColor(tier) {
  switch (tier) {
    case 2 :
        return Colors.uncommon;
    case 3 :
        return Colors.rare;
    case 4 :
        return Colors.epic;
    case 5 :
        return Colors.legendary;
    default:
      return Colors.common;
  }
}

export {
  data ,
  getTraitUnits ,
  getUnitTraits ,
  getUnitTraitsFull ,
  allUnits ,
  allUnits2 ,
  allUnits3 ,
  allTraits ,
  allTraits2 ,
  allOrigins ,
  allClasses ,
  unitTier ,
  unitCost ,
  unitCode ,
  isTraitUnique ,
  traitStyle ,
  traitRankUnitCount ,
  traitRank ,
  emblemTrait ,
  traitRankCounts ,
  isOrigin ,
  isClass ,
  toUrlName ,
  fromUrlName ,
  delowerId ,
  tierColor ,
  
}
/* data Not a pure module */
