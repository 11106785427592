// Generated by ReScript, PLEASE EDIT WITH CARE

import * as T from "common/src/T.bs.js";
import * as Sd from "common/src/tft-static/Sd.bs.js";
import * as TC from "common/src/components/TC.bs.js";
import * as Link from "../Link.bs.js";
import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Global from "common/src/Global.bs.js";
import * as TftSet from "common/src/data/TftSet.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Tc2Tags from "./Tc2Tags.bs.js";
import * as TcUtils from "../team-comps/TcUtils.bs.js";
import * as Tooltip from "common/src/components/Tooltip.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ItemName from "common/src/tft-static/ItemName.bs.js";
import * as TcTitlei from "../team-comps/TcTitlei.bs.js";
import * as ItemImage from "common/src/tft-static/ItemImage.bs.js";
import * as TbUtils13 from "../team-planner13/TbUtils13.bs.js";
import * as TbUtils46 from "../team-planner46/TbUtils46.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ScreenAtom from "common/src/atoms/ScreenAtom.bs.js";
import * as TraitImage from "common/src/tft-static/TraitImage.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Translation from "common/src/bindings/Translation.bs.js";
import * as Tc2Headliner from "./Tc2Headliner.bs.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as SnackbarService from "common/src/SnackbarService.bs.js";
import Fab from "@mui/material/Fab";
import * as UnitImageExtended from "common/src/tft-static/UnitImageExtended.bs.js";
import CopyToClipboard from "copy-to-clipboard";
import IconButton from "@mui/material/IconButton";
import Hexagon from "@mui/icons-material/Hexagon";
import OpenInFull from "@mui/icons-material/OpenInFull";
import UnfoldLess from "@mui/icons-material/UnfoldLess";
import UnfoldMore from "@mui/icons-material/UnfoldMore";
import ContentCopy from "@mui/icons-material/ContentCopy";

function Tc2Summary$ColossusPlaceholder(Props) {
  return React.createElement(TraitImage.PlainIcon.make, {
              className: "mx-[4px] sm:mx-[6px]",
              traitId: "Set7_Dragon",
              size: 35,
              sizeSm: 45
            });
}

var ColossusPlaceholder = {
  make: Tc2Summary$ColossusPlaceholder
};

function Tc2Summary$Unit(Props) {
  var starUnits = Props.starUnits;
  var exampleItems = Props.exampleItems;
  var unitId = Props.unitId;
  var prioStars = Props.prioStars;
  var otherStars = Props.otherStars;
  var items = Belt_Option.getWithDefault(Belt_MapString.get(exampleItems, unitId), []);
  var starTooltip = Belt_Option.map(Belt_Option.flatMap(starUnits, (function (su) {
              return Js_dict.get(su, unitId);
            })), (function (__x) {
          return TcUtils.getStarTooltip(__x, unitId);
        }));
  var tmp = {
    className: "mx-[3px] sm:mx-[5px]",
    size: 37,
    sizeSm: 47,
    unitId: unitId,
    goldenStars: prioStars.includes(unitId),
    items: items,
    unfilteredItems: true
  };
  var tmp$1 = prioStars.includes(unitId) || otherStars.includes(unitId) ? (
      Sd.unitTier(unitId) === 5 ? 2 : 3
    ) : undefined;
  if (tmp$1 !== undefined) {
    tmp.starLevel = Caml_option.valFromOption(tmp$1);
  }
  if (starTooltip !== undefined) {
    tmp.starTooltip = Caml_option.valFromOption(starTooltip);
  }
  return React.createElement(UnitImageExtended.make, tmp);
}

var Unit = {
  make: Tc2Summary$Unit
};

function Tc2Summary$ExpandButton(Props) {
  var t = Translation.useT(/* Common */0);
  return React.createElement("div", {
              className: "flex items-end ml-auto text-primary-11 mt-4 mb-1 tracking-wide"
            }, React.createElement("div", {
                  className: "uppercase text-sm font-medium leading-none"
                }, t("Details")), React.createElement("div", {
                  className: "mb-[2px] ml-1 h-[18px] mr-[2px]"
                }, React.createElement(OpenInFull, {
                      fontSize: "inherit",
                      color: "inherit"
                    })));
}

var make = React.memo(Tc2Summary$ExpandButton);

var ExpandButton = {
  make: make
};

function Tc2Summary$CompUnits(Props) {
  var group = Props.group;
  var emblem = Props.emblem;
  var filters = Props.filters;
  var t = Translation.useT(/* Common */0);
  var set = TftSet.use(undefined);
  var starUnits = group.starUnits;
  var match = TcUtils.getStarUnits(group);
  var otherStars = match[1];
  var prioStars = match[0];
  var comps = Belt_Option.isNone(emblem) && group.nonSpatComps2.length !== 0 ? group.nonSpatComps2 : group.spatComps2;
  var comps$1 = comps.length === 0 ? group.comps : comps;
  var sortedComps = Belt_SortArray.stableSortBy(filters.length !== 0 ? Belt_Array.concat(group.nonSpatComps2, group.spatComps2) : comps$1, (function (g1, g2) {
          return Caml_obj.caml_compare(g2.count, g1.count);
        }));
  Belt_Array.keep(sortedComps, (function (comp) {
          return TcUtils.compFilterMatch(comp, filters, set);
        }));
  var exampleItems = TcUtils.getExampleItems(group, emblem, set);
  var comps$2 = Belt_Array.slice(Belt_SortArray.stableSortBy(comps$1, (function (c1, c2) {
              return -Caml_obj.caml_compare(c1.count, c2.count) | 0;
            })), 0, 5);
  var coreUnits = TC.extractCore(Belt_Array.map(comps$2, (function (c) {
              return c.units;
            })));
  var bestComp = TcUtils.getBestComp(group, emblem, filters, set, coreUnits);
  var coreUnitCount = coreUnits.length;
  var compUnitCount = bestComp.units.length;
  var tmp;
  if (emblem !== undefined) {
    var itemId = emblem[0];
    tmp = React.createElement(Tooltip.make, {
          title: React.createElement("div", undefined, "This team composition requires ", React.createElement(ItemName.make, {
                    itemId: itemId
                  })),
          children: React.createElement(ItemImage.make, {
                itemId: itemId,
                size: 24,
                rounded: "rounded"
              })
        });
  } else {
    tmp = null;
  }
  var code = group.code;
  var tmp$1;
  if (bestComp.extraTraits.length === 1) {
    try {
      var traitId = Belt_Array.getExn(bestComp.extraTraits, 0);
      tmp$1 = React.createElement("div", {
            className: "hidden sm:block"
          }, React.createElement(Tc2Headliner.make, {
                traitId: traitId
              }));
    }
    catch (exn){
      tmp$1 = null;
    }
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", {
              className: "p-2 sm:px-3 sm:py-4 xl:px-4"
            }, React.createElement("div", {
                  className: "pt-[1px] flex justify-between"
                }, React.createElement("div", {
                      className: "flex items-center gap-1"
                    }, tmp, React.createElement(TcTitlei.make, {
                          small: true,
                          group: group
                        })), React.createElement("div", {
                      className: "flex items-center mt-[-2px]"
                    }, code !== undefined ? React.createElement(Tooltip.make, {
                            title: "Copy Team Planner code",
                            children: React.createElement(IconButton, {
                                  color: "primary",
                                  onClick: (function (ev) {
                                      CopyToClipboard(code);
                                      var cb = SnackbarService.sub.contents;
                                      if (cb !== undefined) {
                                        Curry._1(cb, "Team planner code copied to clipboard");
                                      }
                                      ev.stopPropagation();
                                      
                                    }),
                                  size: "small",
                                  children: React.createElement(ContentCopy, {
                                        fontSize: "small"
                                      })
                                })
                          }) : null, React.createElement("div", {
                          onClick: (function (e) {
                              e.stopPropagation();
                              
                            })
                        }, React.createElement(Link.make, {
                              target: "_blank",
                              route: set === 130 ? ({
                                    TAG: /* TeamPlanner */3,
                                    _0: TbUtils13.generateBoard(Belt_Array.getExn(comps$1, 0).units, undefined)
                                  }) : ({
                                    TAG: /* TeamPlanner */3,
                                    _0: TbUtils46.generateBoard(Belt_Array.getExn(comps$1, 0).units, undefined)
                                  }),
                              children: React.createElement(Tooltip.make, {
                                    title: "Open in Team Builder",
                                    children: React.createElement(IconButton, {
                                          color: "primary",
                                          onClick: (function (param) {
                                              
                                            }),
                                          size: "small",
                                          children: React.createElement(Hexagon, {
                                                fontSize: "small"
                                              })
                                        })
                                  })
                            })))), React.createElement("div", {
                  className: "flex pt-2 sm:pt-1 justify-center text-[14px]"
                }, coreUnits.length === 0 ? null : React.createElement("div", {
                        className: CssJs.merge([
                              "shrink-0 flex items-center",
                              CssJs.style([
                                    CssJs.width(CssJs.px(Math.imul(37, coreUnitCount) + Math.imul(6, coreUnitCount - 1 | 0) | 0)),
                                    T.sm_([CssJs.width(CssJs.px(Math.imul(47, coreUnitCount) + Math.imul(10, coreUnitCount - 1 | 0) | 0))])
                                  ])
                            ])
                      }, React.createElement("div", {
                            className: "h-[1px] bg-white flex-1"
                          }), React.createElement("div", {
                            className: "px-[5px]"
                          }, t("Core")), React.createElement("div", {
                            className: "h-[1px] bg-white flex-1"
                          })), coreUnits.length === compUnitCount ? null : React.createElement("div", {
                        className: CssJs.merge([
                              "shrink-0 flex items-center ml-[6px] sm:ml-3",
                              CssJs.style([
                                    CssJs.width(CssJs.px(Math.imul(37, compUnitCount - coreUnitCount | 0) + Math.imul(6, (compUnitCount - coreUnitCount | 0) - 1 | 0) | 0)),
                                    T.sm_([CssJs.width(CssJs.px(Math.imul(47, compUnitCount - coreUnitCount | 0) + Math.imul(10, (compUnitCount - coreUnitCount | 0) - 1 | 0) | 0))])
                                  ])
                            ])
                      }, React.createElement("div", {
                            className: "h-[1px] bg-white flex-1"
                          }), React.createElement("div", {
                            className: "px-[5px]"
                          }, t("Flex")), React.createElement("div", {
                            className: "h-[1px] bg-white flex-1"
                          }))), React.createElement("div", {
                  className: "flex items-center justify-center pt-2 mx-[-3px] sm:mx-[-6px]"
                }, React.createElement("div", {
                      className: "flex"
                    }, Belt_Array.map(coreUnits, (function (unitId) {
                            var tmp = {
                              exampleItems: exampleItems,
                              group: group,
                              unitId: unitId,
                              prioStars: prioStars,
                              otherStars: otherStars,
                              key: unitId
                            };
                            if (starUnits !== undefined) {
                              tmp.starUnits = Caml_option.valFromOption(starUnits);
                            }
                            return React.createElement(Tc2Summary$Unit, tmp);
                          }))), React.createElement("div", {
                      className: "flex"
                    }, Belt_Array.mapWithIndex(TC.removeCoreUnits(bestComp.units, coreUnits), (function (idx, unitId) {
                            var tmp = {
                              exampleItems: exampleItems,
                              group: group,
                              unitId: unitId,
                              prioStars: prioStars,
                              otherStars: otherStars,
                              key: String(idx)
                            };
                            if (starUnits !== undefined) {
                              tmp.starUnits = Caml_option.valFromOption(starUnits);
                            }
                            return React.createElement(Tc2Summary$Unit, tmp);
                          }))), tmp$1));
}

var make$1 = React.memo(Tc2Summary$CompUnits);

var CompUnits = {
  make: make$1
};

function Tc2Summary$CompStats(Props) {
  var group = Props.group;
  var globalCount = Props.globalCount;
  var t = Translation.useT(/* Common */0);
  var pr = Global.f(group.count) / Global.f(globalCount) * 8;
  return React.createElement("div", {
              className: "flex flex-row self-center h-full justify-center items-center xl:justify-start"
            }, React.createElement("div", {
                  className: "self-center font-montserrat w-[150px] grid gap-1 text-[16px] leading-[18px] text-center"
                }, React.createElement("div", {
                      className: "grid grid-cols-[auto,auto] gap-x-[14px] gap-y-[12px]"
                    }, React.createElement("div", undefined, React.createElement("div", {
                              className: "text-white2 pb-1"
                            }, t("Play Rate")), React.createElement("div", {
                              className: "font-medium text-center text-[18px]"
                            }, pr < 0.01 ? pr.toFixed(3).slice(1, 99) : pr.toFixed(2))), React.createElement("div", undefined, React.createElement("div", {
                              className: "text-white2 pb-1"
                            }, t("Place")), React.createElement("div", {
                              className: "font-medium text-center text-[18px]"
                            }, Global.toFixed(undefined, group.place))), React.createElement("div", undefined, React.createElement("div", {
                              className: "text-white2 pb-1"
                            }, t("Top 4 %")), React.createElement("div", {
                              className: "font-medium text-center text-[18px]"
                            }, Global.formatPct(undefined, Global.f(group.top4) / Global.f(group.count)))), React.createElement("div", undefined, React.createElement("div", {
                              className: "text-white2 pb-1"
                            }, t("Win %")), React.createElement("div", {
                              className: "font-medium text-center text-[18px]"
                            }, Global.formatPct(undefined, Global.f(group.win) / Global.f(group.count)))))));
}

var CompStats = {
  make: Tc2Summary$CompStats
};

function Tc2Summary(Props) {
  var filtersOpt = Props.filters;
  var group = Props.group;
  var globalCount = Props.globalCount;
  var onExpand = Props.onExpand;
  var onHover = Props.onHover;
  var subcomps = Props.subcomps;
  var filters = filtersOpt !== undefined ? filtersOpt : [];
  var set = TftSet.use(undefined);
  var t = Translation.useT(/* Common */0);
  var screenSize = ScreenAtom.use(undefined);
  var emblem = React.useMemo((function () {
          return TcUtils.getEmblem(group, set);
        }), [group]);
  var tmp;
  if (Belt_Option.isNone(onHover)) {
    tmp = false;
  } else {
    switch (screenSize) {
      case /* XXl */0 :
      case /* Xl */1 :
      case /* Sm */4 :
          tmp = false;
          break;
      case /* Lg */2 :
      case /* Md */3 :
      case /* Xs */5 :
          tmp = true;
          break;
      
    }
  }
  var tmp$1;
  if (subcomps !== undefined) {
    var setExpanded = subcomps[1];
    var expanded = subcomps[0];
    tmp$1 = React.createElement("div", {
          className: "absolute z-10 " + (
            expanded ? "top-[45px]" : "top-[65px]"
          ) + " left-[-22px]"
        }, React.createElement(Fab, {
              classes: {
                root: "w-[32px] h-[32px] min-h-[32px]"
              },
              color: "secondary",
              size: "small",
              onClick: (function (e) {
                  Curry._1(setExpanded, (function (exp) {
                          return !exp;
                        }));
                  return e.stopPropagation();
                }),
              children: expanded ? React.createElement(UnfoldLess, {
                      viewBox: "0 -0.5 24 24"
                    }) : React.createElement(UnfoldMore, {
                      viewBox: "0 -0.5 24 24"
                    })
            }));
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", {
              className: "relative pl-2 pr-1 flex flex-col md:flex-row " + (
                Belt_Option.isSome(onHover) ? "cursor-pointer tc-summary-wrap" : ""
              ),
              onClick: (function (param) {
                  return Curry._1(onExpand, group);
                }),
              onMouseOver: (function (param) {
                  if (onHover !== undefined) {
                    setTimeout((function (param) {
                            return Curry._1(onHover, group);
                          }), 0);
                    return ;
                  }
                  
                })
            }, React.createElement(make$1, {
                  group: group,
                  emblem: emblem,
                  filters: filters
                }), React.createElement("div", {
                  className: "flex text-sm sm:text-base px-2 py-2 sm:pb-4 pt-3 sm:pt-2 md:pt-4 sm:pl-3 sm:pr-0 justify-around xl:justify-between items-stretch"
                }, React.createElement(Tc2Summary$CompStats, {
                      group: group,
                      globalCount: globalCount
                    }), React.createElement("div", {
                      className: "flex flex-col pl-6 pr-4"
                    }, React.createElement(Tc2Tags.make, {
                          group: group,
                          compact: tmp
                        }), onHover !== undefined ? React.createElement("div", {
                            className: "flex sm:hidden md:flex xl:hidden items-end ml-auto text-primary-11 pl-12 pt-2 mt-auto mb-1 tracking-wide"
                          }, React.createElement("div", {
                                className: "uppercase text-sm font-medium leading-none"
                              }, t("Details")), React.createElement("div", {
                                className: "mb-[2px] ml-1 h-[18px] mr-[2px]"
                              }, React.createElement(OpenInFull, {
                                    fontSize: "inherit",
                                    color: "inherit"
                                  }))) : null), Belt_Option.isSome(onHover) ? React.createElement("div", {
                        className: "pl-2 pr-4 md:pr-3 xl:pr-4 hidden sm:flex md:hidden xl:flex flex-col h-full min-w-[132px]"
                      }, onHover !== undefined ? React.createElement("div", {
                              className: "flex mt-auto"
                            }, React.createElement(make, {})) : null) : null), tmp$1);
}

var make$2 = React.memo(Tc2Summary);

var unitSize = 37;

var unitSizeSm = 47;

export {
  unitSize ,
  unitSizeSm ,
  ColossusPlaceholder ,
  Unit ,
  ExpandButton ,
  CompUnits ,
  CompStats ,
  make$2 as make,
  
}
/* make Not a pure module */
