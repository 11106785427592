// Generated by ReScript, PLEASE EDIT WITH CARE

import * as T from "common/src/T.bs.js";
import * as Sd from "common/src/tft-static/Sd.bs.js";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Dict from "common/src/util/Dict.bs.js";
import * as Base64 from "../bindings/Base64.bs.js";
import * as Data46 from "common/src/data/Data46.bs.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Trait9 from "common/src/data/Trait9.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TbUtilsCore from "../team-planner-core/TbUtilsCore.bs.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Belt_MutableMapInt from "rescript/lib/es6/belt_MutableMapInt.js";

var version = "V";

var version2 = "V";

var GameCfg = {
  $$default: {
    umbral: undefined,
    exalted: undefined
  }
};

function encodeStr(content) {
  if (content.TAG === /* Unit */0) {
    return content._0;
  }
  switch (content._0) {
    case /* JayceSummon */0 :
        return "TFT13_JayceSummon";
    case /* Sentinel */1 :
        return "TFT_BlueGolem";
    case /* Dummy */2 :
        return "TFT_TrainingDummy";
    case /* Sion */3 :
        return "TFT13_Sion";
    
  }
}

function decodeStr(str) {
  switch (str) {
    case "TFT13_JayceSummon" :
        return {
                TAG: /* Other */1,
                _0: /* JayceSummon */0
              };
    case "TFT13_Sion" :
        return {
                TAG: /* Other */1,
                _0: /* Sion */3
              };
    case "TFT_BlueGolem" :
        return {
                TAG: /* Other */1,
                _0: /* Sentinel */1
              };
    case "TFT_TrainingDummy" :
        return {
                TAG: /* Other */1,
                _0: /* Dummy */2
              };
    default:
      return {
              TAG: /* Unit */0,
              _0: str
            };
  }
}

function encodeUnit(unit) {
  var idx = Sd.allUnits2(46).indexOf(unit);
  if (idx >= 60) {
    return Base64.encode1(idx + 4 | 0);
  } else {
    return Base64.encode(idx, 1);
  }
}

function encode(contents) {
  if (contents.TAG === /* Unit */0) {
    return encodeUnit(contents._0);
  }
  switch (contents._0) {
    case /* JayceSummon */0 :
        return Base64.encode(62, 1);
    case /* Sentinel */1 :
        return Base64.encode(63, 1);
    case /* Dummy */2 :
        return Base64.encode(61, 1);
    case /* Sion */3 :
        return Base64.encode(60, 1);
    
  }
}

function decode(str) {
  var id = Base64.decode(str);
  switch (id) {
    case 60 :
        return {
                TAG: /* Other */1,
                _0: /* Sion */3
              };
    case 61 :
        return {
                TAG: /* Other */1,
                _0: /* Dummy */2
              };
    case 62 :
        return {
                TAG: /* Other */1,
                _0: /* JayceSummon */0
              };
    case 63 :
        return {
                TAG: /* Other */1,
                _0: /* Sentinel */1
              };
    default:
      var idx = id >= 64 ? id - 4 | 0 : id;
      return {
              TAG: /* Unit */0,
              _0: Belt_Array.getExn(Sd.allUnits2(46), idx)
            };
  }
}

function canHaveItems(t) {
  if (t.TAG === /* Unit */0) {
    return true;
  } else {
    return (t._0 - 1 >>> 0) <= 1;
  }
}

var Content = {
  encodeStr: encodeStr,
  decodeStr: decodeStr,
  encodeUnit: encodeUnit,
  encode: encode,
  decode: decode,
  canHaveItems: canHaveItems
};

function encodeItemId(id) {
  var info = Dict.getExn(Data46.items, id);
  var id$1 = info.id + 1 | 0;
  if (id$1 <= 31) {
    return Base64.encode(id$1, 1);
  }
  var c1 = Base64.encode((id$1 / 32 | 0) + 32 | 0, 1);
  var c2 = Base64.encode(id$1 % 32, 1);
  return c1 + c2;
}

function encodeDescr(itemCount, chosenTrait) {
  return Base64.encode((chosenTrait << 4) + itemCount | 0, 1);
}

function decodeDescr(v) {
  var descr = Base64.decode(v);
  var itemCount = descr % 16;
  var chosenTrait = descr / 16 | 0;
  return [
          itemCount,
          chosenTrait
        ];
}

function encode$1(items, chosenTrait) {
  var itemsCode = Belt_Array.map(items, encodeItemId).join("");
  if (items.length === 0 && chosenTrait === 0) {
    return "";
  }
  var itemCount = items.length;
  var descr = encodeDescr(itemCount, chosenTrait);
  return "." + descr + itemsCode;
}

function decode$1(str) {
  var match = decodeDescr(str.charAt(0));
  var itemCount = match[0];
  var items = [];
  var idx = 1;
  var currPrefix;
  var currCount = 0;
  while(currCount < itemCount) {
    var id = Base64.decode(str.charAt(idx));
    var contents = currPrefix;
    if (contents !== undefined) {
      items.push((contents << 5) + id | 0);
      currPrefix = undefined;
      currCount = currCount + 1 | 0;
    } else if (id > 31) {
      currPrefix = id % 32;
    } else {
      items.push(id);
      currCount = currCount + 1 | 0;
    }
    idx = idx + 1 | 0;
  };
  return [
          Belt_Array.map(items, (function (id) {
                  return Belt_Array.getExn(Data46.itemsArr(), id - 1 | 0)[0];
                })),
          match[1],
          idx
        ];
}

var Items = {
  encodeItemId: encodeItemId,
  encodeDescr: encodeDescr,
  decodeDescr: decodeDescr,
  encode: encode$1,
  decode: decode$1
};

function encode$2(param) {
  var match = param[1];
  var k = param[0];
  var colNum = k / 10 | 0;
  var rowNum = k % 10;
  return encode(match.unit) + TbUtilsCore.encodePosition(colNum, rowNum) + encode$1(match.items, match.chosenTrait);
}

function decode$2(str, starLevel) {
  var contents = decode(str.charAt(0));
  var match = TbUtilsCore.decodePosition(str.charAt(1));
  var position = Math.imul(match[0], 10) + match[1] | 0;
  if (str.charAt(2) !== ".") {
    return [
            position,
            {
              unit: contents,
              items: [],
              starLevel: starLevel,
              chosenTrait: 0
            },
            2
          ];
  }
  var match$1 = decode$1(str.slice(3));
  return [
          position,
          {
            unit: contents,
            items: match$1[0],
            starLevel: starLevel,
            chosenTrait: match$1[1]
          },
          3 + match$1[2] | 0
        ];
}

var Hex = {
  encode: encode$2,
  decode: decode$2
};

function encode$3(group, count) {
  var tmp;
  switch (group) {
    case /* S2 */0 :
        tmp = 0;
        break;
    case /* S3 */1 :
        tmp = 1;
        break;
    case /* S4 */2 :
        tmp = 3;
        break;
    case /* Augments */3 :
        tmp = 2;
        break;
    
  }
  return Base64.encode(tmp + (count << 2) | 0, 1);
}

function decode$3($$char) {
  var desc = Base64.decode($$char);
  var id = desc % 4;
  var count = desc / 4 | 0;
  var id$1;
  switch (id) {
    case 0 :
        id$1 = /* S2 */0;
        break;
    case 1 :
        id$1 = /* S3 */1;
        break;
    case 2 :
        id$1 = /* Augments */3;
        break;
    case 3 :
        id$1 = /* S4 */2;
        break;
    default:
      id$1 = Js_exn.raiseError("Unknown group id " + String(id));
  }
  return [
          id$1,
          count
        ];
}

var Group = {
  encode: encode$3,
  decode: decode$3
};

var allLegends = [
  "rnn",
  "Tahm Kench",
  "Veigar",
  "Draven",
  "Pengu",
  "Urf",
  "Aurelion Sol",
  "Master Yi",
  "Ezreal",
  "Lee Sin",
  "Vladimir",
  "Twisted Fate",
  "Bard",
  "Caitlyn",
  "Poro"
];

function decodeQs(qs) {
  if (qs === "") {
    return [
            undefined,
            [],
            {
              umbral: undefined,
              exalted: undefined
            }
          ];
  }
  if (qs.charAt(0) !== version && qs.charAt(0) !== version2 && qs.charAt(0) !== "P") {
    return [
            undefined,
            [],
            {
              umbral: undefined,
              exalted: undefined
            }
          ];
  }
  var match;
  if (qs.charAt(0) === version) {
    match = [
      {
        contents: qs.slice(1)
      },
      {
        umbral: undefined,
        exalted: undefined
      }
    ];
  } else {
    var p1 = Base64.decode(qs.charAt(1));
    var p2 = Base64.decode(qs.charAt(2));
    var cfg = p1 / 8 | 0;
    var umbralCfg = p1 % 8;
    var umbral = umbralCfg > 0 ? umbralCfg - 1 | 0 : undefined;
    var match$1 = cfg > 0 ? [
        p2,
        qs.slice(3)
      ] : [
        undefined,
        qs.slice(2)
      ];
    match = [
      {
        contents: match$1[1]
      },
      {
        umbral: umbral,
        exalted: match$1[0]
      }
    ];
  }
  var rem = match[0];
  var units = Belt_MutableMapInt.make(undefined);
  var augments = [];
  var remNonStarred = false;
  while(rem.contents.length > 0) {
    if (remNonStarred) {
      var match$2 = decode$2(rem.contents, 0);
      Belt_MutableMapInt.set(units, match$2[0], match$2[1]);
      rem.contents = rem.contents.slice(match$2[2]);
    } else {
      var match$3 = decode$3(rem.contents.charAt(0));
      var count = match$3[1];
      rem.contents = rem.contents.slice(1);
      switch (match$3[0]) {
        case /* S2 */0 :
            for(var _for = 1; _for <= count; ++_for){
              var match$4 = decode$2(rem.contents, 2);
              Belt_MutableMapInt.set(units, match$4[0], match$4[1]);
              rem.contents = rem.contents.slice(match$4[2]);
            }
            break;
        case /* S3 */1 :
            for(var _for$1 = 1; _for$1 <= count; ++_for$1){
              var match$5 = decode$2(rem.contents, 3);
              Belt_MutableMapInt.set(units, match$5[0], match$5[1]);
              rem.contents = rem.contents.slice(match$5[2]);
            }
            break;
        case /* S4 */2 :
            for(var _for$2 = 1; _for$2 <= count; ++_for$2){
              var match$6 = decode$2(rem.contents, 4);
              Belt_MutableMapInt.set(units, match$6[0], match$6[1]);
              rem.contents = rem.contents.slice(match$6[2]);
            }
            break;
        case /* Augments */3 :
            var allAugs = Data46.augmentsArr();
            for(var i = 1; i <= count; ++i){
              var substr = rem.contents.substring(((i - 1 | 0) << 1), ((i - 1 | 0) << 1) + 2 | 0);
              var idx = Base64.decode(substr);
              var match$7 = Belt_Array.get(allAugs, idx);
              if (match$7 !== undefined) {
                augments.push(match$7[0]);
              }
              
            }
            rem.contents = rem.contents.slice((count << 1));
            remNonStarred = true;
            break;
        
      }
    }
  };
  return [
          Belt_MapInt.fromArray(Belt_MutableMapInt.toArray(units)),
          augments,
          match[1]
        ];
}

function encodeQs(units, augments, gameCfg) {
  var units$1 = Belt_MapInt.toArray(units);
  var s2Units = Belt_Array.keep(units$1, (function (param) {
          return param[1].starLevel === 2;
        }));
  var s3Units = Belt_Array.keep(units$1, (function (param) {
          return param[1].starLevel === 3;
        }));
  var s4Units = Belt_Array.keep(units$1, (function (param) {
          return param[1].starLevel === 4;
        }));
  var s0Units = Belt_Array.keep(units$1, (function (param) {
          return param[1].starLevel === 0;
        }));
  var s2Encode = s2Units.length === 0 ? "" : Belt_Array.reduce(Belt_Array.range(0, ((-1 + (s2Units.length / 15 | 0) | 0) + s2Units.length % 15 | 0) > 0 ? 1 : 0), "", (function (acc, idx) {
            var units = Belt_Array.slice(s2Units, Math.imul(idx, 15), 15);
            var groupKey = encode$3(/* S2 */0, units.length);
            return acc + groupKey + Belt_Array.reduce(units, "", (function (acc, param) {
                          return acc + encode$2([
                                      param[0],
                                      param[1]
                                    ]);
                        }));
          }));
  var s3Encode = s3Units.length === 0 ? "" : Belt_Array.reduce(Belt_Array.range(0, ((-1 + (s3Units.length / 15 | 0) | 0) + s3Units.length % 15 | 0) > 0 ? 1 : 0), "", (function (acc, idx) {
            var units = Belt_Array.slice(s3Units, Math.imul(idx, 15), 15);
            var groupKey = encode$3(/* S3 */1, units.length);
            return acc + groupKey + Belt_Array.reduce(units, "", (function (acc, param) {
                          return acc + encode$2([
                                      param[0],
                                      param[1]
                                    ]);
                        }));
          }));
  var s4Encode = s4Units.length === 0 ? "" : Belt_Array.reduce(Belt_Array.range(0, ((-1 + (s4Units.length / 15 | 0) | 0) + s4Units.length % 15 | 0) > 0 ? 1 : 0), "", (function (acc, idx) {
            var units = Belt_Array.slice(s4Units, Math.imul(idx, 15), 15);
            var groupKey = encode$3(/* S4 */2, units.length);
            return acc + groupKey + Belt_Array.reduce(units, "", (function (acc, param) {
                          return acc + encode$2([
                                      param[0],
                                      param[1]
                                    ]);
                        }));
          }));
  var augmentsEncode = encode$3(/* Augments */3, augments.length) + Belt_Array.reduce(augments, "", (function (acc, augId) {
          var v = Js_dict.get(Data46.augments, augId);
          if (v !== undefined) {
            return acc + Base64.encode(v.id, 2);
          } else {
            return "";
          }
        }));
  var restEncode = Belt_Array.reduce(s0Units, "", (function (acc, param) {
          return acc + encode$2([
                      param[0],
                      param[1]
                    ]);
        }));
  var match;
  if (Belt_Option.isSome(gameCfg.umbral) || Belt_Option.isSome(gameCfg.exalted)) {
    var v = gameCfg.umbral;
    var umbralV = v !== undefined ? v + 1 | 0 : 0;
    var exIdx = gameCfg.exalted;
    var encoded = exIdx !== undefined ? Base64.encode(8 + umbralV | 0, 1) + Base64.encode(exIdx, 1) : Base64.encode(umbralV, 1);
    match = [
      version2,
      encoded
    ];
  } else {
    match = [
      version,
      ""
    ];
  }
  return match[0] + match[1] + s2Encode + s3Encode + s4Encode + augmentsEncode + restEncode;
}

function getTraitRanks(allHexes, augments, extraTrait, exaltedUnits) {
  var uniqUnits = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.keepMap(allHexes, (function (u) {
                  var unitId = u.unit;
                  if (unitId.TAG === /* Unit */0) {
                    return unitId._0;
                  }
                  
                }))));
  var itemTraits = Belt_Array.keepMap(Belt_Array.concatMany(Belt_Array.map(allHexes, (function (u) {
                  return u.items;
                }))), (function (id) {
          return Sd.emblemTrait(id, 46);
        }));
  var augmentTraits = Belt_Array.map(Belt_Array.concatMany(Belt_Array.map(augments, Trait9.fromAugmentId)), Trait9.toApiName);
  var allTraits = Belt_Array.concat(Belt_Array.concat(Belt_Array.concat(Belt_Array.concat(Belt_Array.concatMany(Belt_Array.map(uniqUnits, (function (unit) {
                              return Sd.getUnitTraitsFull(unit, 46);
                            }))), itemTraits), augmentTraits), Belt_Option.mapWithDefault(extraTrait, [], (function (v) {
                  return [v];
                }))), Belt_Array.map(Belt_Array.keep(uniqUnits, (function (unitId) {
                  return exaltedUnits.includes(unitId);
                })), (function (param) {
              return "TFT11_Exalted";
            })));
  if (uniqUnits.includes("TFT11_Xayah") && uniqUnits.includes("TFT11_Rakan")) {
    var tidx = allTraits.indexOf("TFT11_Lovers");
    allTraits.splice(tidx, 1);
    var tidx$1 = allTraits.indexOf("TFT11_Dragonlord");
    allTraits.splice(tidx$1, 1);
  }
  var uniqTraits = Belt_SortArray.stableSortBy(Belt_SetString.toArray(Belt_SetString.fromArray(allTraits)), (function (t1, t2) {
          var c1 = Belt_Array.keep(allTraits, (function (t) {
                  return t === t1;
                })).length;
          var c2 = Belt_Array.keep(allTraits, (function (t) {
                  return t === t2;
                })).length;
          if (c1 !== c2) {
            return -Caml.caml_int_compare(c1, c2) | 0;
          } else {
            return Caml.caml_string_compare(t1, t2);
          }
        }));
  return Belt_Array.map(uniqTraits, (function (trait) {
                var count = Belt_Array.keep(allTraits, (function (t) {
                        return t === trait;
                      })).length;
                return [
                        trait,
                        count
                      ];
              }));
}

function getData(units, top, augments, exaltedUnits) {
  var allData = Belt_Array.concatMany(Belt_Array.map(Belt_Array.range(0 + (
                top ? 4 : 0
              ) | 0, 3 + (
                top ? 4 : 0
              ) | 0), (function (rowNum) {
              return Belt_Array.keepMap(Belt_Array.range(0, 6), (function (colNum) {
                            return Belt_Option.map(Belt_MapInt.get(units, Math.imul(colNum, 10) + rowNum | 0), (function (u) {
                                          return [
                                                  [
                                                    top ? 7 - rowNum | 0 : rowNum,
                                                    top ? 6 - colNum | 0 : colNum
                                                  ],
                                                  u
                                                ];
                                        }));
                          }));
            })));
  var allUnits = Belt_Array.map(allData, (function (prim) {
          return prim[1];
        }));
  var traitCounts = getTraitRanks(allUnits, augments, undefined, exaltedUnits);
  var allItems = Belt_Array.concatMany(Belt_Array.map(allUnits, (function (u) {
              return u.items;
            })));
  return [
          allUnits,
          traitCounts,
          allItems,
          undefined
        ];
}

function generateBoard_(units) {
  var r1Units = {
    contents: 0
  };
  var r2Units = {
    contents: 0
  };
  var r3Units = {
    contents: 0
  };
  var r4Units = {
    contents: 0
  };
  var content = [];
  Belt_Array.forEach(units, (function (u) {
          var info = Dict.get(Data46.units, u);
          var range = info !== undefined ? Caml_option.valFromOption(info).stats.range : 2;
          var match;
          switch (range) {
            case 1 :
                var idx = r1Units.contents;
                r1Units.contents = idx + 1 | 0;
                match = idx > 6 ? [
                    2,
                    idx - 7 | 0
                  ] : [
                    3,
                    idx
                  ];
                break;
            case 2 :
                var idx$1 = r2Units.contents;
                r2Units.contents = idx$1 + 1 | 0;
                match = [
                  2,
                  idx$1
                ];
                break;
            case 3 :
                var idx$2 = r3Units.contents;
                r3Units.contents = idx$2 + 1 | 0;
                match = [
                  1,
                  idx$2
                ];
                break;
            default:
              var idx$3 = r4Units.contents;
              r4Units.contents = idx$3 + 1 | 0;
              match = idx$3 > 6 ? [
                  1,
                  idx$3 - 7 | 0
                ] : [
                  0,
                  idx$3
                ];
          }
          content.push([
                Math.imul(match[1], 10) + match[0] | 0,
                {
                  unit: {
                    TAG: /* Unit */0,
                    _0: u
                  },
                  items: [],
                  starLevel: 0,
                  chosenTrait: 0
                }
              ]);
          
        }));
  return Belt_MapInt.fromArray(content);
}

function generateBoard(units, exalted) {
  var content = generateBoard_(units);
  return encodeQs(content, [], {
              umbral: undefined,
              exalted: exalted
            });
}

function generateBoardFull(units, augments) {
  return "";
}

var codeUnits = [
  "TFT4_Aatrox",
  "TFT4_Akali",
  "TFT4_Annie",
  "TFT4_AurelionSol",
  "TFT4_Azir",
  "TFT4_Brand",
  "TFT4_Braum",
  "TFT4_ChoGath",
  "TFT4_Darius",
  "TFT4b_Diana",
  "TFT4_Elise",
  "TFT4_Fiora",
  "TFT4_Garen",
  "TFT4_Irelia",
  "TFT4_Janna",
  "TFT4_JarvanIV",
  "TFT4_Jax",
  "TFT4_Kalista",
  "TFT4_Katarina",
  "TFT4_Kayle",
  "TFT4_Kennen",
  "TFT4b_Kindred",
  "TFT4_LeeSin",
  "TFT4_Lulu",
  "TFT4_Maokai",
  "TFT4b_Morgana",
  "TFT4_Nasus",
  "TFT4_Nautilus",
  "TFT4_Neeko",
  "TFT4_Nidalee",
  "TFT4_Nunu",
  "TFT4_Olaf",
  "TFT4_Ornn",
  "TFT4_Pyke",
  "TFT4_Rakan",
  "TFT4_Samira",
  "TFT4_Sejuani",
  "TFT4_Sett",
  "TFT4_Shen",
  "TFT4_Shyvana",
  "TFT4_Sivir",
  "TFT4_Swain",
  "TFT4_TahmKench",
  "TFT4_Talon",
  "TFT4_Teemo",
  "TFT4_Tristana",
  "TFT4_Tryndamere",
  "TFT4_TwistedFate",
  "TFT4_Veigar",
  "TFT4_Vi",
  "TFT4_Vladimir",
  "TFT4_Wukong",
  "TFT4_Xayah",
  "TFT4_Yasuo",
  "TFT4_Yone",
  "TFT4_Yuumi",
  "TFT4b_Zed",
  "TFT4_Zilean"
];

function unitCode(unitId) {
  var idx = codeUnits.indexOf(unitId);
  return T.toHex(idx + 1 | 0);
}

var codeSet = "TFTSet4_Act2";

var codeSetLower = codeSet.toLowerCase();

var codeSetLen = codeSet.length;

function genCode(units) {
  var padding = Belt_Array.map(Belt_Array.range(0, 9 - units.length | 0), (function (param) {
            return "00";
          })).join("");
  var units$1 = Belt_SortArray.stableSortBy(units, (function (uid1, uid2) {
          return Caml.caml_int_compare(Sd.allUnits2(130).indexOf(uid1), Sd.allUnits2(130).indexOf(uid2));
        }));
  return "01" + Belt_Array.map(units$1, unitCode).join("") + padding + codeSet;
}

function isValidCode(code) {
  if (code.endsWith(codeSetLower) && code.startsWith("01")) {
    return code.length === (22 + codeSetLen | 0);
  } else {
    return false;
  }
}

function boardFromCode(code) {
  var code$1 = code.toLowerCase();
  if (!isValidCode(code$1)) {
    return ;
  }
  var code$2 = code$1.slice(2, code$1.length - codeSetLen | 0);
  var units = [];
  for(var idx = 0; idx <= 9; ++idx){
    var uc = code$2.slice((idx << 1), (idx << 1) + 2 | 0);
    if (uc !== "00") {
      var num = T.fromHex(uc);
      var unitId = Belt_Array.getExn(codeUnits, num - 1 | 0);
      units.push(unitId);
    }
    
  }
  return Caml_option.some(generateBoard_(units));
}

export {
  version ,
  version2 ,
  GameCfg ,
  Content ,
  Items ,
  Hex ,
  Group ,
  allLegends ,
  decodeQs ,
  encodeQs ,
  getTraitRanks ,
  getData ,
  generateBoard_ ,
  generateBoard ,
  generateBoardFull ,
  codeUnits ,
  unitCode ,
  codeSet ,
  codeSetLower ,
  codeSetLen ,
  genCode ,
  isValidCode ,
  boardFromCode ,
  
}
/* codeSetLower Not a pure module */
