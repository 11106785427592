// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Ab from "common/src/Ab.bs.js";
import * as Afm from "../app-core/Afm.bs.js";
import * as RevAd from "./RevAd.bs.js";
import * as React from "react";
import * as Config from "common/src/Config.bs.js";
import * as Router from "common/src/Router.bs.js";
import * as NitroAd from "./NitroAd.bs.js";
import * as ScreenAtom from "common/src/atoms/ScreenAtom.bs.js";
import * as Router$1 from "next/router";

function SidebarAd$Stack(Props) {
  var rail = Props.rail;
  Afm.use(undefined);
  var screenSize = ScreenAtom.useNoSSR(undefined);
  return React.createElement("div", {
              className: "hidden lg:block self-start mt-5 static top-[108px] mt-[340px]"
            }, React.createElement("div", {
                  className: "lg:min-w-[160px] lg:min-h-[600px] xxl:min-w-[300px]"
                }, React.createElement(NitroAd.make, {
                      format: "sticky-stack",
                      reportPosition: "bottom-right",
                      id: "na-side-stack-" + rail,
                      sizes: screenSize !== 0 ? (
                          screenSize >= 3 ? [] : [[
                                160,
                                600
                              ]]
                        ) : [
                          [
                            300,
                            600
                          ],
                          [
                            160,
                            600
                          ],
                          [
                            300,
                            250
                          ],
                          [
                            320,
                            480
                          ],
                          [
                            320,
                            50
                          ],
                          [
                            320,
                            100
                          ]
                        ],
                      stickyStackLimit: 15,
                      stickyStackSpace: 1.25,
                      stickyStackOffset: 108
                    })));
}

var Stack = {
  make: SidebarAd$Stack
};

function SidebarAd(Props) {
  var rail = Props.rail;
  Router.use(undefined);
  Afm.use(undefined);
  var screenSize = ScreenAtom.useNoSSR(undefined);
  var ab = Ab.use(undefined);
  var useRev = ab < Config.reviqThreshold;
  var router = Router$1.useRouter();
  React.useState(function () {
        return false;
      });
  if (rail === "right" && !useRev) {
    if (router.asPath.startsWith("/team-compositions")) {
      return React.createElement(SidebarAd$Stack, {
                  rail: rail
                });
    } else {
      return React.createElement("div", {
                  className: "hidden self-start mt-5 sticky top-[108px] mt-[340px] lg:flex flex-col gap-16 lg:min-w-[160px] lg:min-h-[600px] xxl:min-w-[300px]"
                }, React.createElement("div", {
                      className: "min-h-[250px]"
                    }, React.createElement(NitroAd.make, {
                          reportPosition: "bottom-right",
                          id: "na-sticky-side-" + rail + "-1",
                          sizes: screenSize !== 0 ? (
                              screenSize >= 3 ? [] : [[
                                    160,
                                    600
                                  ]]
                            ) : [
                              [
                                300,
                                250
                              ],
                              [
                                320,
                                50
                              ],
                              [
                                320,
                                100
                              ]
                            ]
                        })), screenSize !== 0 ? null : React.createElement("div", {
                        className: "min-h-[250px]"
                      }, React.createElement(NitroAd.make, {
                            reportPosition: "bottom-right",
                            id: "na-sticky-side-" + rail + "-2",
                            sizes: [
                              [
                                300,
                                250
                              ],
                              [
                                320,
                                50
                              ],
                              [
                                320,
                                100
                              ]
                            ]
                          })));
    }
  } else {
    return React.createElement("div", {
                className: "hidden lg:block self-start mt-5 sticky top-[108px] mt-[340px]"
              }, React.createElement("div", {
                    className: "lg:min-w-[160px] lg:min-h-[600px] xxl:min-w-[300px] flex items-center justify-center"
                  }, useRev ? (
                      screenSize !== 0 ? (
                          screenSize >= 3 ? null : React.createElement(RevAd.make, {
                                  id: rail === "left" ? "left-rail-3" : "right-rail-3"
                                })
                        ) : React.createElement(RevAd.make, {
                              id: rail === "left" ? "left-rail-2" : "right-rail-2"
                            })
                    ) : React.createElement(NitroAd.make, {
                          reportPosition: "bottom-right",
                          id: "na-sticky-side-" + rail,
                          sizes: screenSize !== 0 ? (
                              screenSize >= 3 ? [] : [[
                                    160,
                                    600
                                  ]]
                            ) : [
                              [
                                300,
                                600
                              ],
                              [
                                160,
                                600
                              ],
                              [
                                300,
                                250
                              ],
                              [
                                320,
                                480
                              ],
                              [
                                320,
                                50
                              ],
                              [
                                320,
                                100
                              ]
                            ]
                        })));
  }
}

var make = SidebarAd;

export {
  Stack ,
  make ,
  
}
/* Ab Not a pure module */
