// Generated by ReScript, PLEASE EDIT WITH CARE

import * as T from "common/src/T.bs.js";
import * as Sd from "common/src/tft-static/Sd.bs.js";
import * as TC from "common/src/components/TC.bs.js";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Data from "common/src/data/Data.bs.js";
import * as React from "react";
import * as Global from "common/src/Global.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Belt_MutableMapInt from "rescript/lib/es6/belt_MutableMapInt.js";
import * as Belt_MutableMapString from "rescript/lib/es6/belt_MutableMapString.js";
import Star from "@mui/icons-material/Star";

function compFilterMatch(comp, filters, set) {
  if (filters.length === 0) {
    return true;
  } else {
    return Belt_Array.every(filters, (function (f) {
                  if (f.TAG !== /* Trait */0) {
                    return comp.units.includes(f._0);
                  }
                  var rank = f._1;
                  var t = f._0;
                  var traits = TC.getTraits(undefined, undefined, comp.units, set);
                  return Belt_Array.some(traits, (function (param) {
                                if (t === param[0]) {
                                  return param[1] >= rank;
                                } else {
                                  return false;
                                }
                              }));
                }));
  }
}

function gc(itemDeltas, c) {
  return Belt_Array.keep(itemDeltas, (function (param) {
                return param[2] <= -1 * c;
              })).length;
}

function getThresholds(itemDeltas, set) {
  var itemDeltas$1 = Belt_Array.keep(itemDeltas, (function (param) {
          var info = Data.itemInfo(param[1], set);
          if (info === undefined) {
            return false;
          }
          var info$1 = Caml_option.valFromOption(info);
          if (info$1.id > 8) {
            return info$1.id < 45;
          } else {
            return false;
          }
        }));
  var st = 0.3;
  var at = 0.25;
  var bt = 0.2;
  var stSkip = false;
  var atSkip = false;
  var btSkip = false;
  while(st >= 0.15 && !stSkip) {
    var c = gc(itemDeltas$1, st);
    if (c <= 3) {
      st = st - 0.01;
    } else {
      stSkip = true;
    }
  };
  var sc = gc(itemDeltas$1, st);
  while(at >= 0.1 && !atSkip) {
    var c$1 = gc(itemDeltas$1, at);
    if ((c$1 - sc | 0) <= 7) {
      at = at - 0.01;
    } else {
      atSkip = true;
    }
  };
  while(bt >= 0.05 && !btSkip) {
    var c$2 = gc(itemDeltas$1, bt);
    if (c$2 <= 18) {
      bt = bt - 0.005;
    } else {
      btSkip = true;
    }
  };
  return [
          -1 * st,
          -1 * at,
          -1 * bt
        ];
}

function getStarUnits(group) {
  var allUnits = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.map(group.comps, (function (c) {
                      return c.units;
                    })))));
  var units = group.unitDeltas;
  var prio = [];
  var others = [];
  Belt_Array.forEach(allUnits, (function (unitId) {
          var unitStats = Belt_Array.keepU(group.units, (function (param) {
                  return param[0] === unitId;
                }));
          var tier = Sd.unitTier(unitId);
          var delta;
          if (tier !== 5) {
            var match = Belt_Option.map(Caml_option.undefined_to_opt(units.find(function (param) {
                          if (param[0] === unitId) {
                            return param[1] === 3;
                          } else {
                            return false;
                          }
                        })), (function (param) {
                    return param[2];
                  }));
            var match$1 = Belt_Option.map(Caml_option.undefined_to_opt(units.find(function (param) {
                          if (param[0] === unitId) {
                            return param[1] === 2;
                          } else {
                            return false;
                          }
                        })), (function (param) {
                    return param[2];
                  }));
            if (match !== undefined && match$1 !== undefined) {
              var tierCount = Belt_Option.getWithDefault(Belt_Option.map(Caml_option.undefined_to_opt(unitStats.find(function (param) {
                                return param[1] === 3;
                              })), (function (param) {
                          return param[2];
                        })), 0);
              var otherCount = Belt_Array.reduce(Belt_Array.map(Belt_Array.keepU(unitStats, (function (param) {
                              return param[1] !== 3;
                            })), (function (param) {
                          return param[2];
                        })), 0, (function (acc, v) {
                      return acc + v | 0;
                    }));
              var part = Global.f(tierCount) / (Global.f(tierCount) + Global.f(otherCount));
              delta = Math.sqrt(part) * (match$1 - match);
            } else {
              delta = undefined;
            }
          } else {
            var match$2 = Belt_Option.map(Caml_option.undefined_to_opt(units.find(function (param) {
                          if (param[0] === unitId) {
                            return param[1] === 2;
                          } else {
                            return false;
                          }
                        })), (function (param) {
                    return param[2];
                  }));
            var match$3 = Belt_Option.map(Caml_option.undefined_to_opt(units.find(function (param) {
                          if (param[0] === unitId) {
                            return param[1] === 1;
                          } else {
                            return false;
                          }
                        })), (function (param) {
                    return param[2];
                  }));
            if (match$2 !== undefined && match$3 !== undefined) {
              var tierCount$1 = Belt_Option.getWithDefault(Belt_Option.map(Caml_option.undefined_to_opt(unitStats.find(function (param) {
                                return param[1] === 2;
                              })), (function (param) {
                          return param[2];
                        })), 0);
              var otherCount$1 = Belt_Array.reduce(Belt_Array.map(Belt_Array.keepU(unitStats, (function (param) {
                              return param[1] !== 2;
                            })), (function (param) {
                          return param[2];
                        })), 0, (function (acc, v) {
                      return acc + v | 0;
                    }));
              var part$1 = Global.f(tierCount$1) / (Global.f(tierCount$1) + Global.f(otherCount$1));
              delta = Math.sqrt(part$1) * (match$3 - match$2);
            } else {
              delta = undefined;
            }
          }
          if (delta === undefined) {
            return ;
          }
          if (!(tier > 4 || tier < 1)) {
            if (tier >= 4) {
              if (delta > 11.5) {
                prio.push(unitId);
                return ;
              } else if (delta > 11.25) {
                others.push(unitId);
                return ;
              } else {
                return ;
              }
            } else if (delta > 0.25) {
              prio.push(unitId);
              return ;
            } else if (delta > 0.15) {
              others.push(unitId);
              return ;
            } else {
              return ;
            }
          }
          tier !== 5;
          if (delta > 10.5) {
            prio.push(unitId);
            return ;
          } else if (delta > 0.93) {
            others.push(unitId);
            return ;
          } else {
            return ;
          }
        }));
  return [
          prio,
          others
        ];
}

function getItemLimits(itemId) {
  return 2;
}

function filterCopies(exampleItems) {
  var filtered = [];
  Belt_Array.forEach(exampleItems, (function (info) {
          var itemId = info[1];
          if (Belt_Array.keep(filtered, (function (param) {
                    return Caml_obj.caml_equal(param[1], itemId);
                  })).length < 2) {
            filtered.push(info);
            return ;
          }
          
        }));
  return filtered;
}

function getGroupItems(group) {
  var allUnits = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.map(group.nonSpatComps, (function (c) {
                      return c.units;
                    })))));
  return filterCopies(Belt_SortArray.stableSortBy(Belt_Array.keep(group.itemDeltas, (function (param) {
                        return allUnits.includes(param[0]);
                      })), (function (param, param$1) {
                    return Caml.caml_float_compare(param[2], param$1[2]);
                  })));
}

function filterEmblems(itemsData, set) {
  var hasEmblem = {
    contents: false
  };
  return Belt_Array.keep(itemsData, (function (param) {
                var info = Data.itemInfoExn(param[1], set);
                if (info.tags.includes("CraftableEmblem") || info.tags.includes("ElusiveEmblem")) {
                  if (hasEmblem.contents) {
                    return false;
                  } else {
                    hasEmblem.contents = true;
                    return true;
                  }
                } else {
                  return true;
                }
              }));
}

function groupItems(tierItems) {
  var itemsOrder = [];
  var finalItemsMap = Belt_MutableMapString.make(undefined);
  var leftoverItems = [];
  Belt_Array.forEach(tierItems, (function (param) {
          var itemId = param[1];
          var unitId = param[0];
          if (!itemsOrder.includes(itemId) && itemsOrder.length >= 5) {
            leftoverItems.push([
                  unitId,
                  itemId
                ]);
            return ;
          }
          if (!itemsOrder.includes(itemId)) {
            itemsOrder.push(itemId);
          }
          var curr = Belt_Option.getWithDefault(Belt_MutableMapString.get(finalItemsMap, itemId), []);
          if (curr.length >= 2) {
            leftoverItems.push([
                  unitId,
                  itemId
                ]);
            return ;
          } else {
            return Belt_MutableMapString.set(finalItemsMap, itemId, Belt_Array.concat(curr, [unitId]));
          }
        }));
  return [
          Belt_Array.map(itemsOrder, (function (itemId) {
                  return [
                          itemId,
                          Belt_MutableMapString.getExn(finalItemsMap, itemId)
                        ];
                })),
          leftoverItems
        ];
}

function getItemTiers(group, set) {
  var itemsData = getGroupItems(group);
  var match = getThresholds(itemsData, set);
  var bp3 = match[2];
  var bp2 = match[1];
  var bp1 = match[0];
  var itemsData$1 = Belt_Array.keep(itemsData, (function (param) {
          var info = Data.itemInfoExn(param[1], set);
          return Belt_Array.some(info.tags, (function (tag) {
                        return [
                                  "Craftable",
                                  "CraftableEmblem",
                                  "ElusiveEmblem",
                                  "Artifact",
                                  "Support"
                                ].includes(tag);
                      }));
        }));
  var sItems = Belt_Array.map(Belt_Array.keep(itemsData$1, (function (param) {
              return param[2] <= bp1;
            })), (function (param) {
          return [
                  param[0],
                  param[1]
                ];
        }));
  var aItems = Belt_Array.map(Belt_Array.keep(itemsData$1, (function (param) {
              var d = param[2];
              if (d <= bp2) {
                return d > bp1;
              } else {
                return false;
              }
            })), (function (param) {
          return [
                  param[0],
                  param[1]
                ];
        }));
  var bItems = Belt_Array.map(Belt_Array.keep(itemsData$1, (function (param) {
              var d = param[2];
              if (d <= bp3) {
                return d > bp2;
              } else {
                return false;
              }
            })), (function (param) {
          return [
                  param[0],
                  param[1]
                ];
        }));
  var match$1 = groupItems(sItems);
  var match$2 = groupItems(Belt_Array.concat(match$1[1], aItems));
  var match$3 = groupItems(Belt_Array.concat(match$2[1], bItems));
  return [
          match$1[0],
          match$2[0],
          match$3[0]
        ];
}

function getExampleItems(group, emblem, set) {
  var deltas = Belt_Array.map(group.carryUnits, (function (prim) {
          return prim[1];
        }));
  var secondCarry = Belt_Array.getExn(deltas, 0) / Belt_Array.getExn(deltas, 1) < 1.5;
  var thirdCarry = Belt_Array.getExn(deltas, 2) > 0.8;
  var items = Belt_MutableMapString.make(undefined);
  var itemsData = filterEmblems(getGroupItems(group), set);
  var itemsData2 = [];
  Belt_Array.forEach(itemsData, (function (param) {
          var i = param[1];
          var info = Data.itemInfoExn(i, set);
          var pass = Belt_Array.some(info.tags, (function (tag) {
                  return [
                            "Craftable",
                            "CraftableEmblem"
                          ].includes(tag);
                }));
          if (pass) {
            itemsData2.push([
                  param[0],
                  i,
                  param[2]
                ]);
            return ;
          }
          
        }));
  if (emblem !== undefined) {
    itemsData2.unshift([
          emblem[1],
          emblem[0],
          0
        ]);
  }
  var itemDeltas = Belt_SortArray.stableSortBy(group.itemDeltas, (function (param, param$1) {
          return Caml.caml_float_compare(param[2], param$1[2]);
        }));
  var c1 = Belt_Array.getExn(group.carryUnits, 0)[0];
  var is1 = Belt_Array.slice(Belt_Array.map(Belt_Array.keep(itemsData2, (function (param) {
                  return param[0] === c1;
                })), (function (param) {
              return param[1];
            })), 0, 3);
  var is1$1 = is1.length === 3 ? is1 : Belt_Array.slice(Belt_Array.map(Belt_Array.keep(itemDeltas, (function (param) {
                    return param[0] === c1;
                  })), (function (param) {
                return param[1];
              })), 0, 3);
  var c2 = Belt_Array.getExn(group.carryUnits, 1)[0];
  var is2 = Belt_Array.slice(Belt_Array.map(Belt_Array.keep(itemsData2, (function (param) {
                  return param[0] === c2;
                })), (function (param) {
              return param[1];
            })), 0, secondCarry ? 3 : 2);
  var c3 = Belt_Array.getExn(group.carryUnits, 2)[0];
  var is3 = Belt_Array.slice(Belt_Array.map(Belt_Array.keep(itemsData2, (function (param) {
                  return param[0] === c3;
                })), (function (param) {
              return param[1];
            })), 0, thirdCarry ? 2 : 1);
  Belt_MutableMapString.set(items, c1, is1$1);
  Belt_MutableMapString.set(items, c2, is2);
  Belt_MutableMapString.set(items, c3, is3);
  var remainingItems = {
    contents: ((10 - is1$1.length | 0) - is2.length | 0) - is3.length | 0
  };
  Belt_Array.forEach(itemsData2, (function (param) {
          var u = param[0];
          if (remainingItems.contents > 0 && Belt_Option.isNone(Belt_MutableMapString.get(items, u))) {
            Belt_MutableMapString.set(items, u, [param[1]]);
            remainingItems.contents = remainingItems.contents - 1 | 0;
            return ;
          }
          
        }));
  return Belt_MapString.fromArray(Belt_MutableMapString.toArray(items));
}

function getPriorityComponents2(group, set) {
  var match = getItemTiers(group, set);
  var scores = Belt_MutableMapInt.make(undefined);
  Belt_Array.forEachWithIndex([
        match[0],
        match[1],
        match[2]
      ], (function (tierIdx, tierItems) {
          var tierScore = 3 - tierIdx | 0;
          return Belt_Array.forEach(tierItems, (function (param) {
                        var components = Belt_Option.mapWithDefault(Data.itemInfo(param[0], set), [], (function (i) {
                                return i.from;
                              }));
                        return Belt_Array.forEach(param[1], (function (param) {
                                      return Belt_Array.forEach(components, (function (componentId) {
                                                    var curr = Belt_MutableMapInt.getWithDefault(scores, componentId, 0);
                                                    return Belt_MutableMapInt.set(scores, componentId, curr + tierScore | 0);
                                                  }));
                                    }));
                      }));
        }));
  return Belt_Array.map(Belt_SortArray.stableSortBy(Belt_MutableMapInt.toArray(scores), (function (param, param$1) {
                    return Caml.caml_int_compare(param$1[1], param[1]);
                  })), (function (prim) {
                return prim[0];
              }));
}

function getRecommendedAugments(group, count, set) {
  var augmentSingles = group.augmentSingles;
  var augmentSingles$1 = Belt_Array.keep(augmentSingles, (function (param) {
          return Belt_Option.isSome(Js_dict.get(Data.augments(set), param[0]));
        }));
  var silver = Belt_Array.slice(Belt_SortArray.stableSortBy(Belt_Array.keep(augmentSingles$1, (function (param) {
                  return Data.augmentInfoExn(param[0], set).rank === 1;
                })), (function (param, param$1) {
              var c2 = param$1[1];
              var c1 = param[1];
              return Caml.caml_float_compare((param[2] - group.place) / (1 - Global.f(c1) / Global.f(group.count)) * Math.sqrt(Global.f(c1)), (param$1[2] - group.place) / (1 - Global.f(c2) / Global.f(group.count)) * Math.sqrt(Global.f(c2)));
            })), 0, count);
  var gold = Belt_Array.slice(Belt_SortArray.stableSortBy(Belt_Array.keep(augmentSingles$1, (function (param) {
                  return Data.augmentInfoExn(param[0], set).rank === 2;
                })), (function (param, param$1) {
              var c2 = param$1[1];
              var c1 = param[1];
              return Caml.caml_float_compare((param[2] - group.place) / (1 - Global.f(c1) / Global.f(group.count)) * Math.sqrt(Global.f(c1)), (param$1[2] - group.place) / (1 - Global.f(c2) / Global.f(group.count)) * Math.sqrt(Global.f(c2)));
            })), 0, count);
  var prism = Belt_Array.slice(Belt_SortArray.stableSortBy(Belt_Array.keep(augmentSingles$1, (function (param) {
                  return Data.augmentInfoExn(param[0], set).rank === 3;
                })), (function (param, param$1) {
              var c2 = param$1[1];
              var c1 = param[1];
              return Caml.caml_float_compare((param[2] - group.place) / (1 - Global.f(c1) / Global.f(group.count)) * Math.sqrt(Global.f(c1)), (param$1[2] - group.place) / (1 - Global.f(c2) / Global.f(group.count)) * Math.sqrt(Global.f(c2)));
            })), 0, count);
  return [
          silver,
          gold,
          prism
        ];
}

function getTopAugments(group, set) {
  var augmentSingles = group.augmentSingles;
  var augmentSingles$1 = Belt_Array.keep(augmentSingles, (function (param) {
          return Belt_Option.isSome(Data.augmentInfo(param[0], set));
        }));
  var silvers = Belt_SortArray.stableSortBy(Belt_Array.keep(augmentSingles$1, (function (param) {
              var info = Data.augmentInfoExn(param[0], set);
              if (info.rank === 1) {
                return Belt_Option.isNone(info.hero);
              } else {
                return false;
              }
            })), (function (param, param$1) {
          var c2 = param$1[1];
          var c1 = param[1];
          return Caml.caml_float_compare((param[2] - group.place) / (1 - Global.f(c1) / Global.f(group.count)) * Math.sqrt(Global.f(c1)), (param$1[2] - group.place) / (1 - Global.f(c2) / Global.f(group.count)) * Math.sqrt(Global.f(c2)));
        }));
  var golds = Belt_SortArray.stableSortBy(Belt_Array.keep(augmentSingles$1, (function (param) {
              return Data.augmentInfoExn(param[0], set).rank === 2;
            })), (function (param, param$1) {
          var c2 = param$1[1];
          var c1 = param[1];
          return Caml.caml_float_compare((param[2] - group.place) / (1 - Global.f(c1) / Global.f(group.count)) * Math.sqrt(Global.f(c1)), (param$1[2] - group.place) / (1 - Global.f(c2) / Global.f(group.count)) * Math.sqrt(Global.f(c2)));
        }));
  var prisms = Belt_SortArray.stableSortBy(Belt_Array.keep(augmentSingles$1, (function (param) {
              return Data.augmentInfoExn(param[0], set).rank === 3;
            })), (function (param, param$1) {
          var c2 = param$1[1];
          var c1 = param[1];
          return Caml.caml_float_compare((param[2] - group.place) / (1 - Global.f(c1) / Global.f(group.count)) * Math.sqrt(Global.f(c1)), (param$1[2] - group.place) / (1 - Global.f(c2) / Global.f(group.count)) * Math.sqrt(Global.f(c2)));
        }));
  var distributed = Belt_Array.concatMany([
        Belt_Array.slice(silvers, 0, 2),
        Belt_Array.slice(golds, 0, 3),
        Belt_Array.slice(prisms, 0, 2)
      ]);
  var remaining = Belt_SortArray.stableSortBy(Belt_Array.concatMany([
            silvers.length >= 2 ? Belt_Array.sliceToEnd(silvers, 2) : [],
            golds.length >= 3 ? Belt_Array.sliceToEnd(golds, 3) : [],
            prisms.length >= 2 ? Belt_Array.sliceToEnd(prisms, 2) : []
          ]), (function (param, param$1) {
          return Caml.caml_float_compare(T.calcSafePlace(2.5, param[2], param[1]), T.calcSafePlace(2.5, param$1[2], param$1[1]));
        }));
  return Belt_SortArray.stableSortBy(Belt_Array.concat(distributed, Belt_Array.slice(remaining, 0, 3)), (function (param, param$1) {
                return Caml.caml_float_compare(param[2], param$1[2]);
              }));
}

function getStarTooltip(param, unitId) {
  var tier = Sd.unitTier(unitId);
  return React.createElement("div", {
              className: "text-sm"
            }, React.createElement("div", {
                  className: "flex"
                }, tier === 5 ? "2" : "3", React.createElement("span", {
                      className: "text-[15px] inline-block mt-[-2px] pr-[2px]"
                    }, React.createElement(Star, {
                          fontSize: "inherit"
                        })), "stats"), React.createElement("div", undefined, Global.formatPct(undefined, param[0]) + "% games"), React.createElement("div", undefined, Global.toFixed(undefined, param[1]) + " avg. place"));
}

function getEmblem(group, set) {
  var emblems = Belt_SortArray.stableSortBy(Belt_Array.keep(group.generalItems, (function (param) {
              var info = Data.itemInfoExn(param[0], set);
              if (info.tags.includes("CraftableEmblem")) {
                return true;
              } else {
                return info.tags.includes("ElusiveEmblem");
              }
            })), (function (param, param$1) {
          return Caml.caml_int_compare(param$1[1], param[1]);
        }));
  var match = Belt_Array.get(emblems, 0);
  if (match === undefined) {
    return ;
  }
  var itemId = match[0];
  var pctGames = Global.f(match[1]) / Global.f(group.count);
  var delta = match[2] - group.place;
  if (!(pctGames >= 0.75 || pctGames >= 0.5 && delta < -0.25)) {
    return ;
  }
  var unitsMap = Belt_MutableMapString.make(undefined);
  Belt_Array.forEach(group.unitItems, (function (ui) {
          if (ui.itemId !== itemId) {
            return ;
          }
          var curr = Belt_MutableMapString.getWithDefault(unitsMap, ui.unitId, 0);
          return Belt_MutableMapString.set(unitsMap, ui.unitId, curr + ui.count | 0);
        }));
  var unitId = Belt_Option.map(Belt_Array.get(Belt_SortArray.stableSortBy(Belt_MutableMapString.toArray(unitsMap), (function (param, param$1) {
                  return Caml.caml_int_compare(param$1[1], param[1]);
                })), 0), (function (prim) {
          return prim[0];
        }));
  return Belt_Option.map(unitId, (function (unitId) {
                return [
                        itemId,
                        unitId
                      ];
              }));
}

function getBestComp(group, emblem, filters, set, coreUnits) {
  var comps = Belt_Array.keep(emblem !== undefined ? group.spatComps : group.nonSpatComps, (function (comp) {
          return !Belt_Option.isSome(Caml_option.undefined_to_opt(comp.units.find(function (unitId) {
                              return Sd.unitTier(unitId) === 6;
                            })));
        }));
  var comps$1 = comps.length === 0 ? (
      emblem !== undefined ? group.spatComps : group.nonSpatComps
    ) : comps;
  var comps$2 = comps$1.length === 0 ? (
      emblem !== undefined ? group.nonSpatComps : group.spatComps
    ) : comps$1;
  var filteredComps = Belt_Array.keep(comps$2, (function (comp) {
          if (Belt_Array.every(coreUnits, (function (unitId) {
                    return comp.units.includes(unitId);
                  }))) {
            return Belt_Array.every(filters, (function (filter) {
                          if (filter.TAG === /* Trait */0) {
                            var trait = filter._0;
                            return Belt_Array.keep(comp.units, (function (u) {
                                          return Sd.getUnitTraits(u, set).includes(trait);
                                        })).length >= Sd.traitRankUnitCount(trait, filter._1, set);
                          }
                          var unit = filter._0;
                          return Belt_Array.some(comp.units, (function (u) {
                                        return u === unit;
                                      }));
                        }));
          } else {
            return false;
          }
        }));
  var filteredComps$1;
  if (filteredComps.length === 0) {
    var fc2 = Belt_Array.keep(comps$2, (function (comp) {
            return Belt_Array.every(filters, (function (filter) {
                          if (filter.TAG === /* Trait */0) {
                            var trait = filter._0;
                            return Belt_Array.keep(comp.units, (function (u) {
                                          return Sd.getUnitTraits(u, set).includes(trait);
                                        })).length >= Sd.traitRankUnitCount(trait, filter._1, set);
                          }
                          var unit = filter._0;
                          return Belt_Array.some(comp.units, (function (u) {
                                        return u === unit;
                                      }));
                        }));
          }));
    filteredComps$1 = fc2.length === 0 ? Belt_Array.keep(comps$2, (function (comp) {
              return Belt_Array.every(filters, (function (filter) {
                            if (filter.TAG === /* Trait */0) {
                              var trait = filter._0;
                              return Belt_Array.keep(comp.units, (function (u) {
                                            return Sd.getUnitTraits(u, set).includes(trait);
                                          })).length >= Sd.traitRankUnitCount(trait, filter._1 - 1 | 0, set);
                            }
                            var unit = filter._0;
                            return Belt_Array.some(comp.units, (function (u) {
                                          return u === unit;
                                        }));
                          }));
            })) : fc2;
  } else {
    filteredComps$1 = filteredComps;
  }
  return Belt_Array.getExn(Belt_Array.map(Belt_SortArray.stableSortBy(Belt_Array.map(filteredComps$1, (function (comp) {
                            var unitsRating = Belt_Array.reduce(Belt_Array.slice(Belt_SortArray.stableSortBy(Belt_Array.map(comp.units, (function (unitId) {
                                                return Global.f(Belt_Array.reduce(Belt_Array.keep(group.units, (function (param) {
                                                                      return param[0] === unitId;
                                                                    })), 0, (function (acc, param) {
                                                                  return acc + param[2] | 0;
                                                                }))) / Global.f(group.count);
                                              })), (function (v1, v2) {
                                            return Caml.caml_float_compare(v2, v1);
                                          })), 0, 6), 1, (function (acc, val) {
                                    return acc * val;
                                  }));
                            var headlinerRating = Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Array.get(comp.extraTraits, 0), (function (et) {
                                        return Belt_Option.flatMap(group.extraTraits, (function (ets) {
                                                      return Caml_option.undefined_to_opt(ets.find(function (param) {
                                                                      return Caml_obj.caml_equal(param[0], et);
                                                                    }));
                                                    }));
                                      })), 0, (function (param) {
                                    var pr = Global.f(param[1]) / Global.f(group.count);
                                    return Math.sqrt(pr) * (param[2] - group.place) / (1 - pr);
                                  }));
                            var rating = (comp.place - group.place + headlinerRating) * Math.sqrt(unitsRating);
                            return [
                                    comp,
                                    rating
                                  ];
                          })), (function (param, param$1) {
                        return Caml.caml_float_compare(param[1], param$1[1]);
                      })), (function (prim) {
                    return prim[0];
                  })), 0);
}

function getCoreAug(group) {
  return Belt_Option.map(Caml_option.undefined_to_opt(group.augmentSingles.find(function (param) {
                      var count = param[1];
                      var rate = Global.f(count) / Global.f(group.count);
                      if (rate <= 0.4) {
                        return false;
                      }
                      var delta = (param[2] - group.place) / (1 - Global.f(count) / Global.f(group.count));
                      var adjRate = rate - delta / 4;
                      return adjRate > 0.65;
                    })), (function (param) {
                return param[0];
              }));
}

export {
  compFilterMatch ,
  gc ,
  getThresholds ,
  getStarUnits ,
  getItemLimits ,
  filterCopies ,
  getGroupItems ,
  filterEmblems ,
  groupItems ,
  getItemTiers ,
  getExampleItems ,
  getPriorityComponents2 ,
  getRecommendedAugments ,
  getTopAugments ,
  getStarTooltip ,
  getEmblem ,
  getBestComp ,
  getCoreAug ,
  
}
/* T Not a pure module */
